import { DefaultValue } from './Components/DefaultValue'
import { MaxValue } from './Components/MaxValue'
import { MinValue } from './Components/MinValue'
import { Step } from './Components/Step'
import { Unit } from './Components/Unit'
import { WithDecimal } from './Components/WithDecimal'

export const Slider = ({ question, index, questionnaireIndex, pageIndex }) => {
  return (
    <div>
      <WithDecimal
        question={question}
        index={index}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
      />
      <MinValue
        question={question}
        index={index}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
      />
      <MaxValue
        question={question}
        index={index}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
      />
      <DefaultValue
        question={question}
        index={index}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
      />
      <Step question={question} index={index} questionnaireIndex={questionnaireIndex} pageIndex={pageIndex} />
      <Unit question={question} index={index} questionnaireIndex={questionnaireIndex} pageIndex={pageIndex} />
    </div>
  )
}
