import React, { useMemo, useCallback, useEffect } from 'react'
import { useSurveyDataStore } from '../../dataStore'
import { TextEnum } from '../../QuestionTypes/RiwisSlider/mapHelper'

export const RiwisCloseButton = ({ isSaving, onHide }) => {
  const [
    { answeredCities, questionnaire, survey, selectedCity },
    { setPageNumber, setNextRiwisQuestionnaire },
  ] = useSurveyDataStore()

  const nextRiwisQuestionnaire = useMemo(() => {
    if (survey.type === 'riwis') {
      let current = questionnaire
      const available = survey.questionnaires
      const currentIndex = available.findIndex((questionnaire) => questionnaire.id === current.id)
      let nextIndex = currentIndex < available.length - 1 ? currentIndex + 1 : 0
      let nextQuestionnaire = null
      while (!nextQuestionnaire) {
        const id = available[nextIndex].id
        if (
          !answeredCities?.some(
            (answeredCity) =>
              answeredCity.generic_area_code === selectedCity.gac &&
              answeredCity.questionnaires.some((questionnaire) => {
                return questionnaire.id === id && questionnaire.answerCount > 0
              })
          )
        ) {
          nextQuestionnaire = available[nextIndex]
        } else if (currentIndex === nextIndex) {
          nextQuestionnaire = current
        } else {
          if (nextIndex + 1 < available.length) {
            nextIndex++
          } else {
            nextIndex = 0
          }
        }
      }
      return nextQuestionnaire
    }
    return null
  }, [answeredCities, questionnaire, selectedCity, survey])

  useEffect(() => {
    if (nextRiwisQuestionnaire) {
      setNextRiwisQuestionnaire(nextRiwisQuestionnaire)
    }
  }, [nextRiwisQuestionnaire, setNextRiwisQuestionnaire])

  const goToNextRiwisQuestionnaire = useCallback(() => {
    setPageNumber(0)
    onHide(nextRiwisQuestionnaire.id)
  }, [nextRiwisQuestionnaire, onHide, setPageNumber])

  if (nextRiwisQuestionnaire.id !== questionnaire.id) {
    return (
      <>
        <button
          type="button"
          onClick={() => onHide(null)}
          className="riwis-btn riwis-secondary"
          disabled={isSaving}
        >
          schliessen
        </button>
        <button
          type="button"
          className="riwis-btn riwis-primary"
          disabled={isSaving}
          onClick={goToNextRiwisQuestionnaire}
        >
          {TextEnum[nextRiwisQuestionnaire.asset_class]} <span className="ml-4">&#9654;</span>
        </button>
      </>
    )
  } else {
    return (
      <>
        {/* workaround for space-between*/}
        <div></div>
        <button
          type="button"
          onClick={() => onHide(null)}
          className="riwis-btn riwis-primary"
          disabled={isSaving}
        >
          schliessen
        </button>
      </>
    )
  }
}

export const RiwisSaveButton = ({ label, dismiss = false, className }) => {
  const [{ questionnaire, survey, selectedCity, answeredCities, pageNumber }, { saveAnswers }] =
    useSurveyDataStore()
  const nextPage = dismiss ? pageNumber : pageNumber + 1
  let answeredCityId = 0
  const answeredQuestionnaires = answeredCities.find(
    (city) => city.generic_area_code === selectedCity.gac
  )?.questionnaires

  if (answeredQuestionnaires) {
    answeredCityId = answeredQuestionnaires.find(
      (aQuestionnaire) => aQuestionnaire.id === questionnaire.id
    )?.resId
  }

  return (
    <button
      type="button"
      className={'riwis-btn riwis-primary ' + className}
      onClick={() => saveAnswers(nextPage, questionnaire, survey.id, selectedCity.gac, answeredCityId)}
    >
      {label}
      <span className="ml-4">&#9654;</span>
    </button>
  )
}
