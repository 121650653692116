import createStore from '../../lib/flux-store'
import { Survey, Questionnaire, Riwis, Logs } from '../../helper/api'
import { getAnswersFromQuestionnaire, getRiwisAnswersFromQuestionnaire } from './QuestionnaireBuilder/helper'

const initialState = {
  dataIsLoading: true,
  isInSurvey: false,
  riwisDataIsFetching: true,
  questionnaireIsFetching: false,
  isAuthorized: true,
  isSaving: false,
  saveMessage: { msg: '', err: '', type: '' },
  survey: null,
  questionnaire: null,
  customCssPath: null,
  supportContact: null,
  selectedCity: null,
  riwisCities: null,
  riwisGeometry: {},
  riwisKgs12: null,
  riwisBounds: {},
  answeredCities: null,
  datasourceYear: null,
  surveyMessage: { msg: '', err: '', type: '' },
  pageNumber: 0,
  missingInputs: [],
  questionsPerPage: [],
  nextRiwisQuestionnaire: null,
}

const actions = {
  setPageNumber: (pageNumber) => ({ pageNumber }),
  setMissingInputs: (missingInputs) => ({ missingInputs }),
  setQuestionsPerPage: (questionsPerPage) => ({ questionsPerPage }),
  setDataIsLoading: (payload) => ({ payload }),
  setNextRiwisQuestionnaire: (nextRiwisQuestionnaire) => ({ nextRiwisQuestionnaire }),
  setInitialState: () => {},
  setSaveMessage: (saveMessage) => ({ saveMessage }),
  setSurveyMessage: (surveyMessage) => ({ surveyMessage }),
  setSelectedCity: (gac, name, marketCategory) => ({ gac, name, marketCategory }),
  setSurvey: (survey) => ({ payload: survey }),
  setQuestionnaire: (questionnaire) => ({ questionnaire }),
  setIsInSurvey: (isInSurvey) => ({ isInSurvey }),
  fetchSurvey: (surveySlug) => (dispatch) => {
    dispatch({ type: 'setDataIsLoading', payload: true })
    Survey.getBySlug(surveySlug)
      .then((res) => {
        if (res.data.msg === 'failed' && res.data.error === 'unauthorized') {
          dispatch({ type: 'setIsAuthorized', payload: false })
          dispatch({ type: 'setDataIsLoading', payload: false })
          dispatch({
            type: 'setSurveyMessage',
            surveyMessage: { msg: '', err: res.data.error, type: 'failed' },
          })
        } else if (res.data.msg === 'failed') {
          dispatch({
            type: 'setSurveyMessage',
            surveyMessage: { msg: '', err: res.data.error, type: 'failed' },
          })
          dispatch({
            type: 'setSurvey',
            payload: null,
          })
        } else {
          const survey = res.data.survey
          const payload = {
            survey,
            customCssPath: res.data.customCssPath,
            supportContact: res.data.survey.support_contact,
          }
          dispatch({ type: 'setSurveyMessage', surveyMessage: { msg: '', error: '', type: 'success' } })
          dispatch({ type: 'setIsAuthorized', payload: true })
          dispatch({ type: 'setSurvey', payload })
          dispatch({ type: 'setDataIsLoading', payload: false })
        }
      })
      .catch((err) => console.warn(err))
  },
  fetchQuestionnaire: (questionnaireId, gac, surveyId) => (dispatch) => {
    if (gac) {
      dispatch({ type: 'setQuestionnaireIsFetching', payload: true })
      Questionnaire.getByIdAndGac(questionnaireId, gac, surveyId).then((res) => {
        dispatch({ type: 'setQuestionnaire', questionnaire: res.data.questionnaire })
        dispatch({ type: 'setQuestionnaireIsFetching', payload: false })
        dispatch({ type: 'setAnsweredCities', answeredCities: res.data.answeredCities })
      })
    } else {
      Questionnaire.getById(questionnaireId).then((res) => {
        dispatch({ type: 'setQuestionnaire', questionnaire: res.data.questionnaire })
      })
    }
  },
  fetchRiwisCities: (surveyId) => (dispatch) => {
    dispatch({ type: 'setRiwisCities', riwisCities: null })
    Promise.all([Riwis.getCities(), Riwis.getAnswerdCitiesBySurvey(surveyId)])
      .then((res) => {
        const [resRiwisCities, resAnsweredCities] = res
        const sortedRiwisCities = resRiwisCities.data.riwisCities.sort((a, b) => a.name.localeCompare(b.name))
        dispatch({ type: 'setRiwisCities', riwisCities: sortedRiwisCities })
        dispatch({ type: 'setAnsweredCities', answeredCities: resAnsweredCities.data.answeredCities })
      })
      .catch((err) => console.warn(err))
  },
  resetCitiesAndAnsweredCities: () => ({}),
  fetchRiwisGeometry: (gac, areaType, marketCategory, question, surveyId) => (dispatch) => {
    Riwis.getGeometryByGacAndAreaType(gac, areaType, marketCategory, surveyId)
      .then((res) => {
        if (!res.data?.message) {
          dispatch({
            type: 'setRiwisGeometryAndBounds',
            areaType,
            geometry: res.data.geometry,
            bounds: res.data.bounds,
          })
        } else {
          Logs.saveErrorLog({
            userId: null,
            timestamp: new Date(),
            message: `Error at fetchRiwisGeometry -> question: ${JSON.stringify(question)} with gac: ${gac}`,
            stack: null,
            componentStack: null,
            location: window.location.pathname,
            userAgent: window.navigator.userAgent,
            appId: 'survey-desk',
          }).then((res) => {
            if (res.data.msg === 'success') {
              console.log('Error report has been submitted')
            } else {
              console.warn('Error report submit failed', res.data.err)
            }
          })
          dispatch({
            type: 'setRiwisGeometryAndBounds',
            areaType,
            geometry: {},
            bounds: {},
          })
        }
      })
      .catch((err) => console.warn(err))
  },
  fetchRiwisKgs12: (geometry) => (dispatch) => {
    Riwis.getKgs12ByGeometry(geometry)
      .then((res) => {
        dispatch({
          type: 'setRiwisKgs12',
          kgs12: res.data.kgs12,
        })
      })
      .catch((err) => console.warn(err))
  },
  setRiwisKgs12: (kgs12) => ({ kgs12 }),
  fetchRiwisData: (pageIndex, question, datasources, gac, year, surveyId) => (dispatch) => {
    dispatch({ type: 'setRiwisDataIsFetching', payload: true })
    Riwis.getData(datasources, gac, year, surveyId)
      .then((res) => {
        if (!res.data?.message) {
          dispatch({
            type: 'setRiwisData',
            pageIndex,
            riwisData: res.data.questionsData,
            questionId: question.id,
          })
        } else {
          Logs.saveErrorLog({
            userId: null,
            timestamp: new Date(),
            message: `Error at fetchRiwisData question: ${JSON.stringify(question)} with gac: ${gac}`,
            stack: null,
            componentStack: null,
            location: window.location.pathname,
            userAgent: window.navigator.userAgent,
            appId: 'survey-desk',
          }).then((res) => {
            if (res.data.msg === 'success') {
              console.log('Error report has been submitted')
            } else {
              console.warn('Error report submit failed', res.data.err)
            }
          })
          dispatch({
            type: 'setRiwisData',
            pageIndex,
            riwisData: {},
            questionId: question.id,
          })
        }
        dispatch({ type: 'setRiwisDataIsFetching', payload: false })
      })
      .catch((err) => console.warn(err))
  },
  setAnswers: (answers) => ({ answers }),
  setAnswer: (pageIndex, questionIndex, answer) => ({
    pageIndex,
    questionIndex,
    answer,
  }),
  saveAnswers: (nextPage, questionnaire, surveyId, gac, answeredCityId) => (dispatch) => {
    const result = gac
      ? getRiwisAnswersFromQuestionnaire(questionnaire, gac)
      : getAnswersFromQuestionnaire(questionnaire)
    dispatch({ type: 'setIsSaving', payload: true })
    Questionnaire.saveAnswers(
      result.answers,
      surveyId,
      questionnaire.id,
      gac,
      answeredCityId,
      result.questionCount,
      result.answerCount
    )
      .then((res) => {
        const saveMessage = { msg: '', type: 'success', err: '' }
        let answeredCities = null
        if (!res.data.survey) {
          if (res.data.msg === 'failed') {
            saveMessage.type = 'failed'
            saveMessage.err = res.data.error
          } else {
            if (res.data.answeredCities) {
              answeredCities = res.data.answeredCities
            }
          }
        }
        dispatch({ type: 'saveAnswers', saveMessage, answeredCities, nextPage })
        dispatch({ type: 'setIsSaving', payload: false })
      })
      .catch((err) => console.warn(err))
  },
}

const reducer = {
  setInitialState: (state) => {
    const resetState = initialState
    resetState.dataIsLoading = false
    resetState.datasourceYear = state.datasourceYear
    resetState.selectedCity = state.selectedCity
    resetState.survey = state.survey
    resetState.supportContact = state.supportContact
    resetState.isInSurvey = state.isInSurvey
    return { ...resetState }
  },
  setPageNumber: (state, { pageNumber }) => {
    return { ...state, pageNumber }
  },
  setMissingInputs: (state, { missingInputs }) => {
    return { ...state, missingInputs }
  },
  setQuestionsPerPage: (state, { questionsPerPage }) => {
    return { ...state, questionsPerPage }
  },
  setNextRiwisQuestionnaire: (state, { nextRiwisQuestionnaire }) => {
    return { ...state, nextRiwisQuestionnaire }
  },
  setDataIsLoading: (state, { payload }) => {
    return { ...state, dataIsLoading: payload }
  },
  setIsAuthorized: (state, { payload }) => {
    return { ...state, isAuthorized: payload }
  },
  setRiwisDataIsFetching: (state, { payload }) => {
    return { ...state, riwisDataIsFetching: payload }
  },
  setQuestionnaireIsFetching: (state, { payload }) => {
    return { ...state, questionnaireIsFetching: payload }
  },
  setIsSaving: (state, { payload }) => {
    return { ...state, isSaving: payload }
  },
  setSaveMessage: (state, { saveMessage }) => {
    return { ...state, saveMessage }
  },
  setSurveyMessage: (state, { surveyMessage }) => {
    return { ...state, surveyMessage }
  },
  setSelectedCity: (state, { gac, name, marketCategory }) => {
    if (gac === null) {
      return { ...state, selectedCity: null }
    }
    return { ...state, selectedCity: { gac, name, marketCategory } }
  },
  setRiwisCities: (state, { riwisCities }) => {
    return { ...state, riwisCities }
  },
  setAnsweredCities: (state, { answeredCities }) => {
    return { ...state, answeredCities }
  },
  resetCitiesAndAnsweredCities: (state) => {
    const newState = { ...state }
    newState.answeredCities = null
    newState.riwisCities = null
    return { ...newState }
  },
  setSurvey: (state, { payload }) => {
    // used for cleanup after route change
    if (payload === null) {
      return { ...state, survey: null, customCssPath: null, supportContact: null, datasourceYear: null }
    }
    return {
      ...state,
      survey: payload.survey,
      customCssPath: payload.customCssPath,
      supportContact: payload.supportContact,
      datasourceYear: payload?.survey?.datasource_year || null,
    }
  },
  setQuestionnaire: (state, { questionnaire }) => {
    return { ...state, questionnaire }
  },
  setIsInSurvey: (state, { isInSurvey }) => ({ ...state, isInSurvey }),
  setAnswers: (state, { answers }) => {
    return { ...state, answers }
  },
  setAnswer: (state, { pageIndex, questionIndex, answer }) => {
    const newQuestionnaire = { ...state.questionnaire }
    const newPages = [...newQuestionnaire.pages]
    const newPage = { ...newPages[pageIndex] }
    const newQuestions = [...newPage.questions]
    const newQuestion = { ...newQuestions[questionIndex] }
    if (!newQuestion.given_answer) newQuestion.given_answer = {}
    newQuestion.given_answer.question_id = answer.question_id
    newQuestion.given_answer.value = { value: answer.value }
    newQuestions[questionIndex] = newQuestion
    newPage.questions = newQuestions
    newPages[pageIndex] = newPage
    newQuestionnaire.pages = newPages
    return { ...state, questionnaire: newQuestionnaire }
  },
  setRiwisData: (state, { pageIndex, riwisData, questionId }) => {
    const newQuestionnaire = { ...state.questionnaire }
    const newPages = [...newQuestionnaire.pages]
    const newPage = { ...newPages[pageIndex] }
    let newQuestions = [...newPage.questions]
    newQuestions = newQuestions.map((question) => {
      const newQuestion = { ...question }
      if (question.id === questionId && question.riwis_datasources) {
        newQuestion.riwisData = riwisData
        if (!newQuestion.given_answer) newQuestion.given_answer = {}
      }
      return newQuestion
    })
    newPage.questions = newQuestions
    newPages[pageIndex] = newPage
    newQuestionnaire.pages = newPages
    return { ...state, questionnaire: newQuestionnaire }
  },
  setRiwisGeometryAndBounds: (state, { geometry, areaType, bounds }) => {
    const newRiwisGeometry = { ...state.riwisGeometry }
    const newRiwisBounds = { ...state.riwisBounds }
    newRiwisGeometry[areaType] = geometry
    newRiwisBounds[areaType] = bounds

    return { ...state, riwisGeometry: newRiwisGeometry, riwisBounds: newRiwisBounds }
  },
  setRiwisKgs12: (state, { kgs12 }) => {
    return { ...state, riwisKgs12: kgs12 }
  },
  saveAnswers: (state, { saveMessage, answeredCities, nextPage }) => {
    return { ...state, saveMessage, answeredCities, pageNumber: nextPage }
  },
}

export const [SurveyDataContext, SurveyDataProvider, useSurveyDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'SurveyDataStore'
)
