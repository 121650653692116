// polyfills
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './css/custom.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/webfonts/fa-brands-400.woff2'
import '@fortawesome/fontawesome-free/webfonts/fa-regular-400.woff2'
import '@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff2'
import { UserDataProvider } from './stores/userStore'
ReactDOM.render(
  <UserDataProvider>
    <App />
  </UserDataProvider>,
  document.getElementById('root')
)
