import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { useUserDataStore } from '../../stores/userStore'

const ResendVerificationMail = ({ message, handleClose, riwisMode }) => {
  if (message.type === 'success') {
    return (
      <>
        <div className="d-flex align-items-center" style={{ height: '8rem' }}>
          <i className="far fa-envelope mr-3" style={{ fontSize: '3rem' }}></i>
          <div style={{ fontSize: '1rem' }}>Ihre Bestätigungsmail wurde versendet!</div>
        </div>
        <button
          style={{ fontWeight: riwisMode ? '500' : '' }}
          className={(riwisMode ? 'riwis-btn riwis-secondary' : 'btn btn-outline-secondary') + ' form-group'}
          id="loginBtn"
          onClick={handleClose}
        >
          Schließen
        </button>
      </>
    )
  } else {
    return (
      <>
        <div className="d-flex align-items-center" style={{ height: '8rem' }}>
          <i className="fas fa-times mr-3" style={{ fontSize: '3rem', color: '#003e71' }}></i>
          <div style={{ fontSize: '1rem' }}>Leider konnte keine Bestätigungsmail versendet werden!</div>
        </div>
        <button
          style={{ fontWeight: riwisMode ? '500' : '' }}
          className={(riwisMode ? 'riwis-btn riwis-secondary' : 'btn btn-outline-secondary') + ' form-group'}
          id="loginBtn"
          onClick={handleClose}
        >
          Schließen
        </button>
      </>
    )
  }
}

export const ResendVerification = ({ email, handleClose, riwisMode }) => {
  const [{ verificationRequestMessage }, { requestVerificationMail }] = useUserDataStore()

  useEffect(() => {
    if (email) {
      requestVerificationMail(email)
    }
  }, [email, requestVerificationMail])

  return (
    <div>
      {verificationRequestMessage.type ? (
        <ResendVerificationMail
          message={verificationRequestMessage}
          handleClose={handleClose}
          riwisMode={riwisMode}
        />
      ) : (
        <Spinner size="xl" />
      )}
    </div>
  )
}
