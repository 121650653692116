import React from 'react'
import { ListBox } from './Components/ListBox'

export const Checkbox = ({ question, index, pageIndex, questionnaireIndex }) => {
  return (
    <ListBox
      label={'Antworten'}
      name="possible_answers"
      items={question.possible_answers}
      index={index}
      pageIndex={pageIndex}
      questionnaireIndex={questionnaireIndex}
    />
  )
}
