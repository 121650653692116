import React, { useState, useCallback } from 'react'
import { Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Container } from '../../components/Container'
import { useUserDataStore } from '../../stores/userStore'

const Feedback = ({ message, setShowLoginModal }) => {
  return message.type === 'success' ? (
    <div className="registrationFeedback">
      <div className="d-flex justify-content-center align-items-center flex-wrap">
        <i className="fas fa-check" style={{ fontSize: '5rem' }}></i>
        <h3 className="text-center px-5 mt-3 mt-sm-0">Passwort geändert</h3>
      </div>
      <div className="mt-5 text-center">
        Ihr Passwort wurde geändert. Sie könnnen sich nun mit Ihrer E-Mail Adresse und ihrem neuen Passwort
        anmelden
      </div>
      <button className="btn btn-primary mt-4" onClick={() => setShowLoginModal(true)}>
        Anmelden
      </button>
    </div>
  ) : (
    <div className="registrationFeedback">
      <div className="d-flex justify-content-center align-items-center flex-wrap">
        <i className="fas fa-times" style={{ fontSize: '5rem' }}></i>
        <h3 className="text-center px-5 mt-3 mt-sm-0">Passwort konnte nicht geändert werden!</h3>
      </div>
      {message.err === 'jwt expired' ? (
        <div className="mt-5 text-center">
          Leider konnte Ihr Passwort nicht geändert werden, da der Link abgelaufen ist. Bitte fordern Sie
          einen neuen Link zum Zurücksetzen Ihres Passworts an und versuchen Sie es erneut. an unseren{' '}
          <a href="mailto:survey-desk@bulwiengesa.de">Survey-Desk Support</a>.
        </div>
      ) : (
        <div>
          Leider konnte Ihr Passwort nicht geändert werden. Bitte versuchen Sie es erneut oder wenden Sie sich
          an unseren <a href="mailto:survey-desk@bulwiengesa.de">Survey-Desk Support</a>.
        </div>
      )}
    </div>
  )
}

const formValidation = (userInput) => {
  const missingInput = {
    password: false,
    passwordRepeat: false,
    minLength: false,
  }

  if (!userInput.password) {
    missingInput.password = true
  }

  if (!userInput.passwordRepeat) {
    missingInput.passwordRepeat = true
  }

  if (userInput.passwordRepeat !== userInput.password) {
    missingInput.password = true
    missingInput.passwordRepeat = true
  }

  if (userInput.password.length < 8) {
    missingInput.minLength = true
  }
  return missingInput
}

export const ResetPassword = () => {
  const { token } = useParams()
  const [{ resetPasswordMessage }, { changePassword, setShowLoginModal }] = useUserDataStore()
  const [userInput, setUserInput] = useState({
    password: '',
    passwordRepeat: '',
  })
  const [missingUserInput, setMissingUserInput] = useState(null)

  const handleInputChange = useCallback(
    (event) => {
      const newUserInput = { ...userInput }
      newUserInput[event.target.name] = event.target.value
      setUserInput(newUserInput)
    },
    [userInput]
  )

  const handlePasswordChange = useCallback(() => {
    const missingInput = formValidation(userInput)
    if (Object.values(missingInput).some((value) => value === true)) {
      setMissingUserInput(missingInput)
    } else {
      setMissingUserInput(null)
      if (token) {
        changePassword(userInput.password, token)
      }
    }
  }, [changePassword, token, userInput])

  return (
    <Container>
      {!resetPasswordMessage.type ? (
        <>
          <div className="row">
            <div className="col-6 text-left text-md-right my-5 px-5">
              <h2>Passwort zurücksetzen</h2>
            </div>
          </div>
          <div className="mt-4 col-md-9 col-sm-12">
            <div style={{ marginBottom: '1.5rem' }}>
              <div className="form-group row">
                <label
                  htmlFor="password"
                  className={
                    (missingUserInput && missingUserInput['password'] ? 'text-danger' : '') +
                    ' col col-form-label text-md-right text-sm-left'
                  }
                >
                  Neues Passwort*
                </label>
                <div className="col-sm-12 col-md">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                  <div
                    className={
                      (missingUserInput && missingUserInput['minLength'] ? 'text-danger' : '') + ' mt-2'
                    }
                    style={{ fontSize: '0.75rem' }}
                  >
                    (mindestens 8 Zeichen)
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <div className="form-group row">
                <label
                  htmlFor="passwordRepeat"
                  className={
                    (missingUserInput && missingUserInput['passwordRepeat'] ? 'text-danger' : '') +
                    ' col col-form-label text-md-right text-sm-left'
                  }
                >
                  Passwort wiederholen*
                </label>
                <div className="col-sm-12 col-md">
                  <input
                    type="password"
                    name="passwordRepeat"
                    id="passwordRepeat"
                    className="form-control"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col d-none d-md-block">&nbsp;</label>
              {missingUserInput && missingUserInput['password'] && missingUserInput['passwordRepeat'] && (
                <div className="col text-danger">Passwörter stimmen nicht überein</div>
              )}
            </div>

            <div className="form-group row">
              <div className="col-sm-12 col-md text-right">
                <Button variant="primary" onClick={handlePasswordChange}>
                  Passwort ändern
                </Button>
              </div>
            </div>
          </div>{' '}
        </>
      ) : (
        <div>
          <Feedback message={resetPasswordMessage} setShowLoginModal={setShowLoginModal} />
        </div>
      )}
    </Container>
  )
}
