import React, { useEffect, useState } from 'react'
import { useSurveyDataStore } from '../dataStore'
import { Button } from 'react-bootstrap'
import { RiwisSaveButton } from '../QuestionnaireBuilder/Components/RiwisButtons'
import { SaveButton } from '../QuestionnaireBuilder/Components/Buttons'

const SuccessMessage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-wrap my-4">
      <i className="fas fa-check-circle text-success align-middle mr-3 mb-4" style={{ fontSize: '2rem' }}></i>
      <p className="text-center" style={{ fontSize: '1rem' }}>
        Ihr Stand wurde erfolgreich gespeichert
      </p>
    </div>
  )
}

const FailureMessage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-wrap my-4">
      <i
        className="fas fa-exclamation-circle text-danger align-middle mr-3 mb-4"
        style={{ fontSize: '2rem' }}
      ></i>
      <p className="text-center" style={{ fontSize: '1rem' }}>
        Ein Fehler ist aufgetreten. Ihr Stand konnte nicht gespeichert werden
      </p>
    </div>
  )
}

export const Dismiss = ({ onHide }) => {
  const [{ saveMessage, survey }] = useSurveyDataStore()
  const [disabledButton, setDisalbedButton] = useState(false)

  useEffect(() => {
    if (saveMessage.type === 'success') {
      setDisalbedButton(true)
      setTimeout(() => {
        setDisalbedButton(false)
        onHide()
      }, 2000)
    }
  }, [onHide, saveMessage])

  return (
    <div>
      <div className="header">
        {saveMessage.type === '' ? (
          <h2>Umfrage schließen</h2>
        ) : saveMessage.type === 'success' ? (
          <h2>Speichern erfolgreich</h2>
        ) : (
          <h2>Speichern fehlgeschlagen</h2>
        )}
      </div>
      <div className="body">
        {saveMessage.type === '' ? (
          <>
            <div style={{ fontSize: '1rem' }}>Möchten sie die Umfrage wirklich abbrechen?</div>
            <p style={{ fontSize: '1rem' }} className="mt-3">
              Sie können ihren Stand speichern und die Bearbeitung zu einem anderen Zeitpunkt fortsetzen.
            </p>
          </>
        ) : saveMessage.type === 'success' ? (
          <SuccessMessage />
        ) : (
          <FailureMessage />
        )}
      </div>
      <div className="d-flex justify-content-center justify-content-sm-between footer flex-wrap">
        {!disabledButton &&
          (survey.type === 'riwis' ? (
            <>
              <Button className="riwis-btn riwis-secondary my-3 mx-4" onClick={() => onHide(null)}>
                Beenden ohne speichern
              </Button>
              <RiwisSaveButton dismiss={true} className="my-3 mx-4" label="Beenden und Speichern" />
            </>
          ) : (
            <>
              {' '}
              <Button variant="secondary" onClick={() => onHide(null)} className="my-3 mx-4">
                Beenden ohne speichern
              </Button>
              <SaveButton dismiss={true} label="Beenden und Speichern" className="my-3 mx-4" />
            </>
          ))}
      </div>
    </div>
  )
}
