import { Container } from 'react-bootstrap'

export const RegistrationFeedback = ({ message }) => {
  return (
    <Container>
      {message.type === 'success' ? (
        <div className="registrationFeedback">
          <div className="d-flex justify-content-center align-items-center flex-wrap">
            <i className="far fa-envelope" style={{ fontSize: '5rem' }}></i>
            <h3 className="text-center px-5 mt-3 mt-sm-0">Vielen Dank für Ihre Registrierung</h3>
          </div>
          <div className="mt-5 text-center w-100">
            Sie erhalten von uns in Kürze eine Bestätigungsmail für die Aktivierung ihres Survey-Desk Kontos.
            Sobald die Aktivierung abgeschlossen ist, können Sie sich mit Ihren Benutzer-Daten anmelden.
          </div>
        </div>
      ) : (
        <div className="registrationFeedback">
          <div className="d-flex justify-content-center align-items-center flex-wrap">
            <i className="fas fa-times" style={{ fontSize: '5rem' }}></i>
            <h3 className="text-center px-5 mt-3 mt-sm-0">Registrierung fehlgeschlagen!</h3>
          </div>
          <div className="mt-5 text-center w-100">
            Leider konnte Ihre Registrierung nicht abgeschlossen werden. Bitte versuchen Sie es erneut oder
            wenden Sie sich an unseren <a href="mailto:survey-desk@bulwiengesa.de">Survey-Desk Support</a>.
          </div>
        </div>
      )}
    </Container>
  )
}
