import { useEffect } from 'react'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'
import { Container } from '../../components/Container'
import { useUserDataStore } from '../../stores/userStore'

const Feedback = ({ message }) => {
  return !message.err ? (
    <div className="registrationFeedback">
      <div className="d-flex justify-content-center align-items-center flex-wrap">
        <h3 className="text-center px-5 mt-3 mt-sm-0">Sie werden eingeloggt</h3>
      </div>
    </div>
  ) : (
    <div className="registrationFeedback">
      <div className="d-flex justify-content-center align-items-center flex-wrap">
        <i className="fas fa-times" style={{ fontSize: '5rem' }}></i>
        <h3 className="text-center px-5 mt-3 mt-sm-0">Anmeldung fehlgeschlagen</h3>
      </div>
      {message.err === 'bad token' ? (
        <div className="mt-5 text-center">
          Leider ist ihr Token ungültig. Bitte fordern Sie bei unserem{' '}
          <a href="mailto:survey-desk@bulwiengesa.de">Survey-Desk Support</a> einen neuen Token zur Anmeldung
          an Ihrem Konto an.
        </div>
      ) : message.err === 'token expired' ? (
        <div>
          Leider ist ihr Token abgelaufen. Bitte fordern Sie bei unserem{' '}
          <a href="mailto:survey-desk@bulwiengesa.de">Survey-Desk Support</a> einen neuen Token zur Anmeldung
          an Ihrem Konto an.
        </div>
      ) : (
        <div>
          Leider ist ein Fehler bei Ihrer Anmeldung aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie
          sich an unseren <a href="mailto:survey-desk@bulwiengesa.de">Survey-Desk Support</a>.
        </div>
      )}
    </div>
  )
}

export const TokenLogin = () => {
  const [{ redirectTo, loginMessage }, { loginUserToken }] = useUserDataStore()
  const { token } = useParams()

  useEffect(() => {
    if (token) {
      loginUserToken(token)
    }
  }, [token, loginUserToken])

  return !redirectTo ? (
    <Container>
      <Feedback message={loginMessage} />
    </Container>
  ) : (
    <Redirect to={redirectTo}></Redirect>
  )
}
