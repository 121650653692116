/* This function closes the gap between numbers for ordering.
 * It expects an array of objects. Each object must have a field order.
 * First the objects are sorted by order field. After that, the order fields
 * are rearranged, eliminating any gap between successive order numbers.
 * Note: All modifications of the given array happens in place!
 * E.g:    [ { id: 1, order: 7 }, { id: 2, order: 3 }, { id: 3, order: 1 } ]
 *      => [ { id: 3, order: 1 }, { id: 2, order: 3 }, { id: 1, order: 7 } ]
 *      => [ { id: 3, order: 1 }, { id: 2, order: 2 }, { id: 1, order: 3 } ]
 */
export function closeGaps(objectsArray) {
  // sort
  objectsArray.sort((a, b) => a.order - b.order)

  console.log('closeGaps: after sort', objectsArray)

  // close gaps
  objectsArray.reduce((prev, curr, currIndex, array) => {
    if (curr.order > prev.order + 1) {
      array[currIndex].order = prev.order + 1
    }
    return curr
  })

  console.log('closeGaps: after closeGaps', objectsArray)

  return objectsArray
}

// check if TinyMCE has been instanced for given element
// -1 means there is no such element for given selector
export function checkTinyMCEExistence(selector) {
  const element = document.querySelector(selector)
  if (!element) return -1
  return element.classList.contains('mce-content-body') ? 1 : 0
}

export function generateId(length) {
  const l = length + 4
  const l1 = Math.ceil(l / 2)
  const l2 = Math.floor(l / 2)
  return Math.random().toString(36).substring(2, l1) + Math.random().toString(36).substring(2, l2)
}

export const findLastNonDeleted = (index, items, direction) => {
  const newItems = [...items]
  newItems[index] = { ...newItems[index] }
  newItems[index].deleted = true
  direction = !direction ? 'down' : direction
  if (!newItems.filter((question) => !question.deleted).length) return 0
  const down = () => {
    if (index - 1 >= 0) {
      const lastItemIndex = index - 1
      const lastItem = newItems[lastItemIndex]
      if (lastItem?.deleted) {
        return findLastNonDeleted(lastItemIndex, newItems, 'down')
      } else {
        return lastItemIndex
      }
    } else {
      return up()
    }
  }
  const up = () => {
    if (index + 1 < items.length) {
      const nextItemIndex = index + 1
      const nextItem = newItems[nextItemIndex]
      if (nextItem?.deleted) {
        return findLastNonDeleted(nextItemIndex, newItems, 'up')
      } else {
        return nextItemIndex
      }
    } else {
      return down()
    }
  }

  if (direction === 'down') {
    return down()
  } else {
    return up()
  }
}

export const stripString = (htmlString) => htmlString.replace(/(<([^>]+)>)/gi, '')

const UMLEnum = {
  '&uuml;': 'ü',
  '&Uuml;': 'Ü',
  '&ouml;': 'ö',
  '&Ouml;': 'Ö',
  '&auml;': 'ä',
  '&Auml;': 'Ä',
}

export const decodeSpecialChars = (str) => {
  return str
    .replace(/&nbsp;/g, ' ')
    .replace(/&[a-zA-Z]uml;/g, (match) => {
      return UMLEnum[match]
    })
    .replace(/&szlig;/g, 'ß')
    .replace(/&amp;/g, '&')
}
