import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useSurveyDataStore } from './dataStore'
import { MissingInputWarning } from './missingInputWarning'
import { QuestionBuilder } from './QuestionBuilder'
import { IconEnum, TextEnum } from './QuestionTypes/RiwisSlider/mapHelper'
import { Modal } from '../../components/Modal'
import { Dismiss } from './ModalWindows/Dismiss'
import { checkConstraints } from './QuestionnaireBuilder/helper'

//todo
const onScrollY = () => {}

const getQuestionNumber = (questionCount, pageNumber, questionsPerPage) => {
  if (pageNumber === 0) {
    return questionCount
  } else {
    return questionsPerPage[pageNumber - 1].accumulatedQuestions + questionCount
  }
}

export const Page = ({ page, onHide }) => {
  // const [showScrollIndicatorTop, setShowScrollIndicatorTop] = useState(false)
  const [
    { survey, questionnaire, selectedCity, riwisDataIsFetching, pageNumber, missingInputs, questionsPerPage },
    { setAnswer },
  ] = useSurveyDataStore()
  const questionsContainerRef = useRef(null)
  const [showDismiss, setShowDismiss] = useState(false)
  let questionCount = 0

  const handleAnswerChange = useCallback(
    (answer) => {
      const questionIndex = page.questions.findIndex((question) => question.qid === answer.qid)
      setAnswer(pageNumber, questionIndex, answer)
    },
    [pageNumber, page, setAnswer]
  )

  useEffect(() => {
    if (pageNumber >= 0) {
      window.scrollTo({ top: 60 })
    }
  }, [questionsContainerRef, pageNumber])

  if (!page) return <div></div>
  return (
    <div id={page.pageId} key={page.pageId}>
      {missingInputs.length > 0 && (
        <MissingInputWarning
          error={
            !survey.show_star
              ? 'Bitte alle rot markierten Fragen beantworten.'
              : 'Bitte alle mit * gekennzeichneten Fragen beantworten.'
          }
          position={'sticky'}
        />
      )}
      {/* <div className={(showScrollIndicatorTop ? 'scrollIndicatorTop' : '') + ' pageHead'}> */}
      <div className="pageHead">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row">
            <div className="d-flex flex-column">
              {/* show additional infos for riwis surveys */}
              {questionnaire.asset_class !== null && selectedCity && (
                <div className="d-flex flex-row mb-3 align-items-start">
                  <h4 className="mr-2" style={{ fontSize: '1rem', color: '#212529', fontWeight: 400 }}>
                    {selectedCity.name.replace(' (Stadt)', '')}
                  </h4>
                  <h4 style={{ fontSize: '1rem', color: '#212529', fontWeight: 400 }}>
                    - {TextEnum[questionnaire.asset_class]}
                  </h4>
                  <div className="ml-3">
                    <img width={14} src={IconEnum[questionnaire.asset_class]} alt="" />
                  </div>
                </div>
              )}
              <div className="d-flex flex-row">
                <h4 className="pageTitle">{page.title}</h4>
              </div>

              {page?.description?.length > 0 && (
                <p
                  className="mt-3 mb-3 pageDescription"
                  dangerouslySetInnerHTML={{ __html: page.description }}
                ></p>
              )}
            </div>
          </div>
          <div className="crossContainer" onClick={() => setShowDismiss(true)}>
            <div className="crossBarOne">
              <div className="crossBarTwo"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="questionsContainer" onScroll={onScrollY} ref={questionsContainerRef}>
        {page.questions &&
          page.questions.map((question, index) => {
            return (
              (!riwisDataIsFetching || !question.datasource) && (
                <div key={question.id} className="question">
                  {checkConstraints(question.constraints, page) && (
                    <QuestionBuilder
                      question={question}
                      lastQuestionNo={getQuestionNumber(++questionCount, pageNumber, questionsPerPage)}
                      showStar={survey.show_star}
                      missingInputs={missingInputs}
                      handleAnswerChange={handleAnswerChange}
                      pageNumber={pageNumber}
                      questionIndex={index}
                      selectedCity={selectedCity || null}
                    />
                  )}

                  {checkConstraints(question.constraints, page) && page.questions.length - 1 !== index && (
                    <hr style={{ marginTop: '2rem' }}></hr>
                  )}
                </div>
              )
            )
          })}
      </div>
      {showDismiss === true && (
        <Modal onHide={setShowDismiss} minWidth={'500px'}>
          <Dismiss onHide={onHide} />
        </Modal>
      )}
      {/* <div className={showScrollIndicatorBottom ? 'scrollIndicatorBottom' : ''}></div> */}
    </div>
  )
}
