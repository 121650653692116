import { useState } from 'react'

export const InputColumns = ({
  id,
  qid,
  dataType,
  answers,
  value,
  minValue,
  maxValue,
  unit,
  onChange,
  withDecimal,
}) => {
  const [labelLength] = useState(() => {
    return answers.reduce((length, answer) => {
      if (answer.text.length > length) {
        length = answer.text.length
      }
      return length
    }, 0)
  })

  const handleChange = (event) => {
    const givenAnswer = { ...value } || {}
    let inputValue = event.target.value

    if (dataType === 'number') {
      if (inputValue < minValue) {
        inputValue = withDecimal ? minValue : parseInt(minValue)
      }
      if (inputValue > maxValue) {
        inputValue = withDecimal ? maxValue : parseInt(maxValue)
      }
    }
    givenAnswer[event.target.name] = inputValue
    onChange({ question_id: id, value: givenAnswer, qid })
  }
  return (
    <>
      {answers.map((answer) => (
        <div key={`${id}_${answer.id}`} className="form-group">
          <div className="row">
            <div
              className={
                (labelLength > 14 ? 'col-xl-3 col-lg-3 col-md-4' : 'col-xl-2 col-lg-2 col-md-3') +
                ' text-nowrap mb-3'
              }
            >
              {answer.text}
            </div>
            <div className="input-group col-sm-12 col-md-5">
              <input
                name={answer.id}
                onChange={handleChange}
                className="form-control"
                type={dataType}
                value={value[answer.id]}
                min={minValue}
                max={maxValue}
              ></input>
              {unit && (
                <div className="input-group-append">
                  <span className="input-group-text" id="input-unit">
                    {unit}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
