import createStore from '../../../../lib/flux-store'
import { Survey, UserGroup, Template } from '../../../../helper/api'
import { generateId } from './utils'

const initialState = {
  dataIsLoading: true,
  saveMessage: { msg: '', error: '', type: '' },
  survey: {
    id: 0,
    from: new Date(),
    to: new Date(),
    title: '',
    description: '',
    primaryColor: '',
    secondaryColor: '',
    status: 'draft',
    subtitle: '',
    support_contact: null,
    usergroups: null,
    type_of_survey: 'closed',
    logo: null,
    show_star: true,
    questionnaires: [],
    type: 'survey',
    datasource_year: null,
  },
  userGroups: [],
  editMode: false,
  templateTitle: '',
  templateList: null,
  template: null,
  editTemplate: false,
  questionnaireTab: 0,
  pageTab: 0,
  questionTab: 0,
}

const actions = {
  initSurvey: (surveyId) => (dispatch) => {
    dispatch({ type: 'setDataIsLoading', payload: true })
    Promise.all([Survey.getById(surveyId), UserGroup.getAll()]).then((res) => {
      const [surveyData, userGroupsData] = res
      const payload = {
        survey: surveyData.data.survey,
        userGroups: userGroupsData.data.userGroups,
        editMode: surveyData.data.editMode,
      }
      dispatch({ type: 'initSurvey', payload })
      dispatch({ type: 'setDataIsLoading', payload: false })
    })
  },
  fetchTemplateList: () => (dispatch) => {
    Template.getList().then((res) => {
      dispatch({ type: 'setTemplateList', templateList: res.data.list })
    })
  },
  fetchTemplate: (templateId) => (dispatch) => {
    Template.getById(templateId).then((res) => {
      dispatch({ type: 'setTemplate', template: res.data.template })
    })
  },
  setTemplateTitle: (templateTitle) => ({ templateTitle }),
  saveTemplate: (title, template, id) => (dispatch) => {
    Template.saveTemplate(title, template, id).then((res) => {
      if (res.data.templateId) {
        dispatch({
          type: 'setSaveMessage',
          saveMessage: { msg: 'Vorlage gespeichert', type: 'success', error: '' },
        })
      } else {
        dispatch({
          type: 'setSaveMessage',
          saveMessage: {
            msg: 'Vorlage konnte nicht gespeichert werden',
            type: 'failed',
            error: res.data.err,
          },
        })
      }
    })
  },
  setSurvey: (survey, editTemplate = null) => ({ survey, editTemplate }),
  saveSurvey: (survey) => (dispatch) => {
    Survey.save(survey)
      .then((res) => {
        if (res.data.survey) {
          const payload = {
            survey: res.data.survey,
            saveMessage: { msg: 'Umfrage gespeichert', type: 'success', err: '' },
          }
          dispatch({ type: 'saveSurvey', payload })
        } else {
          const saveMessage = { msg: '', error: res.data.error, type: 'failed' }
          dispatch({ type: 'setSaveMessage', saveMessage })
        }
      })
      .catch((err) =>
        dispatch({ type: 'setSaveMessage', saveMessage: { msg: 'Fehler!', type: 'failed', error: err } })
      )
  },
  setSaveMessage: (saveMessage) => ({ saveMessage }),
  setUserGroups: (userGroups) => ({ userGroups }),
  setSurveyInput: (value, property) => ({ value, property }),
  setQuestionnaireInput: (index, value, property) => ({ index, value, property }),
  addQuestionnaire: (initialProperties) => ({ initialProperties }),
  setPageInput: (questionnaireIndex, pageIndex, value, property) => ({
    questionnaireIndex,
    pageIndex,
    value,
    property,
  }),
  addPage: (questionnaireIndex, initialProperties) => ({ questionnaireIndex, initialProperties }),
  setQuestionInput: (questionnaireIndex, pageIndex, questionIndex, value, property) => ({
    questionnaireIndex,
    pageIndex,
    questionIndex,
    value,
    property,
  }),
  addQuestion: (questionnaireIndex, pageIndex, initialProperties) => ({
    questionnaireIndex,
    pageIndex,
    initialProperties,
  }),
  deletePage: (questionnaireIndex, pageIndex) => ({ questionnaireIndex, pageIndex }),
  deleteQuestion: (questionnaireIndex, pageIndex, questionIndex) => ({
    questionnaireIndex,
    pageIndex,
    questionIndex,
  }),
  deleteQuestionnaire: (questionnaireIndex) => ({ questionnaireIndex }),
  setQuestionnaireTab: (index) => ({ index }),
  setPageTab: (index) => ({ index }),
  setQuestionTab: (index) => ({ index }),
  changeQuestionOrder: (questionnaireIndex, pageIndex, questions) => ({
    questionnaireIndex,
    pageIndex,
    questions,
  }),
  changePageOrder: (questionnaireIndex, pages) => ({ questionnaireIndex, pages }),
  changeQuestionnaireOrder: (questionnaires) => ({ questionnaires }),
}

const reducer = {
  setDataIsLoading: (state, { payload }) => {
    return { ...state, dataIsLoading: payload }
  },
  setSaveMessage: (state, { saveMessage }) => {
    return { ...state, saveMessage }
  },
  initSurvey: (state, { payload }) => {
    return {
      ...state,
      survey: migrateOldSurvey(payload.survey),
      userGroups: payload.userGroups,
      editMode: payload.editMode,
    }
  },
  setTemplate: (state, { template }) => {
    return { ...state, template }
  },
  setTemplateList: (state, { templateList }) => {
    return { ...state, templateList }
  },
  setTemplateTitle: (state, { templateTitle }) => {
    return { ...state, templateTitle }
  },
  setSurvey: (state, { survey, editTemplate }) => {
    return { ...state, survey, editTemplate }
  },
  saveSurvey: (state, { payload }) => {
    return { ...state, survey: payload.survey, saveMessage: payload.saveMessage }
  },
  setUserGroups: (state, { userGroups }) => {
    return { ...state, userGroups }
  },
  setSurveyInput: (state, { value, property }) => {
    const newSurvey = { ...state.survey }
    newSurvey[property] = value
    return { ...state, survey: newSurvey }
  },
  setQuestionnaireInput: (state, { index, value, property }) => {
    const newSurvey = { ...state.survey }
    const newQuestionnaires = [...newSurvey.questionnaires]
    const newQuestionnaire = { ...newQuestionnaires[index] }
    newQuestionnaire[property] = value
    newQuestionnaires[index] = newQuestionnaire
    newSurvey.questionnaires = newQuestionnaires
    return { ...state, survey: newSurvey }
  },
  addQuestionnaire: (state, { initialProperties }) => {
    const newSurvey = { ...state.survey }
    const newQuestionnaires = [...newSurvey.questionnaires, initialProperties]
    newSurvey.questionnaires = newQuestionnaires
    return { ...state, survey: newSurvey }
  },
  setPageInput: (state, { questionnaireIndex, pageIndex, value, property }) => {
    const newSurvey = { ...state.survey }
    const newQuestionnaires = [...newSurvey.questionnaires]
    const newQuestionnaire = { ...newQuestionnaires[questionnaireIndex] }
    const newPages = [...newQuestionnaire.pages]
    const newPage = { ...newPages[pageIndex] }
    newPage[property] = value
    newPages[pageIndex] = newPage
    newQuestionnaire.pages = newPages
    newQuestionnaires[questionnaireIndex] = newQuestionnaire
    newSurvey.questionnaires = newQuestionnaires
    return { ...state, survey: newSurvey }
  },
  addPage: (state, { questionnaireIndex, initialProperties }) => {
    const newSurvey = { ...state.survey }
    const newQuestionnaires = [...newSurvey.questionnaires]
    const newQuestionnaire = { ...newQuestionnaires[questionnaireIndex] }
    let newPages = []
    if (newQuestionnaire.pages) {
      newPages = [...newQuestionnaire.pages, initialProperties]
    } else {
      newPages.push(initialProperties)
    }
    newQuestionnaire.pages = newPages
    newQuestionnaires[questionnaireIndex] = newQuestionnaire
    newSurvey.questionnaires = newQuestionnaires
    return { ...state, survey: newSurvey }
  },
  setQuestionInput: (state, { questionnaireIndex, pageIndex, questionIndex, value, property }) => {
    const newSurvey = { ...state.survey }
    const newQuestionnaires = [...newSurvey.questionnaires]
    const newQuestionnaire = { ...newQuestionnaires[questionnaireIndex] }
    const newPages = [...newQuestionnaire.pages]
    const newPage = { ...newPages[pageIndex] }
    const newQuestions = [...newPage.questions]
    const newQuestion = { ...newQuestions[questionIndex] }
    if (Array.isArray(value) && !property) {
      value.forEach((item) => (newQuestion[item.property] = item.value))
    } else {
      newQuestion[property] = value
    }
    newQuestions[questionIndex] = newQuestion
    newPage.questions = newQuestions
    newPages[pageIndex] = newPage
    newQuestionnaire.pages = newPages
    newQuestionnaires[questionnaireIndex] = newQuestionnaire
    newSurvey.questionnaires = newQuestionnaires
    return { ...state, survey: newSurvey }
  },
  addQuestion: (state, { questionnaireIndex, pageIndex, initialProperties }) => {
    const newSurvey = { ...state.survey }
    const newQuestionnaires = [...newSurvey.questionnaires]
    const newQuestionnaire = { ...newQuestionnaires[questionnaireIndex] }
    const newPages = [...newQuestionnaire.pages]
    const newPage = { ...newPages[pageIndex] }
    let newQuestions = []
    if (newPage.questions) {
      newQuestions = [...newPage.questions, initialProperties]
    } else {
      newQuestions.push(initialProperties)
    }
    newPage.questions = newQuestions
    newPages[pageIndex] = newPage
    newQuestionnaire.pages = newPages
    newQuestionnaires[questionnaireIndex] = newQuestionnaire
    newSurvey.questionnaires = newQuestionnaires
    return { ...state, survey: newSurvey }
  },
  deletePage: (state, { questionnaireIndex, pageIndex }) => {
    const newSurvey = { ...state.survey }
    const newQuestionnaires = [...newSurvey.questionnaires]
    const newQuestionnaire = { ...newQuestionnaires[questionnaireIndex] }
    let newPages = [...newQuestionnaire.pages]
    const newPage = { ...newPages[pageIndex] }
    if (newPage.id > 0) {
      newPage.deleted = true
      newPages[pageIndex] = newPage
    } else {
      newPages.splice(pageIndex, 1)
    }
    newQuestionnaire.pages = newPages
    newQuestionnaires[questionnaireIndex] = newQuestionnaire
    newSurvey.questionnaires = newQuestionnaires
    return { ...state, survey: newSurvey }
  },

  deleteQuestionnaire: (state, { questionnaireIndex }) => {
    const newSurvey = { ...state.survey }
    const newQuestionnaires = [...newSurvey.questionnaires]
    let newQuestionnaire = { ...newQuestionnaires[questionnaireIndex] }
    if (newQuestionnaire.id > 0) {
      newQuestionnaire.deleted = true
      newQuestionnaires[questionnaireIndex] = newQuestionnaire
    } else {
      newQuestionnaires.splice(questionnaireIndex, 1)
    }
    newSurvey.questionnaires = newQuestionnaires
    return { ...state, survey: newSurvey }
  },
  deleteQuestion: (state, { questionnaireIndex, pageIndex, questionIndex }) => {
    const newSurvey = { ...state.survey }
    const newQuestionnaires = [...newSurvey.questionnaires]
    const newQuestionnaire = { ...newQuestionnaires[questionnaireIndex] }
    const newPages = [...newQuestionnaire.pages]
    const newPage = { ...newPages[pageIndex] }
    const newQuestions = [...newPage.questions]
    const newQuestion = { ...newQuestions[questionIndex] }
    newQuestion.deleted = true
    newQuestions[questionIndex] = newQuestion
    let order = 0
    const newOrderedQuestions = newQuestions.reduce((arr, question) => {
      if (!question.deleted) {
        const newQuestion = { ...question }
        newQuestion.order = ++order
        arr.push(newQuestion)
      } else {
        if (question.id > 0) {
          const newQuestion = { ...question }
          newQuestion.order = null
          arr.push(newQuestion)
        }
      }
      return arr
    }, [])
    newPage.questions = newOrderedQuestions
    newPages[pageIndex] = newPage
    newQuestionnaire.pages = newPages
    newQuestionnaires[questionnaireIndex] = newQuestionnaire
    newSurvey.questionnaires = newQuestionnaires
    return { ...state, survey: newSurvey }
  },
  setQuestionnaireTab: (state, { index }) => {
    return { ...state, questionnaireTab: index, pageTab: 0, questionTab: 0 }
  },
  setPageTab: (state, { index }) => {
    return { ...state, pageTab: index, questionTab: 0 }
  },
  setQuestionTab: (state, { index }) => {
    return { ...state, questionTab: index }
  },
  changeQuestionOrder: (state, { questionnaireIndex, pageIndex, questions }) => {
    const newSurvey = { ...state.survey }
    const newQuestionnaires = [...newSurvey.questionnaires]
    const newQuestionnaire = { ...newQuestionnaires[questionnaireIndex] }
    const newPages = [...newQuestionnaire.pages]
    const newPage = { ...newPages[pageIndex] }
    newPage.questions = questions
    newPages[pageIndex] = newPage
    newQuestionnaire.pages = newPages
    newQuestionnaires[questionnaireIndex] = newQuestionnaire
    newSurvey.questionnaires = newQuestionnaires
    return { ...state, survey: newSurvey }
  },
  changePageOrder: (state, { questionnaireIndex, pages }) => {
    const newSurvey = { ...state.survey }
    const newQuestionnaires = [...newSurvey.questionnaires]
    const newQuestionnaire = { ...newQuestionnaires[questionnaireIndex] }
    newQuestionnaire.pages = pages
    newQuestionnaires[questionnaireIndex] = newQuestionnaire
    newSurvey.questionnaires = newQuestionnaires
    return { ...state, survey: newSurvey }
  },
  changeQuestionnaireOrder: (state, { questionnaires }) => {
    const newSurvey = { ...state.survey }
    const newQuestionnaires = questionnaires
    newSurvey.questionnaires = newQuestionnaires
    return { ...state, survey: newSurvey }
  },
}

export const [SurveyEditorStateContext, SurveyEditorStateProvider, useSurveyEditorStateStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'SurveyEditorStateStore'
)

const migrateOldSurvey = (survey) => {
  survey?.questionnaires?.forEach((questionnaire) => {
    if (!questionnaire.qnid) {
      questionnaire.qnid = generateId(6)
    }
    questionnaire?.pages?.forEach((page) => {
      page?.questions?.forEach((question) => {
        if (!question.qid) {
          question.qid = generateId(6)
        }
      })
    })
  })
  return survey
}
