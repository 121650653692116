import { useCallback, useMemo } from 'react'
import { useReportingDataStore } from './dataStore'
import XLSX from 'xlsx'
import { TextEnum } from '../../Survey/QuestionTypes/RiwisSlider/mapHelper'
import { stripString, decodeSpecialChars } from '../Survey/edit/utils'
import { commentCategories } from '../Survey/edit/QuestionTypes/RiwisCategories'

const userInfosCols = [
  'topix_id',
  'username',
  'is_riwis_user',
  'first_name',
  'last_name',
  'company',
  'generic_area_code',
]

const findCommentHeader = (fileMakerName) => {
  const commentHeaders = Object.entries(commentCategories)
  for (let [commentHeader, values] of commentHeaders) {
    if (values.some((value) => value === fileMakerName)) {
      return commentHeader
    }
  }
}

const getFilemakerNames = (questions) => {
  const names = []
  questions.forEach((question) => {
    question.riwis_datasources.forEach((datasource) => {
      const commentHeaders = Object.entries(commentCategories)
      let commentHeader = null
      commentHeaders.forEach(([header, value]) => {
        if (value[value.length - 1] === datasource.fileMaker) {
          commentHeader = header
        }
      })
      names.push(datasource.fileMaker)
      if (commentHeader !== null) {
        names.push(commentHeader)
      }
    })
  })
  return names
}

const answersOfUsers = (colHeaders, riwisReport, assetClass) => {
  const answers = []
  Object.values(riwisReport[assetClass]).forEach((gacGroup) => {
    Object.values(gacGroup).forEach((userAnswersOfGac) => {
      const row = []
      let notes = ''
      let currentCommentHeader = ''
      let edit_date = null
      let create_date = null
      const typeIndex = colHeaders.indexOf('typ')
      const uniqueId = `${userAnswersOfGac[0].userId}${userAnswersOfGac[0].questionnaire_id}${userAnswersOfGac[0].generic_area_code}`
      const uniqueIdIndex = colHeaders.indexOf('id')
      row[uniqueIdIndex] = uniqueId
      row[typeIndex] = TextEnum[assetClass]
      userAnswersOfGac.forEach((userAnswer) => {
        const answer_create_date = new Date(userAnswer.create_date).getTime()
        const answer_updated_at = userAnswer.updated_at ? new Date(userAnswer.updated_at).getTime() : null
        if (create_date === null || create_date > answer_create_date) {
          const index = colHeaders.indexOf('Erstellungsdatum')
          create_date = answer_create_date
          row[index] = new Date(create_date)
        }
        if (
          (edit_date === null && answer_updated_at !== null) ||
          edit_date < answer_create_date ||
          edit_date < answer_updated_at
        ) {
          const index = colHeaders.indexOf('Bearbeitungsdatum')
          if ((edit_date === null && answer_updated_at !== null) || edit_date < answer_updated_at) {
            edit_date = answer_updated_at
            row[index] = new Date(edit_date)
          } else if (edit_date !== null && edit_date !== answer_create_date) {
            edit_date = answer_create_date
            row[index] = new Date(edit_date)
          }
        }
        userInfosCols.forEach((userInfo) => {
          const index = colHeaders.indexOf(userInfo)
          row[index] = userAnswer[userInfo]
        })
        if (userAnswer.question_type === 'radioInlineRiwis') {
          const index = colHeaders.indexOf(userAnswer.value.value.fileMaker)
          const answer = userAnswer.possible_answers.find(
            (answer) => answer.id === parseInt(userAnswer.value.value.value)
          )
          if (answer) {
            row[index] = answer.text
          }
          if (userAnswer.value.value.note) {
            const commentHeader = findCommentHeader(userAnswer.riwis_datasources[0].fileMaker)
            const index = colHeaders.indexOf(commentHeader)
            row[index] = row[index] || ''
            row[index] += decodeSpecialChars(stripString(userAnswer.label)) + ': '
            row[index] += userAnswer.value.value.note
            row[index] = row[index].replace(/\n/g, ' ')
          }
        } else if (userAnswer.question_type === 'radioMatrixRiwis') {
          const index = colHeaders.indexOf(userAnswer.value.value.fileMaker)
          const [yId, xId] = Object.entries(userAnswer.value.value)[0]
          const y = userAnswer.questions_field.findIndex((question) => question.id === parseInt(yId)) + 1
          const x = userAnswer.possible_answers.findIndex((answer) => answer.id === parseInt(xId)) + 1
          // if values have been found
          if (x > 0 && y > 0) {
            row[index] = `(${x},${y})`
          }
          if (userAnswer.value.value.note) {
            const commentHeader = findCommentHeader(userAnswer.riwis_datasources[0].fileMaker)
            const index = colHeaders.indexOf(commentHeader)
            row[index] = row[index] || ''
            row[index] += decodeSpecialChars(stripString(userAnswer.label)) + ': '
            row[index] += userAnswer.value.value.note
            row[index] = row[index].replace(/\n/g, ' ')
          }
        } else {
          Object.entries(userAnswer.value.value).forEach(([key, value]) => {
            if (key === 'note') {
              const commentHeader = findCommentHeader(userAnswer.riwis_datasources[0].fileMaker)
              if (currentCommentHeader !== commentHeader) {
                currentCommentHeader = commentHeader
                notes = ''
              }
              if (!commentHeader) {
                console.error('No header found for', userAnswer.riwis_datasources[0].fileMaker)
              }
              const index = colHeaders.indexOf(findCommentHeader(userAnswer.riwis_datasources[0].fileMaker))
              notes += decodeSpecialChars(stripString(userAnswer.label)) + ': '
              notes += value
              row[index] = notes.replace(/\n/g, ' ')
            } else {
              const index = colHeaders.indexOf(value.fileMaker)
              row[index] = value.userValue
            }
          })
        }
      })
      answers.push(row)
    })
  })
  return answers
}

export const Results = () => {
  const [{ report }] = useReportingDataStore()

  const asXLSX = useCallback((table, fileName) => {
    let workbook = XLSX.utils.table_to_book(table)
    XLSX.writeFile(workbook, fileName)
  }, [])

  const createXLSX = useCallback(() => {
    const fileName = 'results_' + report.currentSurvey + '.xlsx'
    asXLSX(document.getElementById('surveyAnswers'), fileName)
  }, [report, asXLSX])

  const riwisReport = useMemo(() => {
    if (!report || report.type !== 'riwis') return null
    const groupByAssetClassAndGac = report.answers.reduce((obj, answer) => {
      if (
        obj.hasOwnProperty(answer.asset_class) &&
        obj[answer.asset_class].hasOwnProperty(answer.generic_area_code) &&
        obj[answer.asset_class][answer.generic_area_code].hasOwnProperty(answer.username)
      ) {
        obj[answer.asset_class][answer.generic_area_code][answer.username].push(answer)
      } else {
        if (obj.hasOwnProperty(answer.asset_class)) {
          if (obj[answer.asset_class].hasOwnProperty(answer.generic_area_code)) {
            if (obj[answer.asset_class][answer.generic_area_code].hasOwnProperty(answer.username)) {
              obj[answer.asset_class][answer.generic_area_code][answer.username].push(answer)
            } else {
              // asset class and gac exists, user missing
              obj[answer.asset_class][answer.generic_area_code][answer.username] = [answer]
            }
          } else {
            // asset class exists, gac and user missing
            obj[answer.asset_class][answer.generic_area_code] = {}
            obj[answer.asset_class][answer.generic_area_code][answer.username] = [answer]
          }
        } else {
          // asset class does not exist yet
          obj[answer.asset_class] = {}
          obj[answer.asset_class][answer.generic_area_code] = {}
          obj[answer.asset_class][answer.generic_area_code][answer.username] = [answer]
        }
      }
      return obj
    }, {})
    return groupByAssetClassAndGac
  }, [report])

  const generateXLSX = useCallback(() => {
    if (report) {
      const questionGroups = report.questions.reduce((obj, question) => {
        if (Object.prototype.hasOwnProperty.call(obj, question.asset_class)) {
          obj[question.asset_class].push(question)
        } else {
          obj[question.asset_class] = [question]
        }
        return obj
      }, {})
      const workbook = XLSX.utils.book_new()
      workbook.Props = {
        Title: report.currentSurvey,
        CreatedDate: new Date(),
      }

      Object.entries(TextEnum).forEach(([key, value]) => {
        const colHeaders = [
          'id',
          'Erstellungsdatum',
          'Bearbeitungsdatum',
          'typ',
          ...userInfosCols,
          ...getFilemakerNames(questionGroups[key]),
        ]
        const answers = answersOfUsers(colHeaders, riwisReport, key)
        const ws = XLSX.utils.aoa_to_sheet([colHeaders, ...answers])
        const index = workbook.SheetNames.push(value)
        workbook.Sheets[workbook.SheetNames[index - 1]] = ws
      })
      XLSX.writeFile(workbook, 'results_' + report.currentSurvey + '.xlsx')
    }
  }, [report, riwisReport])

  return (
    report &&
    (report.type === 'riwis' ? (
      <div className="d-flex justify-content-center mt-5">
        <button className="btn btn-primary" onClick={generateXLSX}>
          <div className="d-flex flex-column">
            <i style={{ fontSize: '3rem' }} className="fas fa-file-excel h3 m-0"></i>
            <div style={{ fontSize: '1rem' }} className="mt-3">
              Excel Report
            </div>
          </div>
        </button>
      </div>
    ) : (
      <div style={{ overflowX: 'auto', width: '100%' }}>
        <button className="btn btn-outline-primary" onClick={createXLSX}>
          <i className="fas fa-file-excel h3 m-0"></i>
        </button>
        <table className="table table-sm table-bordered" id="surveyAnswers">
          <thead className="bg-primary text-light">
            <tr>
              <th scope="col" rowSpan="2">
                Teilnehmer
              </th>
              {report?.questions.map((question) => {
                if (question?.questions_field && question?.questions_field.length > 0) {
                  return question.questions_field.map((questionField, index) => (
                    <th
                      key={question.question_id + '-' + index}
                      scope="col"
                      colSpan={question.possible_answers ? question.possible_answers.length : 1}
                      dangerouslySetInnerHTML={{
                        __html: stripString(question.question) + '<br/><br/>' + questionField.text,
                      }}
                    ></th>
                  ))
                } else {
                  return (
                    <th
                      key={question.question_id}
                      scope="col"
                      colSpan={question.possible_answers ? question.possible_answers.length : 1}
                      dangerouslySetInnerHTML={{ __html: stripString(question.question) }}
                    ></th>
                  )
                }
              })}
            </tr>
            <tr>
              {report?.questions.map((question) => {
                if (question?.questions_field?.length > 0) {
                  return question.questions_field.map(() => {
                    return question.possible_answers.map((possibleAnswer) => (
                      <th key={question.question_id + '-' + possibleAnswer.id} scope="col">
                        {possibleAnswer.text || '&nbsp;'}
                      </th>
                    ))
                  })
                } else {
                  if (question?.possible_answers?.length === 0) {
                    return <th key={question.question_id} scope="col"></th>
                  }
                  return question.possible_answers.map((possibleAnswer) => (
                    <th key={question.question_id + '-' + possibleAnswer.id}>{possibleAnswer.text}</th>
                  ))
                }
              })}
            </tr>
          </thead>
          <tbody>
            {report.answers.map((answersPerUser) => (
              <tr key={answersPerUser[0]}>
                {answersPerUser.map((answerOfUser, index) => (
                  <td key={`${answersPerUser[0]}-${index}`}>{answerOfUser}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ))
  )
}
