import React from 'react'

export class Text extends React.Component {
  constructor(props) {
    super(props)

    this.state = { givenAnswer: props.value }
  }

  handleChange = (event) => {
    let givenAnswer = this.state.givenAnswer
    givenAnswer = event.target.value
    this.setState({ givenAnswer: givenAnswer })
    this.props.onChange({ question_id: this.props.id, value: givenAnswer, qid: this.props.qid })
  }

  render() {
    return (
      <div className="form-group">
        <div className="row">
          <div className="col-12 col-md-5">
            <input
              type="text"
              className="form-control"
              name={this.props.id}
              value={this.state.givenAnswer}
              onChange={this.handleChange}
            ></input>
          </div>
        </div>
      </div>
    )
  }
}
