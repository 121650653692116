import React from 'react'

export class Textarea extends React.Component {
  constructor(props) {
    super(props)
    this.state = { givenAnswer: props.value }
  }

  handleChange = (event) => {
    let givenAnswer = this.state.givenAnswer
    givenAnswer = event.target.value
    this.setState({ givenAnswer: givenAnswer })
    this.props.onChange({ question_id: this.props.id, value: givenAnswer, qid: this.props.qid })
  }

  render() {
    return (
      <div className="form-group pr-4 pl-4">
        <div className="row">
          <textarea
            className="form-control col-sm-12 col-md-5"
            name={this.props.id}
            onChange={this.handleChange}
            value={this.state.givenAnswer || ''}
            id=""
            cols="6"
            rows="6"
            // style={{ resize: 'both', width: '50%' }}
            maxLength={this.props.limit}
          ></textarea>
        </div>
        <div className="mt-2 text-muted">
          {this.state.givenAnswer.length} / {this.props.limit} Zeichen
        </div>
      </div>
    )
  }
}
