import React from 'react'
import { useSurveyDataStore } from '../../dataStore'
import { getMissingInputs, getQuestionPerPageObject } from '../helper'
import { RiwisCloseButton, RiwisSaveButton } from './RiwisButtons'

export const PrevButton = () => {
  const [{ survey, pageNumber, questionnaire }, { setMissingInputs, setPageNumber }] = useSurveyDataStore()

  const handlePrevButton = () => {
    setMissingInputs([])
    setPageNumber(pageNumber - 1)
  }

  const prevVisibility = pageNumber < 1 ? 'hidden' : 'visible'
  if (questionnaire?.pages?.length + 1 === pageNumber) {
    return null
  }

  return (
    <button
      type="button"
      className={survey.type === 'riwis' ? 'riwis-btn riwis-secondary' : 'btn btn-primary text-uppercase'}
      style={{ visibility: prevVisibility }}
      onClick={handlePrevButton}
    >
      <span className="mr-4">&#9664;</span>zurück
    </button>
  )
}

export const NextButton = ({ onHide, setNextRiwisQuestionnaire }) => {
  const [
    { survey, questionnaire, isSaving, pageNumber, questionsPerPage },
    { setPageNumber, setMissingInputs, setQuestionsPerPage },
  ] = useSurveyDataStore()

  const handleNextButton = () => {
    const missingInputs = getMissingInputs(pageNumber, questionnaire)
    setMissingInputs(missingInputs)
    if (!missingInputs.length) {
      setPageNumber(pageNumber + 1)
      setQuestionsPerPage(getQuestionPerPageObject(questionnaire, pageNumber, questionsPerPage))
    }
  }

  if (questionnaire?.pages?.length + 1 === pageNumber) {
    return survey.type === 'riwis' ? (
      <RiwisCloseButton
        isSaving={isSaving}
        questionnaire={questionnaire}
        setNextRiwisQuestionnaire={setNextRiwisQuestionnaire}
        onHide={onHide}
      />
    ) : (
      <CloseButton onHide={onHide} isSaving={isSaving} surveyType={survey.type} />
    )
  } else if (questionnaire?.pages?.length === pageNumber) {
    if (survey.type === 'riwis') {
      return <RiwisSaveButton label="Beenden" />
    } else {
      return <SaveButton label="Beenden" />
    }
  } else {
    return (
      <button
        type="button"
        className={survey.type === 'riwis' ? 'riwis-btn riwis-primary' : 'btn btn-primary text-uppercase'}
        onClick={() => handleNextButton()}
      >
        weiter<span className="ml-4">&#9654;</span>
      </button>
    )
  }
}

const CloseButton = ({ isSaving, onHide }) => {
  return (
    <>
      {/* workaround for space-between*/}
      <div></div>
      <button
        type="button"
        onClick={() => onHide(null)}
        className="btn btn-primary text-uppercase"
        disabled={isSaving}
      >
        schliessen
      </button>
    </>
  )
}

export const SaveButton = ({ label, dismiss = false, className }) => {
  const [{ questionnaire, survey, pageNumber }, { saveAnswers }] = useSurveyDataStore()
  const nextPage = dismiss ? pageNumber : pageNumber + 1
  return (
    <button
      type="button"
      className={'btn btn-primary text-uppercase ' + className}
      onClick={() => saveAnswers(nextPage, questionnaire, survey.id)}
    >
      {label}
      <span className="ml-4">&#9654;</span>
    </button>
  )
}
