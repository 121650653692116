import React, { useCallback, useState } from 'react'

const placeholderText = `Die Entwicklungstendenz hat sich...`

export const RiwisRadioInline = ({ onChange, qid, answers, id, value, riwisDatasources }) => {
  const [showNote, setShowNote] = useState(() => (value.note ? true : false))

  const handleChange = useCallback(
    (event) => {
      const answerText = answers.find((answer) => parseInt(answer.id) === parseInt(event.target.value)).text
      onChange({
        question_id: id,
        value: {
          value: event.target.value,
          fileMaker: riwisDatasources[0].fileMaker,
          text: answerText,
          note: value.note || '',
        },
        qid,
      })
    },
    [onChange, qid, riwisDatasources, answers, id, value]
  )

  const toggleNote = useCallback(() => {
    setShowNote(!showNote)
  }, [setShowNote, showNote])

  const handleNoteChange = useCallback(
    (event) => {
      const givenAnswer = { ...value }
      givenAnswer.note = event.target.value
      onChange({
        question_id: id,
        value: givenAnswer,
        qid,
      })
    },
    [id, qid, onChange, value]
  )

  const resetAnswer = useCallback(() => {
    onChange({
      question_id: id,
      value: {
        fileMaker: riwisDatasources[0].fileMaker,
        note: value.note || '',
      },
      qid,
    })
  }, [value, onChange, id, riwisDatasources, qid])

  return (
    <div>
      {answers.map((answer) => (
        <div className="custom-control custom-radio custom-control-inline" key={`${id}_${answer.id}`}>
          <input
            className="custom-control-input"
            type="radio"
            name={id}
            id={`${id}_${answer.id}`}
            value={answer.id}
            onChange={handleChange}
            checked={parseInt(value.value) === answer.id}
          ></input>
          <label
            style={{ fontSize: '0.875rem' }}
            className="custom-control-label"
            htmlFor={`${id}_${answer.id}`}
          >
            {answer.text}
          </label>
        </div>
      ))}
      <div className="d-flex justify-content-between mt-5 flex-1">
        <div
          onClick={toggleNote}
          style={{
            color: 'rgb(0,41,64)',
            textDecoration: 'underline',
            cursor: 'pointer',
            width: 'fit-content',
            fontSize: '0.875rem',
          }}
        >
          Anmerkung hinzufügen
        </div>
        <div
          style={{
            color: 'rgb(0,41,64)',
            textDecoration: 'underline',
            cursor: 'pointer',
            width: 'fit-content',
            fontSize: '0.875rem',
          }}
          onClick={resetAnswer}
        >
          Antwort zurücksetzen
        </div>
      </div>
      {showNote && (
        <textarea
          value={value.note || ''}
          name="note"
          className="riwisTextareaWhite"
          style={{ width: '100%' }}
          placeholder={placeholderText}
          onChange={handleNoteChange}
        ></textarea>
      )}
    </div>
  )
}
