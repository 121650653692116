//-------------------------------------------------------
//             ListBox defines default props
//             at the end of this file
//-------------------------------------------------------
import React, { useState, useCallback } from 'react'
import { useSurveyEditorStateStore } from '../../surveyEditorState'

export const ListBox = ({ items, name, questionnaireIndex, pageIndex, index, label }) => {
  const [showNewInput, setShowNewInput] = useState(false)
  const [, { setQuestionInput }] = useSurveyEditorStateStore()

  const changeHandler = useCallback(
    (items) => {
      setQuestionInput(questionnaireIndex, pageIndex, index, [...items], name)
    },
    [questionnaireIndex, pageIndex, setQuestionInput, index, name]
  )

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) handleAddItem(event)
  }

  const handleKeyPressForUpdate = (event) => {
    if (event.keyCode === 13) handleUpdateItem(event)
  }

  const handleNewClick = () => {
    setShowNewInput(true)
  }

  const handleAddItem = useCallback(
    (event) => {
      if (event.target.value === '') {
        return
      }

      let newItems = [...items]

      // determine current highest id with an array reducer function
      const maxId = (currentMax, current) => (current.id > currentMax.id ? current : currentMax)

      newItems.push({ id: newItems.reduce(maxId, { id: 0 }).id + 1, text: event.target.value })

      changeHandler(newItems)

      event.target.value = ''
    },
    [items, changeHandler]
  )

  const handleUpdateItem = (event) => {
    let newItems = [...items]
    const index = newItems.findIndex((item) => item.id === parseInt(event.target.id))

    newItems[index].edit = false
    newItems[index].text = event.target.value
    newItems = newItems.map((item) => {
      return { id: item.id, text: item.text }
    })

    changeHandler(newItems)
  }

  const handleItemDeleteClick = (itemId) => {
    let newItems = [...items.filter((item) => item.id !== itemId)]
    newItems = newItems.map((item) => {
      return { id: item.id, text: item.text }
    })
    changeHandler(newItems)
  }

  const handleItemEditClick = (itemId) => {
    const newItems = [...items]

    const index = newItems.findIndex((item) => item.id === itemId)
    items[index].edit = true

    changeHandler(newItems)
  }

  const handleItemUpClick = (itemId) => {
    let newItems = [...items]
    const index = newItems.findIndex((item) => item.id === itemId)
    const elem = newItems.splice(index, 1)[0]
    newItems.splice(index - 1, 0, elem)

    changeHandler(newItems)
  }

  const handleItemDownClick = (itemId) => {
    let newItems = [...items]
    const index = newItems.findIndex((item) => item.id === itemId)
    const elem = newItems.splice(index, 1)[0]
    newItems.splice(index + 1, 0, elem)

    changeHandler(newItems)
  }

  const renderSingleItem = (item) => {
    const className = 'list-group-item p-2 pl-3 text-break' + (item.isSelected ? ' active' : '')
    const itemInput = (
      <input
        autoFocus={true}
        type="text"
        id={item.id}
        className="form-input"
        name="newAnswer"
        defaultValue={item.text}
        onBlur={handleUpdateItem}
        onKeyDown={handleKeyPressForUpdate}
      ></input>
    )

    return (
      <li className={className} key={item.id} id={item.id}>
        {item.edit ? itemInput : item.text}

        <div className="btn-group btn-group-sm float-right" role="group" aria-label="tool buttons">
          <button
            type="button"
            className="btn btn-outline-secondary text-primary"
            onClick={() => handleItemUpClick(item.id)}
          >
            &uarr;
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary text-primary"
            onClick={() => handleItemDownClick(item.id)}
          >
            &darr;
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary text-primary"
            onClick={() => handleItemEditClick(item.id)}
          >
            &#x270E;
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary text-danger"
            onClick={() => handleItemDeleteClick(item.id)}
          >
            &#x2718;
          </button>
        </div>
      </li>
    )
  }

  return (
    <div className="form-group row">
      <label className="col-sm-2 form-group-label text-right">{label}</label>
      <div className="col-sm-10">
        <div className="border rounded p-3 bg-light">
          <ul className="list-group" style={{ maxHeight: 'calc(30vh)', overflowY: 'auto' }}>
            {items.map((item) => renderSingleItem(item))}
          </ul>

          {showNewInput ? (
            <input
              type="text"
              autoFocus={true}
              className="form-control mt-2 w-100"
              name="newAnswer"
              onBlur={handleAddItem}
              onKeyDown={handleKeyPress}
            ></input>
          ) : (
            ''
          )}

          <button
            type="button"
            className="btn-sm btn-outline-secondary mt-3 text-primary"
            onClick={handleNewClick}
          >
            <b>+</b>
          </button>
        </div>
      </div>
    </div>
  )
}
