import React from 'react'

export class PageIndicator extends React.Component {
  constructor(props) {
    super(props)

    this.theSvg = React.createRef()
  }

  renderSingleItem = (item) => {
    return (
      <span
        key={item.id}
        id={item.id}
        onClick={this.handleItemClick}
        className={(item.isSelected ? 'bg-primary' : 'bg-secondary') + ' indicatorItem'}
      ></span>
    )
  }

  render() {
    const pages = new Array(this.props.pageCount - 1)
    pages.fill(0)

    return (
      <div>
        <div className="desktopPageIndicator">
          {/* <hr style={{position: 'absolute', width: '100%', backgroundColor: '#bbb'}}></hr> */}
          {pages.length <= 10 ? (
            pages.map((page, index) => {
              return this.renderSingleItem({
                id: index,
                isSelected: index === this.props.pageNo,
                isFirst: index === 0,
                isLast: index === pages.length - 1,
              })
            })
          ) : (
            <div className="pagination">{this.props.pageNo + 1 + '/' + pages.length}</div>
          )}
        </div>
        <div className="mobilePageIndicator">{this.props.pageNo + 1 + '/' + pages.length}</div>
      </div>
    )
  }

  // render () {
  //   const pages = new Array(this.props.pageCount)
  //   pages.fill(0)

  //   return (
  //     <div>
  //       {
  //         pages.map((page, index) => {
  //           return (
  //             <div key={index} className="form-check form-check-inline">
  //               <input className="form-check-input" type="radio" readOnly checked={index === this.props.pageNo}></input>
  //             </div>
  //           )
  //         })
  //       }
  //     </div>
  //   )
  // }
}
