import { Container } from '../../../components/Container'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useUserDataStore } from '../../../stores/userStore'
import Logo from '../../../assets/img/riwis_logo_color.svg'
import { useCallback, useEffect, useMemo } from 'react'

export const RiwisRegistrationIntro = ({ setHideRiwisIntro }) => {
  const [{ user, listAsPartner }, { setShowLoginModal, setListAsPartner }] = useUserDataStore()
  const location = useLocation()
  const history = useHistory()

  const cookieStorage = useMemo(() => {
    return {
      getItem: (key) => {
        const cookies = document.cookie
          .split(';')
          .map((cookie) => cookie.split('='))
          .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {})

        return cookies[key]
      },
      setItem: (key, value, expiresYears) => {
        const maxAge = 60 * 60 * 24 * 365 * expiresYears // 1 year
        document.cookie = `${key}=${value};max-age=${maxAge}`
      },
    }
  }, [])

  const handleGoToSurvey = useCallback(() => {
    cookieStorage.setItem('hideRiwisIntro', true, 1)
    if (setHideRiwisIntro) {
      setHideRiwisIntro(true)
    }
    if (location.pathname === '/registration/riwis/intro') {
      history.push('/survey/riwis-marktdatenerhebung-2021')
    }
  }, [location, history, cookieStorage, setHideRiwisIntro])

  useEffect(() => {
    // set hideRiwisIntro if user sees the page from the 'not logged in route'
    if (
      location.pathname.includes('/survey/riwis-marktdatenerhebung-2021') &&
      location.pathname.includes('login') &&
      !cookieStorage.getItem('hideRiwisIntro')
    ) {
      cookieStorage.setItem('hideRiwisIntro', true, 1)
    }
  }, [location, cookieStorage])

  return (
    <Container>
      <div className="intro riwis">
        {/* <h2 className="display-5 text-primary">RIWIS</h2> */}
        <img width="125" src={Logo} alt="Riwis Logo" />
        <h1 className="display-4">Marktdaten&shy;befragung</h1>
        <section>
          <h3 className="text-secondary mb-4">Worum geht es in der Umfrage?</h3>
          <p className="lead">
            Als Marktforschungshaus analysieren wir seit über 30 Jahren Immobilienmärkte und beraten unsere
            Kunden und Partner umfassend und interdisziplinär. Natürlich spielen im Umfeld qualitativer und
            quantitativer Analysen auch Meinungen und Einstellungen von Akteuren und Zielgruppen eine wichtige
            Rolle. Umfragen erweitern den Research-Werkzeugkasten erheblich und ermöglichen neue, zusätzliche
            Insights, die sich durch reine Marktbeobachtung nicht erschließen lassen.
          </p>
        </section>
        <section>
          <h3 className="text-secondary mb-4">Warum sollten Sie an der Umfrage teilnehmen?</h3>
          <ul className="lead">
            <li>
              Als Teilnehmer*in erhalten Sie einen kostenfreien Zugang zu RIWIS für all jene Städte, für die
              Sie einen Fragebogen ausgefüllt haben. Die Freischaltung gilt bis September 2022.
            </li>
            <li>
              Auf Wunsch nennen wir Sie neben weiteren Datenpartnern auf unserer Website{' '}
              <Link to={{ pathname: 'https://www.riwis.de' }} target="_blank">
                www.riwis.de
              </Link>{' '}
              als Marktexpert*in.
            </li>
            <li>
              Sie tragen wesentlich dazu bei, die Markttransparenz am deutschen Immobilienmarkt zu erhöhen.
            </li>
            <li>
              Wir spenden für jeden Befragungsteilnehmer*in 10 Euro an die Organisation{' '}
              <Link to={{ pathname: 'https://www.savethechildren.de' }} target="_blank">
                Save the Children
              </Link>
              .
            </li>
          </ul>
          <div style={{ fontSize: '0.75rem', fontStyle: 'italic', lineHeight: '190%' }}>
            **Die Teilnahme ist auf max. 5 Städte begrenzt. Mehrfachanmeldungen können nicht gewertet werden.
            Nach erfolgter Teilnahme und Abschluss der Marktdatenerhebung wird Ihnen unter Vorbehalt Zugriff
            auf den selektierten Leistungsumfang von RIWIS Online gewährt. Hierzu senden wir Ihnen am
            16.12.2021 einen Aktivierungslink und die geltenden Nutzungsbedingungen zu.
          </div>
        </section>

        <section>
          <h3 className="text-secondary mb-4">Wie funktioniert die Umfrage?</h3>
          <p className="lead">
            Sie können bis zu 5 Städte auswählen, für die Sie Ihre Einschätzungen abgeben möchten. Unser neuer
            Online-Fragebogen ist unterteilt in Fragen zu den Assetklassen Büro, Einzelhandel, Industrie und
            Wohnen. Ihre Einschätzungen können Sie mit Hilfe von Schiebereglern, Ankreuzfeldern und
            Anmerkungsfeldern mit uns teilen. Hierbei werden Ihnen die festgelegten Marktdaten des Vorjahres
            sowie bestimmte Lagekriterien als Orientierungshilfe angezeigt.
          </p>
          <ul className="lead">
            <li>Start der Befragung: Mittwoch, 06.10.2021</li>
            <li>Ende der Befragung: Montag, 15.11.2021</li>
          </ul>
        </section>

        <section>
          <h3 className="text-secondary mb-4">Sie haben Fragen?</h3>
          <p className="lead">
            Haben Sie Fragen oder Anregungen zu unserer Erhebung, senden Sie uns gerne eine Nachricht an Felix
            Embacher MRICS und Jan Finke.
          </p>
          <p className="lead">
            <Link to={{ pathname: 'mailto:riwis@bulwiengesa.de' }} target="_blank">
              riwis@bulwiengesa.de
            </Link>
            <br />
            +49 201 87 46 96 63
          </p>
        </section>

        <div className="d-flex w-100 justify-content-center">
          {!user ? (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Link to="/registration/riwis/formular">
                <button className="riwis-btn riwis-primary" style={{ fontSize: '1rem', fontWeight: '500' }}>
                  Weiter zur Registrierung
                </button>
              </Link>
              <div className="my-5" style={{ fontSize: '1rem' }}>
                <strong>ODER</strong>
              </div>
              <button
                className="riwis-btn riwis-white"
                style={{ fontSize: '1rem', fontWeight: '500' }}
                onClick={() => setShowLoginModal(true)}
              >
                Anmeldung
              </button>
            </div>
          ) : (
            <div className="d-flex flex-column">
              <div className="custom-control custom-checkbox mb-5">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  name="list_as_partner"
                  id="list_as_partner"
                  value={listAsPartner !== null ? listAsPartner : user.list_as_partner}
                  checked={listAsPartner !== null ? listAsPartner : user.list_as_partner}
                  onChange={(e) => setListAsPartner(e.target.checked)}
                ></input>
                <label
                  className="custom-control-label"
                  style={{ fontWeight: '500', fontSize: '0.875rem' }}
                  htmlFor="list_as_partner"
                >
                  Ja ich möchte als Datenpartner auf der Website{' '}
                  <Link to={{ pathname: 'https://www.riwis.de' }} target="_blank">
                    www.riwis.de
                  </Link>{' '}
                  als Marktexpert*in aufgelistet werden
                </label>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="riwis-btn riwis-primary"
                  style={{ fontSize: '1rem', fontWeight: '500' }}
                  onClick={handleGoToSurvey}
                >
                  Zur Umfrage
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}
