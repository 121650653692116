import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Container } from '../../components/Container'
import { useUserDataStore } from '../../stores/userStore'
import { RiwisRegistrationIntro } from '../Registration/Riwis/RiwisRegistrationIntro'

export const LoginInfo = () => {
  const [{ user }, { setShowLoginModal }] = useUserDataStore()
  const history = useHistory()

  const showModal = () => {
    setShowLoginModal(true)
  }

  useEffect(() => {
    if (user) {
      const prevPage = history.location.pathname.replace('/login', '')
      history.push(prevPage)
    }
  }, [user, history])

  return history.location.pathname.includes('/survey/riwis-marktdatenerhebung-2021') ? (
    <RiwisRegistrationIntro />
  ) : (
    <Container>
      <div className="d-flex flex-column align-items-center">
        <h4>Sie müssen angemeldet sein um an dieser Umfrage teilnehmen zu können.</h4>
        <Button onClick={showModal}>Anmelden</Button>
      </div>
    </Container>
  )
}
