import React from 'react'

export class CheckboxMatrix extends React.Component {
  constructor(props) {
    super(props)

    // this.props.answers = props.answers || {}

    this.state = { givenAnswer: props.value || {}, selectedQuestion: null }
  }

  handleChange = (event) => {
    // target name is of form
    // <id of overall question>_<id within questions field>
    //
    const questionName = event.target.name.split('_')[0]
    const [question, answer] = event.target.value.split('_')
    const givenAnswer = this.state.givenAnswer

    if (!givenAnswer[question]) givenAnswer[question] = []

    // eslint-disable-next-line
    if (event.target.checked && !givenAnswer[question].some((item) => item == answer)) {
      givenAnswer[question].push(answer)
    } else if (!event.target.checked) {
      // eslint-disable-next-line
      givenAnswer[question] = givenAnswer[question].filter((item) => item != answer)
    }

    this.setState({ givenAnswer: givenAnswer })

    this.props.onChange({ question_id: questionName, value: givenAnswer, qid: this.props.qid })
  }

  close = () => {
    this.setSelectedQuestion(null)
  }

  setSelectedQuestion = (questionId) => {
    this.setState({ selectedQuestion: questionId })
  }

  renderMobileDropdownBtn = (question) => {
    const answers = this.props.answers.filter(
      (answer) =>
        this.props.value[question.id] &&
        this.props.value[question.id].find((givenAnswers) => parseInt(givenAnswers) === answer.id)
    )
    return (
      <div className="btn-group">
        <button
          type="button"
          className="btn mainBtn"
          key={question.id}
          onClick={() => this.setSelectedQuestion(question.id)}
        >
          {answers.length
            ? answers.map((answer, index) => (
                <span key={answer.id}>
                  {answer.text}
                  {index < answers.length - 1 && ', '}
                </span>
              ))
            : 'Antwort auswählen'}
        </button>
        <button
          type="button"
          className="btn btn-primary dropdown-toggle dropdown-toggle-split"
          onClick={() => this.setSelectedQuestion(question.id)}
        ></button>
      </div>
    )
  }

  render() {
    const tableHeader = (
      <thead>
        <tr>
          <th scope="col"></th>
          {this.props.answers.map((answer) => {
            return <th key={answer.id} dangerouslySetInnerHTML={{ __html: answer.text }}></th>
          })}
        </tr>
      </thead>
    )

    const tableBody = (
      <tbody>
        {this.props.questions.map((question) => {
          return (
            <tr style={{ verticalAlign: 'baseline' }} key={question.id}>
              <th key={question.id} scope="row" dangerouslySetInnerHTML={{ __html: question.text }}></th>

              <td className="matrixDropdown">{this.renderMobileDropdownBtn(question)}</td>

              {this.props.answers.map((answer) => {
                const elementName = this.props.id + '_' + question.id
                const elementId = this.props.id + '_' + question.id + '_' + answer.id
                const elementValue = question.id + '_' + answer.id

                const checked =
                  (this.state.givenAnswer[question.id] &&
                    this.state.givenAnswer[question.id].includes(answer.id.toString())) ||
                  false

                return (
                  <td key={answer.id}>
                    {
                      <div className="custom-control custom-checkbox" key={elementId}>
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          name={elementName}
                          id={elementId}
                          value={elementValue}
                          onChange={this.handleChange}
                          checked={checked}
                        ></input>
                        <label className="custom-control-label" htmlFor={elementId}></label>
                      </div>
                    }
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    )

    return (
      <div className="d-flex">
        {this.state.selectedQuestion !== null && (
          <MobileAnswersCheckbox
            id={this.props.id}
            answers={this.props.answers}
            questions={this.props.questions}
            selectedQuestion={this.state.selectedQuestion}
            givenAnswers={this.state.givenAnswer}
            close={this.close}
            changeHandler={this.handleChange}
          />
        )}
        <div onScroll={this.onScroll} className="matrixTableContainer">
          <table className="matrixTable">
            {tableHeader}
            {tableBody}
          </table>
        </div>
      </div>
    )
  }
}

class MobileAnswersCheckbox extends React.Component {
  constructor(props) {
    super(props)
    this.question = this.props.questions.find((question) => question.id === this.props.selectedQuestion)
  }

  selectedActive = () => {
    return (
      <div className="selectedRing d-flex justify-content-center align-items-center">
        <div className="selectedDot"></div>
      </div>
    )
  }

  selectedInactive = () => {
    return <div className="selectedRing"></div>
  }

  render() {
    return (
      <div
        className="mobileAnswerWindow"
        onClick={(e) => {
          e.target.className === 'mobileAnswerWindow' && this.props.close()
        }}
      >
        <div className="mobileAnswerContainer">
          <ul className="mobileAnswerList">
            <li className="closeBtn d-flex justify-content-end">
              <div className="crossContainer" onClick={this.props.close}>
                <div className="crossBarOne">
                  <div className="crossBarTwo"></div>
                </div>
              </div>
            </li>
            {this.props.answers.map((answer) => {
              const elementName = this.props.id + '_' + this.question.id + '_m'
              const elementValue = this.question.id + '_' + answer.id
              const elementId = this.props.id + '_' + this.question.id + '_' + answer.id + '_m'
              const checked =
                (this.props.givenAnswers[this.question.id] &&
                  this.props.givenAnswers[this.question.id].includes(answer.id.toString())) ||
                false
              return (
                <li key={answer.id} value={elementValue} className="d-flex align-items-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      onChange={this.props.changeHandler}
                      className="custom-control-input"
                      type="checkbox"
                      name={elementName}
                      id={elementId}
                      value={elementValue}
                      checked={checked}
                    />
                    <label className="custom-control-label" htmlFor={elementId}>
                      {answer.text}
                    </label>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}
