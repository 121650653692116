import React from 'react'

export class Slider extends React.Component {
  constructor(props) {
    super(props)

    console.log('Slider::constructor: props', this.props)

    this.state = {
      value: props.value || props.options.default_value,
      inputValue: props.value || props.options.default_value,
    }

    /*  this is needed to have an answer,
     *  even if no input has been performed
     */
    if (!props.value && props.options.default_value) {
      this.props.onChange({ question_id: this.props.id, value: props.options.default_value })
    }
  }

  handleChange = (event) => {
    let value = Number(event.target.value)
    if (value < this.props.options.min_value) value = this.props.options.min_value
    if (value > this.props.options.max_value) value = this.props.options.max_value
    this.setState({ value: event.target.value, inputValue: value })
    this.props.onChange({ question_id: this.props.id, value, qid: this.props.qid })
  }

  handleNumberInput = (event) => {
    const pattern = this.props.options.with_decimal ? /^\d+,{0,1}\d{0,1}$/ : /^\d+$/

    if (event.target.value !== '' && event.target.value.match(pattern) === null) {
      return
    }

    this.setState({ value: event.target.value, inputValue: event.target.value })

    event.target.value = Number(event.target.value.replace(',', '.'))
    this.handleChange(event)
  }

  displayNumberInputValue = (value) => {
    return String(value).replace('.', ',')
  }

  render() {
    const inputForSlider = (
      <input
        className="form-control mr-4 numberInput"
        type="text"
        name="sliderValue"
        size="3"
        min={this.props.options.min_value}
        max={this.props.options.max_value}
        step={this.props.options.step}
        value={this.displayNumberInputValue(this.state.inputValue)}
        onChange={this.handleNumberInput}
        style={{ width: '3rem' }}
        aria-describedby="valueUnit"
      ></input>
    )

    const withUnit = (
      <div className="input-group mr-4" style={{ width: '10rem' }}>
        <div className="input-group-prepend">
          <span className="input-group-text" id="valueUnit">
            {this.props.options.unit}
          </span>
        </div>
        {inputForSlider}
      </div>
    )

    return (
      <div className="d-flex align-items-center">
        {this.props.options.unit ? withUnit : inputForSlider}
        <h5>
          <span className="badge badge-primary">
            {Number(this.props.options.min_value).toLocaleString() + ' ' + (this.props.options.unit || '')}
          </span>
        </h5>
        <input
          className="custom-range"
          type="range"
          name={this.props.id}
          min={this.props.options.min_value}
          max={this.props.options.max_value}
          value={this.state.value}
          step={this.props.options.step}
          onChange={this.handleChange}
          style={{ width: '33%' }}
        ></input>
        <h5>
          <span className="badge badge-primary">
            {Number(this.props.options.max_value).toLocaleString() + ' ' + (this.props.options.unit || '')}
          </span>
        </h5>
      </div>
    )
  }
}
