import { useCallback, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { useUserDataStore } from '../stores/userStore'
import { useSurveyDataStore } from '../pages/Survey/dataStore'
import { LoginBox } from './NavigationContent/LoginBox'
import { ResendVerification } from './NavigationContent/ResendVerification'
import { ForgotPassword } from './NavigationContent/ForgotPassword'
import Logo from '../assets/img/riwis_logo_color.svg'

export const Navigation = () => {
  const [
    { user, redirectTo, showLoginModal },
    { logoutUser, setLoginMessage, setRequestResetPasswordMessage, setShowLoginModal },
  ] = useUserDataStore()
  const [{ survey, questionnaire }] = useSurveyDataStore()
  const [show, setShow] = useState(false)
  const [riwisMode, setRiwisMode] = useState(false)

  const [resendTo, setResendTo] = useState(null)
  const [forgotPassword, setForgotPassword] = useState(null)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const history = useHistory()
  const location = useLocation()

  const handleLogout = useCallback(() => {
    logoutUser()
  }, [logoutUser])

  const NavbarNotLoggedIn = useCallback(() => {
    return (
      <nav
        className={
          (riwisMode ? 'bg-riwis' : 'bg-primary') +
          ' navbar navbar-dark navbar-expand-md p-0 justify-content-between'
        }
      >
        <button
          onClick={handleShow}
          className="btn btn-outline-light py-3 ml-3"
          data-toggle="modal"
          data-target="#loginModal"
        >
          Login
        </button>

        <NavbarSlope
          backgroundClass={riwisMode ? 'riwis-slope' : ''}
          logo={
            survey !== null && survey?.logo && location.pathname.includes('/survey/')
              ? survey.logo
              : riwisMode
              ? Logo
              : ''
          }
        />
      </nav>
    )
  }, [riwisMode, survey, location])

  const NavbarLoggedIn = useCallback(() => {
    return (
      <nav className={(riwisMode ? 'bg-riwis' : 'bg-primary') + ' navbar navbar-dark navbar-expand-md p-0'}>
        <button className="navbar-toggler ml-3" type="button" data-toggle="collapse" data-target=".dual-nav">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse dual-nav order-1 order-md-0">
          <ul className="navbar-nav align-items-center">
            <li className="nav-item dropdown">
              <div
                id="userDropdown"
                href="#"
                className="nav-link dropdown-toggle btn text-white"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {user.username}
              </div>
              <div className="dropdown-menu" aria-labelledby="userDropdown" style={{ zIndex: 1100 }}>
                <Link className="nav-link" to={`/account/${user.id}`} style={{ padding: 0 }}>
                  <button className="dropdown-item">Konto</button>
                </Link>
                <button onClick={handleLogout} href="" className="dropdown-item">
                  Logout
                </button>
              </div>
            </li>
          </ul>
          {user.isAdmin === true && (
            <ul className="navbar-nav align-items-center ml-5">
              <li className="nav-item">
                <Link className="nav-link" to="/admin/surveys/all">
                  Umfragen
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/reporting">
                  Auswertungen
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/token">
                  Token
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/usergroups">
                  User-Gruppen
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/users">
                  User-Liste
                </Link>
              </li>
            </ul>
          )}
        </div>
        <NavbarSlope
          questionnaire={questionnaire}
          backgroundClass={riwisMode ? 'riwis-slope' : ''}
          logo={
            survey !== null && survey?.logo && location.pathname.includes('/survey/')
              ? survey.logo
              : riwisMode
              ? Logo
              : ''
          }
        />
      </nav>
    )
  }, [riwisMode, survey, user, handleLogout, questionnaire, location])

  const NavbarSlope = useCallback(({ backgroundClass, logo, questionnaire }) => {
    return (
      <div className="navbar-brand order-0 order-md-2 p-0 m-0">
        <div className="brandWrapper order-sm-last">
          <div className={`${backgroundClass} slope`}>
            <div className="logoWrapper">
              {questionnaire ? (
                logo ? (
                  <img className="p-2 logo" src={logo} alt="logo" width="100%" />
                ) : (
                  'SurveyDesk'
                )
              ) : (
                <Link to="/">
                  {logo ? <img className="p-2 logo" src={logo} alt="logo" width="100%" /> : 'SurveyDesk'}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }, [])

  useEffect(() => {
    if (showLoginModal) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [showLoginModal])

  useEffect(() => {
    if (!show) {
      setLoginMessage({ msg: '', type: '', err: '' })
      setRequestResetPasswordMessage({ msg: '', type: '', err: '' })
      setResendTo(null)
      setForgotPassword(null)
      setShowLoginModal(false)
    }
  }, [show, setLoginMessage, setRequestResetPasswordMessage, setShowLoginModal])

  useEffect(() => {
    if (!user && redirectTo) {
      history.push(redirectTo)
    }
  }, [user, redirectTo, history])

  useEffect(() => {
    if ((location && location?.pathname.includes('riwis')) || (survey && survey.type === 'riwis')) {
      setRiwisMode(true)
    } else {
      setRiwisMode(false)
    }
  }, [location, survey])

  return (
    <>
      {!user?.id ? <NavbarNotLoggedIn /> : <NavbarLoggedIn />}

      {/* Login Modal */}
      <Modal
        contentClassName="loginWindow"
        style={{ fontFamily: riwisMode && 'Maven Pro' }}
        show={show}
        onHide={handleClose}
      >
        <div className={(riwisMode ? 'riwis' : '') + ' d-flex justify-content-start align-items-center pt-5'}>
          <div className="loginBarOne"></div>
          <h2 className="text-primary text-nowrap mx-3">
            {riwisMode ? <img width="100" height="100%" src={Logo} alt="logo" /> : 'Survey Desk'}
          </h2>
          <div className="loginBarTwo"></div>
        </div>
        <Modal.Body className="loginBody">
          {!resendTo ? (
            !forgotPassword ? (
              <LoginBox
                handleResendVerificationMail={setResendTo}
                handleForgotPassword={setForgotPassword}
                handleClose={handleClose}
                riwisMode={riwisMode}
              />
            ) : (
              <ForgotPassword handleClose={handleClose} riwisMode={riwisMode} />
            )
          ) : (
            <ResendVerification handleClose={handleClose} email={resendTo} riwisMode={riwisMode} />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
