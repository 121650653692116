import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLandingpageDataStore } from './dataStore'
import { Container } from '../../components/Container'
import { useUserDataStore } from '../../stores/userStore'

const SurveyCard = (survey) => {
  const from = new Date(survey.from).toLocaleDateString()
  const to = new Date(survey.to).toLocaleDateString()
  return (
    <div key={survey.id} className="surveyCard">
      <div className="surveyCardDate mb-2">
        {from}&nbsp;&mdash;&nbsp; {to}
      </div>
      <Link className="surveyCardHeader text-primary" to={`/survey/${survey.slug}`}>
        {survey.title}
      </Link>
      <h6 className="surveyCardBody my-4">{survey.subtitle}</h6>
    </div>
  )
}

export const Landingpage = () => {
  const [{ user }] = useUserDataStore()
  const [{ surveys }, { fetchSurveys }] = useLandingpageDataStore()

  useEffect(() => {
    if (user) {
      fetchSurveys()
    }
  }, [fetchSurveys, user])

  return (
    <Container>
      <div className="intro">
        <h1 className="display-4 text-primary">Survey Desk</h1>
        <h3 className="text-secondary">
          Das Umfrage-Werkzeug zur Messung von Marktforschung, Kundenzufriedenheit und all dem, wozu ein Panel
          hilfreich ist.
        </h3>
        <div className="my-5">
          <p className="lead">
            Als Marktforschungshaus analysieren wir seit über 30 Jahren Immobilienmärkte und beraten unsere
            Kunden und Partner umfassend und interdisziplinär. Natürlich spielen im Umfeld qualitativer und
            quantitativer Analysen auch Meinungen und Einstellungen von Akteuren und Zielgruppen eine wichtige
            Rolle. Umfragen erweitern den Research-Werkzeugkasten erheblich und ermöglichen neue, zusätzliche
            Insights, die sich durch reine Marktbeobachtung nicht erschließen lassen.
          </p>
          <p className="lead">
            Geht es also um Hintergründe, Einschätzungen und persönliche Meinungen und Einstellungen sind
            qualitative Befragungen und Umfragen das Mittel der Wahl. Hier unterstützt SurveyDesk - unsere
            Applikation für Online-Befragungen. Was möchten Sie herausfinden?
          </p>
          <p className="h5 text-muted">
            Sprechen Sie uns gerne an:
            {user?.supportContact ? (
              <a href={`mailto:${user.supportContact.email}`}> {user.supportContact.email}</a>
            ) : (
              <a href="mailto:survey-desk@bulwiengesa.de"> survey-desk@bulwiengesa.de</a>
            )}
          </p>
        </div>
        <div className="py-3 overflow-hidden">
          {user && surveys && (
            <div>
              <h2 className="text-secondary">
                {surveys.length > 0
                  ? 'Laufende Befragungen'
                  : 'Zur Zeit haben wir keine laufenden Befragungen.'}
              </h2>
              <div className="my-4">
                <div className="surveyCardContainer">{surveys.map((survey) => SurveyCard(survey))}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}
