import { Button } from 'react-bootstrap'
import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Container } from '../../components/Container'
import { useRegistrationDataStore } from './dataStore'
import { useUserDataStore } from '../../stores/userStore'
import { Redirect } from 'react-router-dom'

export const EmailVerification = () => {
  const [{ registrationMessage, registeredUser, redirectTo }, { verifyUser }] = useRegistrationDataStore()
  const [{ user }, { setShowLoginModal, setUser }] = useUserDataStore()
  const { token } = useParams()

  const showLogin = useCallback(() => {
    setShowLoginModal(true)
  }, [setShowLoginModal])

  useEffect(() => {
    if (token) {
      verifyUser(token)
    }
  }, [token, verifyUser])

  useEffect(() => {
    if (registeredUser) {
      setUser(registeredUser)
    }
  }, [registeredUser, setUser])

  return user && redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    <Container>
      {registrationMessage.type === 'success' ? (
        <div className="registrationFeedback">
          <div className="d-flex flex-column justify-content-center align-items-center flex-wrap">
            <i className="fas fa-check" style={{ fontSize: '5rem' }}></i>
            <h3 className="text-center px-5 mt-3 mt-sm-0">Ihre Konto wurde aktiviert!</h3>
            <div className="mt-5 text-center d-flex flex-column">
              <div>
                Vielen Dank für die Registrierung bei Survey-Desk. Sie können sich nun mit ihren Zugangsdaten
                anmelden.
              </div>
              <div className="mt-5">
                <Button onClick={showLogin}>Anmelden</Button>
              </div>
            </div>
          </div>
        </div>
      ) : registrationMessage.type === 'failed' ? (
        registrationMessage.msg === 'token error' ? (
          <div className="registrationFeedback">
            <div className="d-flex flex-column justify-content-center align-items-center flex-wrap">
              <i className="fas fa-times" style={{ fontSize: '5rem' }}></i>
              <h3 className="text-center px-5 mt-3 mt-sm-0">Automatischer Login fehlgeschlagen!</h3>
            </div>
            <div className="mt-5 text-center">
              Leider konnten Sie nicht automatisch angemeldet werden. Bitte melden Sie sich mit ihren
              Anmelde-Daten an.
            </div>
          </div>
        ) : (
          <div className="registrationFeedback">
            <div className="d-flex justify-content-center align-items-center flex-wrap">
              <i className="fas fa-times" style={{ fontSize: '5rem' }}></i>
              <h3 className="text-center px-5 mt-3 mt-sm-0">Verifizierung fehlgeschlagen!</h3>
            </div>
            <div className="mt-5 text-center">
              Leider konnte Ihre Registrierung nicht abgeschlossen werden. Bitte versuchen Sie es erneut oder
              wenden Sie sich an unseren <a href="mailto:survey-desk@bulwiengesa.de">Survey-Desk Support</a>.
            </div>
          </div>
        )
      ) : (
        <div className="registrationFeedback">
          <div className="d-flex justify-content-center align-items-center flex-wrap">
            <h3 className="text-center px-5 mt-3 mt-sm-0">Ihr Konto wird aktiviert...</h3>
          </div>
        </div>
      )}
    </Container>
  )
}
