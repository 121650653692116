import React, { useState, useCallback } from 'react'
import { Radio } from './QuestionTypes/Radio'
import { RadioMatrix } from './QuestionTypes/RadioMatrix'
import { RadioInline } from './QuestionTypes/RadioInline'
import { Checkbox } from './QuestionTypes/Checkbox'
import { CheckboxMatrix } from './QuestionTypes/checkboxMatrix'
import { Slider } from './QuestionTypes/Slider'
import { Text } from './QuestionTypes/Text'
import { Textarea } from './QuestionTypes/Textarea'
import { Sum } from './QuestionTypes/Sum'
import { RiwisSlider } from './QuestionTypes/RiwisSlider'
import { InputColumns } from './QuestionTypes/InputColumns'
import InfoIcon from '../../assets/icons/Chat-Information.svg'
import { Modal } from '../../components/Modal'
import { RiwisRadioMatrix } from './QuestionTypes/RiwisRadioMatrix'
import { RiwisRadioInline } from './QuestionTypes/RiwisRadioInline'

export const QuestionBuilder = ({
  question,
  lastQuestionNo,
  showStar,
  missingInputs,
  handleAnswerChange,
  pageNumber,
}) => {
  const [showDefinitionModal, setShowDefinitionModal] = useState(false)
  const questionRenderer = useCallback(
    (question) => {
      switch (question.type) {
        case 'radio':
          return (
            <Radio
              id={question.id}
              qid={question.qid}
              key={question.id}
              label={label}
              answers={question.possible_answers}
              value={value}
              onChange={handleAnswerChange}
            />
          )
        case 'radioInline':
          return (
            <RadioInline
              id={question.id}
              qid={question.qid}
              key={question.id}
              label={label}
              answers={question.possible_answers}
              value={value}
              onChange={handleAnswerChange}
            />
          )
        case 'radioMatrix':
          return (
            <RadioMatrix
              id={question.id}
              qid={question.qid}
              key={question.id}
              label={label}
              answers={question.possible_answers}
              questions={question.questions_field}
              oneAnswer={question.one_answer}
              value={value}
              onChange={handleAnswerChange}
            />
          )
        case 'checkbox':
          return (
            <Checkbox
              id={question.id}
              qid={question.qid}
              key={question.id}
              label={label}
              answers={question.possible_answers}
              value={value}
              onChange={handleAnswerChange}
            />
          )
        case 'checkboxMatrix':
          return (
            <CheckboxMatrix
              id={question.id}
              qid={question.qid}
              key={question.id}
              label={label}
              answers={question.possible_answers}
              questions={question.questions_field}
              value={value}
              onChange={handleAnswerChange}
            />
          )
        case 'slider':
          return (
            <Slider
              id={question.id}
              qid={question.qid}
              key={question.id}
              label={label}
              options={question}
              value={value}
              onChange={handleAnswerChange}
            />
          )
        case 'text':
          return (
            <Text
              id={question.id}
              qid={question.qid}
              key={question.id}
              label={label}
              value={value}
              onChange={handleAnswerChange}
            />
          )
        case 'textarea':
          return (
            <Textarea
              id={question.id}
              qid={question.qid}
              key={question.id}
              label={label}
              value={value}
              limit={question.limit || null}
              onChange={handleAnswerChange}
            ></Textarea>
          )
        case 'sum':
          return (
            <Sum
              id={question.id}
              qid={question.qid}
              key={question.id}
              label={label}
              answers={question.possible_answers}
              value={value}
              maxValue={question.max_value}
              unit={question.unit}
              optOut={question.opt_out}
              onChange={handleAnswerChange}
            ></Sum>
          )
        case 'sliderRiwis':
          return (
            <RiwisSlider
              question={question}
              key={question.id}
              value={value}
              onChange={handleAnswerChange}
              pageNumber={pageNumber}
            />
            // <div>riwis</div>
          )
        case 'radioMatrixRiwis':
          return (
            <RiwisRadioMatrix
              id={question.id}
              qid={question.qid}
              key={question.id}
              label={label}
              answers={question.possible_answers}
              riwisDatasources={question.riwis_datasources}
              answerHeader={question.matrix_answer_header}
              questionHeader={question.matrix_question_header}
              questions={question.questions_field}
              oneAnswer={question.one_answer}
              value={value}
              onChange={handleAnswerChange}
            />
          )
        case 'radioInlineRiwis':
          return (
            <RiwisRadioInline
              id={question.id}
              qid={question.qid}
              key={question.id}
              label={label}
              answers={question.possible_answers}
              value={value}
              onChange={handleAnswerChange}
              riwisDatasources={question.riwis_datasources}
            />
          )
        case 'inputColumns':
          return (
            <InputColumns
              id={question.id}
              qid={question.qid}
              key={question.id}
              dataType={question.data_type}
              answers={question.possible_answers}
              value={value}
              withDecimal={question.with_decimal}
              minValue={question.min_value}
              maxValue={question.max_value}
              unit={question.unit}
              onChange={handleAnswerChange}
            />
          )
        default:
          return <div></div>
      }
    },
    // eslint-disable-next-line
    [handleAnswerChange, pageNumber, label, value]
  )

  let value = question?.given_answer?.value?.value || ''
  const label = (question.is_optional || !showStar ? '' : '*') + question.label
  const warning = missingInputs.includes(question.id)

  return (
    <>
      <div className="mb-5">
        <div className="d-flex mb-3">
          <h4 className={(warning ? 'missingInputColor' : '') + ' questionNumber'}>{lastQuestionNo}. </h4>
          {question.is_optional || !showStar ? (
            ''
          ) : (
            <small className={(warning ? 'missingInputColor' : '') + ' mr-1 warning'}>*</small>
          )}
          <div style={{ display: 'inline-block' }}>
            <h4
              className={(warning ? 'missingInputColor' : '') + ' pageTitle'}
              dangerouslySetInnerHTML={{ __html: question.label }}
            ></h4>
            {question?.definition && (
              <span className="cursorPointer" onClick={() => setShowDefinitionModal(true)}>
                <img src={InfoIcon} width="24" height="24" alt="" />
              </span>
            )}
          </div>
        </div>
        {question.description && (
          <h6
            className={
              (question.type === 'sliderRiwis' ? 'riwisIndent' : '') +
              ' questionIndent pb-3 questionDescription'
            }
            dangerouslySetInnerHTML={{ __html: question.description }}
          ></h6>
        )}
      </div>
      <div
        className={
          (question.type !== 'radioMatrix' && question.type !== 'checkboxMatrix' && question.type !== 'sum'
            ? 'questionIndent'
            : 'matrixIndent') +
          (question.type === 'sliderRiwis' || question.type === 'radioMatrixRiwis' ? ' riwisIndent' : '') +
          ' pb-3'
        }
      >
        {questionRenderer(question)}
      </div>
      {showDefinitionModal && (
        <Modal onHide={setShowDefinitionModal} maxWidth={'50rem'} marginX={'2rem'}>
          {/* <h3 className="mb-5">Definition</h3> */}
          <div
            style={{ fontSize: '1rem', maxWidth: '50rem' }}
            dangerouslySetInnerHTML={{ __html: question.definition }}
          ></div>
        </Modal>
      )}
    </>
  )
}
