import React, { useCallback, useEffect, useState, useRef } from 'react'
import { RiwisSliderComponent } from './RiwisSliderComponent'
import { RiwisMap } from './RiwisMap'
import { useSurveyDataStore } from '../../dataStore'
import { Placeholder } from './Placeholder'

const placeholderText = `Ich finde die RIWIS Werte aus dem Vorjahr generell zu hoch/niedrig
Die von mir beobachtete Wertveränderung ergibt sich aus…
Ich sehe keine Marktveränderung, weil…
`

export const RiwisSlider = ({ question, onChange, value, pageNumber }) => {
  const [showNote, setShowNote] = useState(false)
  const textareaRef = useRef(null)
  const [{ selectedCity, datasourceYear, survey }, { fetchRiwisData }] = useSurveyDataStore()

  const handleNoteChange = useCallback(
    (event) => {
      let answer = question.given_answer.hasOwnProperty('question_id') ? { ...question.given_answer } : null
      if (answer) {
        answer.value.value.note = event.target.value
      } else {
        answer = {
          question_id: question.id,
          qid: question.qid,
          value: { note: event.target.value },
        }
      }
      onChange(answer)
    },
    [question, onChange]
  )

  useEffect(() => {
    if (!question.riwisData) {
      fetchRiwisData(
        pageNumber,
        question,
        question.riwis_datasources,
        selectedCity.gac,
        datasourceYear,
        survey.id
      )
    }
  }, [question, pageNumber, fetchRiwisData, selectedCity, datasourceYear, survey])

  useEffect(() => {
    if (showNote && textareaRef.current) {
      textareaRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }, [textareaRef, showNote])

  useEffect(() => {
    if (value.note && !showNote) {
      setShowNote(true)
    }
    // eslint-disable-next-line
  }, [])

  return question.riwisData ? (
    <div className="riwisSliderWrapper">
      {question.show_map === true && <RiwisMap question={question} />}
      {Object.entries(question.riwisData).map(([key, data]) => (
        <RiwisSliderComponent
          key={key}
          data={data}
          datasourceId={key}
          onChange={onChange}
          question={question}
          value={value}
        />
      ))}
      <div className="riwisSliderContainer">
        <span
          style={{
            color: 'rgb(0,41,64)',
            textDecoration: 'underline',
            cursor: 'pointer',
            width: 'fit-content',
            fontSize: '0.875rem',
          }}
          onClick={() => setShowNote(!showNote)}
        >
          <div className="">Anmerkung hinzufügen</div>
        </span>
        {showNote && (
          <textarea
            value={value.note || ''}
            ref={textareaRef}
            className="riwisTextarea"
            style={{ width: '100%' }}
            placeholder={placeholderText}
            onChange={handleNoteChange}
          ></textarea>
        )}
      </div>
    </div>
  ) : (
    <Placeholder showMap={question.show_map} datasources={question.riwis_datasources} />
  )
}
