import { Container } from '../../../components/Container'
import { Link } from 'react-router-dom'
import { useUserGroupsAdminDataStore } from './dataStore'
import { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { AlertBox } from '../../../components/AlertBox'

export const UserGroupList = () => {
  const [
    { dataIsLoading, userGroupList, userGroupMessage },
    { fetchUserGroupList, deleteUserGroup, setUserGroupMessage },
  ] = useUserGroupsAdminDataStore()

  useEffect(() => {
    fetchUserGroupList()
  }, [fetchUserGroupList])

  useEffect(() => {
    if (userGroupMessage.type) {
      setTimeout(() => {
        setUserGroupMessage({ msg: '', type: '', err: '' })
      }, 5000)
    }
  }, [userGroupMessage, setUserGroupMessage])

  return (
    <Container>
      {dataIsLoading === false && userGroupList && (
        <>
          <div className="row">
            <div className="col">
              <h3>Benutzer-Gruppen</h3>
            </div>
            <div className="col text-right">
              <Link to="/admin/usergroup/0">
                <Button variant="outline-primary">Neue Benutzer-Gruppe</Button>
              </Link>
            </div>
            <AlertBox
              type={userGroupMessage.type}
              message={userGroupMessage.msg}
              error={userGroupMessage.error}
              top={'70px'}
              right={'25%'}
            />
          </div>
          <hr />
          <table className="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Label</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {userGroupList.map((userGroup) => (
                <tr key={userGroup.id}>
                  <th scope="row">{userGroup.id}</th>
                  <td>
                    <Link to={`/admin/usergroup/${userGroup.id}`}>{userGroup.label}</Link>
                  </td>
                  <td>
                    <i
                      className="fas fa-trash-alt small ml-3 trashCan cursorPointer"
                      onClick={() => deleteUserGroup(userGroup.id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </Container>
  )
}
