import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import ScrollArrow from '../../assets/img/arrow-down.svg'

export const SurveyOpener = ({ survey, handleCurrentQuestionaire }) => {
  const [scrollArrowOpacity, setScrollArrowOpacity] = useState(1)
  const [showScrollArrow, setShowScrollArrow] = useState(true)
  const questionnaireContainerRef = useRef(null)
  const scrollToArrow = useRef(null)

  const handleScroll = useCallback(() => {
    if (scrollToArrow.current) {
      const arrowTop = scrollToArrow.current.getBoundingClientRect().top
      const arrowHeight = scrollToArrow.current.getBoundingClientRect().height
      const containerTop = questionnaireContainerRef.current.getBoundingClientRect().top
      if (containerTop - arrowHeight <= arrowTop) {
        const diff = containerTop - arrowTop
        const opacity = (diff * 100) / arrowHeight / 100
        setScrollArrowOpacity(opacity)
      } else {
        setScrollArrowOpacity(1)
      }
      if (containerTop <= arrowTop) {
        if (showScrollArrow) {
          setShowScrollArrow(false)
        }
      } else {
        if (!showScrollArrow) {
          setShowScrollArrow(true)
        }
      }
    }
  }, [showScrollArrow])

  const answers = useMemo(() => {
    const arr = []
    if (survey && survey.questionnaires)
      survey.questionnaires.forEach((questionnaire) => {
        if (questionnaire.pages)
          questionnaire.pages.forEach((page) => {
            if (page.questions)
              page.questions.forEach((question) => {
                arr.push(question.given_answer || { question_id: question.id, value: { value: '' } })
              })
          })
      })
    return arr
  }, [survey])

  const checkConstraints = useCallback(
    (constraints) => {
      if (!constraints) return true

      const constraint = constraints // shall be an array in future
      const foundAnswer = answers.find((answer) => answer.qid === constraint.when)

      const contains = (container, value) => {
        // eslint-disable-next-line
        return Array.isArray(container) ? container.some((item) => item == value) : container == value
      }

      if (foundAnswer) {
        return (
          foundAnswer.value && foundAnswer.value.value && contains(foundAnswer.value.value, constraint.has)
        )
      } else {
        return false
      }
    },
    [answers]
  )

  const renderSingleQuestionnaire = useCallback(
    (questionnaire, index) => {
      const numPages = questionnaire.pages ? questionnaire.pages.length : 0
      // eslint-disable-next-line
      const numPagesText = numPages + (numPages === 0 || numPages >= 2 ? ' Seiten' : ' Seite')

      return (
        <div className="questionnaireWidget" key={questionnaire.id}>
          <div className="d-flex flex-column justify-content-between flex-1">
            <div>
              <div className="questionnaireWidgetMeta">
                <div>Fragebogen {'#' + (index + 1)}</div>
                {/* <div>{numPagesText}</div> */}
              </div>
              <h4 className="text-primary mb-4">{questionnaire.title}</h4>
              <div
                className="questionnaireWidgetBody"
                dangerouslySetInnerHTML={{ __html: questionnaire.subtitle }}
              ></div>
            </div>
            <button
              onClick={() => handleCurrentQuestionaire(questionnaire.id)}
              className="btn btn-light btn-white"
            >
              BEANTWORTEN
            </button>
          </div>
        </div>
      )
    },
    [handleCurrentQuestionaire]
  )

  const RenderCustomIntro = () => {
    let { backgroundImageStyle, introductionStyle, header } = survey.custom_intro_styles
    if (Object.keys(backgroundImageStyle).length) {
      backgroundImageStyle.backgroundImage = `url(${survey.background_image})`
    }
    // background_image_styles
    const defaultBackgroundImageStyle = {
      backgroundImage: `url(${survey.background_image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'bottom',
    }

    const defaultIntroductionStyle = {
      background: 'linear-gradient(60deg, #003e71 5%, rgba(0, 62, 113, 0) 100%)',
      opacity: '1',
      color: 'white',
      fontWeight: 300,
      padding: '1.5rem',
      WebkitBackdropFilter: 'blur(2px)',
      backdropFilter: 'blur(2px)',
      fontSize: '0.875rem',
    }

    return (
      <div
        style={
          Object.keys(backgroundImageStyle).length ? { ...backgroundImageStyle } : defaultBackgroundImageStyle
        }
      >
        <div style={Object.keys(introductionStyle).length ? introductionStyle : defaultIntroductionStyle}>
          <h1 style={header}>{survey.title}</h1>
          <h2>{survey.subtitle}</h2>
          {survey.description && (
            <div className="openerDescription" dangerouslySetInnerHTML={{ __html: survey.description }}></div>
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <div className={`openerWindow ${survey.type}`}>
      {survey.background_image ? (
        <RenderCustomIntro />
      ) : (
        <div className="openerIntroductionBG">
          <div className="openerIntroduction">
            <h1>{survey.title}</h1>
            <h2>{survey.subtitle}</h2>
            <div className="openerDescription" dangerouslySetInnerHTML={{ __html: survey.description }}></div>
          </div>
        </div>
      )}

      <div id="qContainer" className="questionnaireContainer" ref={questionnaireContainerRef}>
        {survey &&
          survey.questionnaires &&
          survey.questionnaires.map(
            (q, index) => checkConstraints(q.constraints) && renderSingleQuestionnaire(q, index)
          )}
      </div>
      <div
        className={(showScrollArrow ? 'visible' : 'invisible') + ' scrollToArrow'}
        style={{ opacity: scrollArrowOpacity }}
        ref={scrollToArrow}
      >
        <a href="#qContainer">
          <img width="100%" alt="scrollArrow" src={ScrollArrow}></img>
        </a>
      </div>
    </div>
  )
}
