export const checkConstraints = (constraint, page) => {
  if (!constraint) return true
  if (!constraint.when || !constraint.has) return true

  const foundAnswer = page.questions.find((question) => {
    return question.qid === constraint.when
  })

  const contains = (container, value, questionType) => {
    if (Array.isArray(container)) {
      // eslint-disable-next-line
      return container.some((item) => item == value)
    } else if (typeof container === 'object') {
      return Object.entries(container).reduce((bool, [key, answers]) => {
        if (questionType === 'radioMatrix') {
          // eslint-disable-next-line
          if (answers == value) {
            bool = true
          }
        } else if (Array.isArray(answers)) {
          // eslint-disable-next-line
          if (answers.some((item) => item == value)) {
            bool = true
          }
        } else {
          // eslint-disable-next-line
          if (key == value && answers) {
            bool = true
          }
        }
        return bool
      }, false)
    } else {
      // eslint-disable-next-line
      return container == value
    }
  }
  if (foundAnswer) {
    return (
      foundAnswer.given_answer?.value?.value &&
      contains(foundAnswer.given_answer?.value?.value, constraint.has, foundAnswer.type)
    )
  } else {
    return false
  }
}

export const getMissingInputs = (pageNo, questionnaire) => {
  if (pageNo < 0 || pageNo === null) return []
  if (questionnaire?.pages.length === pageNo) return []
  // even if no questions are defined, the questionnaire shall go further
  //
  if (!questionnaire?.pages[pageNo]?.questions) return []

  let missingInputs = questionnaire?.pages[pageNo]?.questions.filter((question) => {
    if (question.is_optional) return false
    if (!checkConstraints(question?.constraints, questionnaire?.pages[pageNo])) return false
    const currentValue = question?.given_answer?.value?.value || ''

    if (currentValue.optOut === true) return false
    if (Array.isArray(currentValue)) return currentValue.length <= 0
    if (question.type === 'radioMatrix' || question.type === 'checkboxMatrix') {
      return (
        !question?.given_answer ||
        question?.questions_field.length !== Object.keys(question?.given_answer?.value?.value).length ||
        Object.values(question?.given_answer?.value?.value).some((value) => value.length === 0)
      )
    }
    if (question.type === 'sum')
      return (
        Object.values(currentValue).reduce((accu, value) => accu + Number(value), 0) !==
        Number(question?.max_value)
      )
    if (typeof currentValue === 'object') return Object.keys(currentValue).length <= 0
    return currentValue === ''
  })

  missingInputs = missingInputs.map((question) => question.id)

  return missingInputs
}

export const getQuestionPerPageObject = (questionnaire, pageNumber, questionsPerPage) => {
  const visibleQuestions = getVisibleQuestionsCount(questionnaire, pageNumber)
  const questionObject = {
    pageNumber: pageNumber,
    questionCount: visibleQuestions,
    accumulatedQuestions:
      pageNumber === 0
        ? visibleQuestions
        : visibleQuestions + questionsPerPage[pageNumber - 1].accumulatedQuestions,
  }

  const questionPerPageItems = [...questionsPerPage]

  if (!questionsPerPage[pageNumber]) {
    questionPerPageItems.push(questionObject)
  } else {
    questionPerPageItems[pageNumber] = questionObject
  }

  return questionPerPageItems
}

const getVisibleQuestionsCount = (questionnaire, pageNumber) => {
  let questionCount = 0
  if (!questionnaire?.pages[pageNumber]?.questions) {
    return questionCount
  }
  questionnaire?.pages[pageNumber]?.questions.forEach((question) => {
    checkConstraints(question.constraints, questionnaire.pages[pageNumber]) && questionCount++
  })
  return questionCount
}

export const getAnswersFromQuestionnaire = (questionnaire) => {
  const answers = []
  questionnaire.pages.forEach((page) => {
    if (page.questions) {
      page.questions.forEach((question) => {
        if (question.given_answer && Object.values(question.given_answer).length) {
          answers.push(question.given_answer || { question_id: question.id, value: null })
        }
      })
    }
  })
  return { answers, questionCount: null, answerCount: null }
}

export const getRiwisAnswersFromQuestionnaire = (questionnaire, gac) => {
  const answers = []
  let questionCount = 0
  questionnaire.pages.forEach((page) => {
    if (page.questions) {
      page.questions.forEach((question) => {
        questionCount++
        if (question.given_answer && Object.values(question.given_answer).length) {
          question.given_answer.generic_area_code = gac
          if (question.given_answer.value.value || question.given_answer.value.value.note) {
            answers.push(question.given_answer || { question_id: question.id, value: null })
          } else {
            questionCount--
          }
        }
      })
    }
  })
  return { answers, questionCount, answerCount: answers.length }
}
