import React, { useState } from 'react'
import { generateId } from './utils'
import { ConstraintBox } from './constraintBox'
import { Page } from './page'
import { findLastNonDeleted } from './utils'
import { TinyMCE } from './TinyMCE'
import { useSurveyEditorStateStore } from './surveyEditorState'
import { TabContainer } from './TabContainer'
import { Modal } from '../../../../components/Modal'
import { Button } from 'react-bootstrap'

const flattenDeep = (array) => {
  return array.reduce((acc, val) => (Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val)), [])
}

export const Questionnaire = ({ questionnaires }) => {
  const [
    { survey, questionnaireTab },
    {
      setQuestionnaireInput,
      addQuestionnaire,
      setQuestionnaireTab,
      deleteQuestionnaire,
      changeQuestionnaireOrder,
    },
  ] = useSurveyEditorStateStore()
  const [newId, setNewId] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [questionnaireToDelete, setQuestionnaireToDelete] = useState(null)

  const handleInputChange = (index, event) => {
    setQuestionnaireInput(index, event.target.value, event.target.name)
  }

  const handleTinyMCEChange = (index, event) => {
    setQuestionnaireInput(index, event.value, event.name)
  }

  const handleNewQuestionnaireClick = () => {
    const initialProperties = {
      title: '',
      description: '',
      order: questionnaires.length + 1,
      id: newId,
      qnid: generateId(6),
      pages: [],
      asset_class: '',
    }
    setNewId(newId - 1)
    addQuestionnaire(initialProperties)
    setQuestionnaireTab(questionnaires.length)
  }

  const handleConstraintChange = (index, constraint) => {
    setQuestionnaireInput(index, constraint, 'constraints')
  }

  const handleDeleteQuestionnaireClick = () => {
    if (questionnaireTab === questionnaireToDelete) {
      findLastNonDeleted(questionnaireToDelete, questionnaires)
    } else if (questionnaireTab - 1 > 0) {
      setQuestionnaireTab(questionnaireTab - 1)
    } else {
      setQuestionnaireTab(0)
    }
    deleteQuestionnaire(questionnaireToDelete)
    hideDeleteModal()
  }

  const updateOrder = (questionnaires) => {
    changeQuestionnaireOrder(questionnaires)
  }

  const showDeleteModal = (index) => {
    setShowModal(true)
    setQuestionnaireToDelete(index)
  }

  const hideDeleteModal = () => {
    setShowModal(false)
    setQuestionnaireToDelete(null)
  }

  const renderSingleQuestionnaire = (questionnaire, index) => {
    return (
      <React.Fragment>
        <div className="mt-4">
          <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Titel</label>
            <div className="col">
              <input
                className="form-control"
                type="text"
                name="title"
                onChange={(event) => handleInputChange(index, event)}
                value={questionnaire.title || ''}
              ></input>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Untertitel</label>
            <div className="col">
              <TinyMCE
                id={`questionnaireSubtitle-${questionnaire.id}`}
                content={questionnaire.subtitle}
                name={'subtitle'}
                inlineMode={true}
                handleTinyMCEChange={(event) => handleTinyMCEChange(index, event)}
              />
            </div>
          </div>
          {survey.type === 'riwis' && (
            <div className="form-group row">
              <label className="col-sm-2 col-form-label text-right">Kategorie</label>
              <div className="col-sm-2">
                <select
                  name="asset_class"
                  className="form-control"
                  value={questionnaire.asset_class}
                  onChange={(event) => handleInputChange(index, event)}
                >
                  <option value="">keine Kategorie</option>
                  <option value="office_market">Büro</option>
                  <option value="residential_market">Wohnen</option>
                  <option value="logistics_market">Industrie</option>
                  <option value="retail_market">Handel</option>
                </select>
              </div>
            </div>
          )}
          <div className="form-group row">
            <label className="col-sm-2 col-form-label text-right">Bedingung</label>
            <div className="col">
              <ConstraintBox
                constraints={flattenDeep(
                  questionnaires.map(
                    (questionnaire) =>
                      questionnaire.pages &&
                      questionnaire.pages.map(
                        (page) =>
                          page.questions &&
                          page.questions.map((q) => {
                            return {
                              id: q.id,
                              qid: q.qid,
                              text: q.label,
                              has: q.possible_answers,
                              pageId: page.id,
                              questionnaireId: questionnaire.id,
                            }
                          })
                      )
                  )
                ).filter((item) => item !== undefined && item.has)}
                selected={questionnaire.constraints}
                changeHandler={(constraint) => handleConstraintChange(index, constraint)}
              />
            </div>
          </div>
        </div>

        <Page
          questionnaireId={questionnaire.id}
          pages={questionnaire.pages || []}
          questionnaireIndex={index}
          assetClass={questionnaire.asset_class}
        ></Page>
      </React.Fragment>
    )
  }

  return (
    <div className="mt-5">
      <div className="d-flex align-items-center">
        <h3>Fragebögen</h3>
        <button
          type="button"
          className="btn-sm btn-outline-primary ml-5"
          onClick={handleNewQuestionnaireClick}
        >
          <small>Neuer Fragebogen</small>
        </button>
      </div>
      <hr></hr>
      <div className="d-flex">
        {questionnaires.length > 0 && (
          <TabContainer
            tabs={questionnaires}
            activeTab={questionnaireTab}
            setActiveTab={setQuestionnaireTab}
            tabWidth={'145px'}
            handleDelete={showDeleteModal}
            updateOrder={updateOrder}
          />
        )}
      </div>
      {questionnaires[questionnaireTab] &&
        !questionnaires[questionnaireTab].deleted &&
        renderSingleQuestionnaire(questionnaires[questionnaireTab], questionnaireTab)}
      {showModal && (
        <Modal onHide={hideDeleteModal} maxWidth={'500px'} minWidth={'450px'}>
          <div className="header">Fragebogen löschen?</div>
          <div className="body">
            <p>
              Fragebogen <b>{questionnaires[questionnaireToDelete].title}</b> wirklich löschen?
            </p>
            <div>Zusätzlich werden folgende Inhalte gelöscht:</div>
            <ul>
              <li>Alle Seiten des Fragebogens</li>
              <li>
                <b>Alle Fragen der Seite</b>
              </li>
              <li>
                <b>Antworten zu den Fragen</b>
              </li>
            </ul>
          </div>
          <div className="footer justify-content-between">
            <Button variant="secondary" onClick={hideDeleteModal}>
              Abbrechen
            </Button>
            <Button variant="danger" onClick={handleDeleteQuestionnaireClick}>
              Löschen
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}
