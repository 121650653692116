import React from 'react'
import { ListBox } from './Components/ListBox'
import { MaxValue } from './Components/MaxValue'
import { MinValue } from './Components/MinValue'
import { DefaultValue } from './Components/DefaultValue'
import { WithDecimal } from './Components/WithDecimal'
import { DataType } from './Components/DataType'

export const InputColumns = ({ question, questionnaireIndex, pageIndex, index }) => {
  return (
    <div>
      <ListBox
        label={'Antworten'}
        name="possible_answers"
        items={question.possible_answers}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <MinValue
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <MaxValue
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <DefaultValue
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <WithDecimal
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <DataType
        question={question}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
    </div>
  )
}
