import React, { useEffect } from 'react'
import { useSurveyDataStore } from '../dataStore'
import { PageIndicator } from '../page-indicator'
import { NextPage } from './Components/NextPage'
import { NextButton, PrevButton } from './Components/Buttons'

export const QuestionnaireBuilder = ({ onHide }) => {
  const [{ questionnaire, pageNumber }, { setInitialState }] = useSurveyDataStore()

  // const [showScrollIndicatorTop, setShowScrollIndicatorTop] = useState(false)
  // const [showScrollIndicatorBottom, setShowScrollInidicatorBottom] = useState(false)
  // const [scrolledBottom] = useState(false)

  useEffect(() => {
    return function cleanup() {
      setInitialState()
    }
  }, [setInitialState])

  if (questionnaire === null) {
    return <h1>Keine Fragen zu beantworten!</h1>
  } else {
    return (
      <>
        <div className="questionnaireWindow">
          <NextPage onHide={onHide} />
          <div className="d-flex justify-content-between align-items-center pageNavigation">
            <PrevButton />
            {pageNumber < questionnaire.pages.length && (
              <PageIndicator pageCount={questionnaire.pages.length + 1} pageNo={pageNumber} />
            )}
            <NextButton onHide={onHide} />
          </div>
        </div>
      </>
    )
  }
}
