import React from 'react'

export const Modal = ({ children, onHide, maxWidth, minWidth, marginX }) => {
  const handleOutsideClick = (event) => {
    if (event.target.id === 'outside') {
      onHide(false)
    }
  }
  return (
    <div className="modalWindow" id="outside" onClick={handleOutsideClick}>
      <div
        className="modalWindowContent"
        style={{ maxWidth, marginLeft: marginX, marginRight: marginX, minWidth }}
      >
        <div className="crossContainer align-self-end" onClick={() => onHide(false)}>
          <div className="crossBarOne">
            <div className="crossBarTwo"></div>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
