import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Forbidden } from '../pages/ErrorPages/Forbidden'
import { LoginInfo } from '../pages/ErrorPages/LoginInfo'
import { useSurveyDataStore } from '../pages/Survey/dataStore'
import { useUserDataStore } from '../stores/userStore'
import { Container } from './Container'

export const AuthorizationHandler = () => {
  const [{ user }] = useUserDataStore()
  const [alreadyLoggedIn] = useState(() => !!user)
  const [{ isAuthorized, dataIsLoading }, { fetchSurvey }] = useSurveyDataStore()
  const { surveySlug } = useParams()

  useEffect(() => {
    // user logged in after seeing this page
    if (user && !alreadyLoggedIn) {
      fetchSurvey(surveySlug)
    }
  }, [user, alreadyLoggedIn, surveySlug, fetchSurvey])

  return !user ? <LoginInfo /> : !dataIsLoading && !isAuthorized ? <Forbidden /> : <Container />
}
