import React from 'react'
export class ConstraintBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      when: props.selected ? props.selected.when : 'intern-0',
      has: props.selected ? props.selected.has : 'intern-0',
    }
  }

  handleWhenChange = (event) => {
    // const when = this.props.constraints.find(constraint => constraint.id === parseInt(event.target.value))

    this.setState({ when: event.target.value, has: 'intern-0' })
  }

  handleHasChange = (event) => {
    const has = event.target.value

    console.log('handleHasChange: value', event.target.value)

    this.setState({ has: has })
    if (has !== 'intern-0' && this.props.changeHandler && typeof this.props.changeHandler === 'function') {
      this.props.changeHandler({ type: 'display', when: this.state.when, has: parseInt(has) })
    }
  }

  handleSetBack = () => {
    this.setState({ when: 'intern-0', has: 'intern-0' })
    this.props.changeHandler(null)
  }

  render() {
    const constraint =
      this.state.when &&
      this.state.when !== 'intern-0' &&
      this.props.constraints.find((c) => c.qid === this.state.when)
    return (
      <div>
        <span>sichtbar, wenn</span>
        <select
          className="mx-3"
          value={this.state.when}
          onChange={this.handleWhenChange}
          style={{ width: '50%', maxWidth: '50%' }}
        >
          <option key="0" value="intern-0">
            &mdash; Frage wählen
          </option>
          {this.props.constraints.map((when) => (
            <option
              key={`${when.questionnaireId}-${when.pageId}-${when.qid}`}
              value={when.qid}
              dangerouslySetInnerHTML={{ __html: when.text }}
            ></option>
          ))}
        </select>
        <span>hat</span>
        <select className="mx-3" value={this.state.has} onChange={this.handleHasChange}>
          <option key="0" value="intern-0">
            &mdash; Antwort wählen
          </option>
          {
            // this.state.when && this.state.when !== 'intern-0' && this.state.when.has.map(has => <option key={has.id} value={has.id}>{has.text}</option>)
            constraint &&
              constraint.has.map((has) => (
                <option key={has.id} value={has.id}>
                  {has.text}
                </option>
              ))
          }
        </select>
        <button
          tpye="button"
          className="btn btn-outline-secondary btn-sm text-danger ml-3"
          onClick={this.handleSetBack}
        >
          &#x2718;
        </button>
      </div>
    )
  }
}
