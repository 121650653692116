import React from 'react'

const placeholderText = `Die Marktsituation hat sich...`

export class RiwisRadioMatrix extends React.Component {
  constructor(props) {
    super(props)

    this.radioMatrixRef = React.createRef()

    this.state = {
      givenAnswer: props.value || {},
      scrollEndX: false,
      selectedQuestion: null,
      showNote: props.value.note ? true : false,
    }
  }

  handleChange = (event) => {
    // target name is of form
    // <id of overall question>_<id within questions field>
    //
    const questionName = this.props.id
    const [question, answer] = event.target.value.split('_')
    let givenAnswer = { ...this.state.givenAnswer }

    givenAnswer[question] = answer

    if (this.props.oneAnswer) {
      givenAnswer = {
        [question]: answer,
        fileMaker: this.props.riwisDatasources[0].fileMaker,
        note: givenAnswer.note || '',
      }
    }

    this.setState({ givenAnswer: givenAnswer })

    this.props.onChange({ question_id: questionName, value: givenAnswer, qid: this.props.qid })
  }

  handleNoteChange = (event) => {
    const givenAnswer = { ...this.state.givenAnswer }
    givenAnswer.note = event.target.value
    this.setState({ givenAnswer: givenAnswer })
    this.props.onChange({ question_id: this.props.id, value: givenAnswer, qid: this.props.qid })
  }

  resetAnswer = () => {
    const givenAnswer = {
      fileMaker: this.props.riwisDatasources[0].fileMaker,
      note: this.state.givenAnswer.note,
    }
    this.setState({ givenAnswer })
    this.props.onChange({ question_id: this.props.id, value: givenAnswer, qid: this.props.qid })
  }

  toggleNote = () => {
    this.setState({ showNote: !this.state.showNote })
  }

  render() {
    const tableHeader = (
      <thead className="riwis">
        <tr>
          <th style={{ borderRight: '1px solid lightgrey' }} colSpan="2">
            &nbsp;
          </th>
          <th
            colSpan={this.props.answers.length}
            style={{
              textAlign: 'center',
              borderBottom: '1px solid lightgrey',
              borderTop: '1px solid lightgrey',
              backgroundColor: '#efedee',
              color: 'black',
            }}
          >
            <h5 className="p-4" dangerouslySetInnerHTML={{ __html: this.props.answerHeader }}></h5>
          </th>
        </tr>
        <tr>
          <th style={{ borderRight: '1px solid lightgrey' }} colSpan="2" scope="col">
            <div className="theadBlur"></div>
          </th>
          {this.props.answers.map((answer) => {
            return (
              <th
                style={{ paddingTop: '0.75rem' }}
                key={answer.id}
                dangerouslySetInnerHTML={{ __html: answer.text }}
              ></th>
            )
          })}
        </tr>
      </thead>
    )

    const tableBody = (
      <tbody>
        {this.props.questions.map((question, index) => {
          return (
            <tr style={{ verticalAlign: 'center' }} key={question.id}>
              {index === 0 && (
                <th
                  style={{
                    borderRight: '1px solid lightgrey',
                    borderLeft: '2px solid lightgrey',
                    backgroundColor: '#efedee',
                    color: 'black',
                  }}
                  rowSpan={this.props.questions.length}
                >
                  <h5 dangerouslySetInnerHTML={{ __html: this.props.questionHeader }}></h5>
                </th>
              )}
              <th
                key={question.id}
                scope="row"
                dangerouslySetInnerHTML={{ __html: question.text }}
                style={{ borderRight: '1px solid lightgrey' }}
              ></th>
              {this.props.answers.map((answer) => {
                const elementName = this.props.id + '_' + question.id
                const elementId = this.props.id + '_' + question.id + '_' + answer.id
                const elementValue = question.id + '_' + answer.id

                const checked = parseInt(this.props.value[question.id]) === answer.id

                return (
                  <td key={answer.id}>
                    {
                      <div className="custom-control custom-radio" key={elementId}>
                        <input
                          className="custom-control-input"
                          type="radio"
                          name={elementName}
                          id={elementId}
                          value={elementValue}
                          onChange={this.handleChange}
                          checked={checked}
                        ></input>
                        <label className="custom-control-label" htmlFor={elementId}></label>
                      </div>
                    }
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    )

    return (
      <>
        <div className="d-flex">
          <div className="matrixTableContainer riwis">
            <table className="matrixTableRiwis">
              {tableHeader}
              {tableBody}
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4 flex-1">
          <div
            onClick={this.toggleNote}
            style={{
              color: 'rgb(0,41,64)',
              textDecoration: 'underline',
              cursor: 'pointer',
              width: 'fit-content',
              fontSize: '0.875rem',
            }}
          >
            Anmerkung hinzufügen
          </div>
          <div
            style={{
              color: 'rgb(0,41,64)',
              textDecoration: 'underline',
              cursor: 'pointer',
              width: 'fit-content',
              fontSize: '0.875rem',
            }}
            onClick={this.resetAnswer}
          >
            Antwort zurücksetzen
          </div>
        </div>
        {this.state.showNote && (
          <textarea
            value={this.state.givenAnswer.note || ''}
            // ref={textareaRef}
            name="note"
            className="riwisTextareaWhite"
            style={{ width: '100%' }}
            placeholder={placeholderText}
            onChange={this.handleNoteChange}
          ></textarea>
        )}
      </>
    )
  }
}
