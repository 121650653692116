import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useUserDataStore } from '../../stores/userStore'

const Feedback = ({ message, handleClose }) => {
  if (message.type === 'success') {
    return (
      <>
        <div className="d-flex align-items-center" style={{ height: '8rem' }}>
          <i className="far fa-envelope mr-3" style={{ fontSize: '3rem', color: '#003e71' }}></i>
          <div style={{ fontSize: '1rem' }}>
            Sie erhalten in Kürze eine E-Mail zum Zurücksetzen Ihres Passworts.
          </div>
        </div>
        <button className="btn btn-outline-secondary form-group" id="loginBtn" onClick={handleClose}>
          Schließen
        </button>
      </>
    )
  } else {
    return (
      <>
        <div className="d-flex align-items-center" style={{ height: '8rem' }}>
          <i className="fas fa-times mr-3" style={{ fontSize: '3rem', color: '#003e71' }}></i>
          <div style={{ fontSize: '1rem' }}>Leider konnte keine Bestätigungsmail versendet werden!</div>
        </div>
        <button className="btn btn-outline-secondary form-group" id="loginBtn" onClick={handleClose}>
          Schließen
        </button>
      </>
    )
  }
}

export const ForgotPassword = ({ handleClose, riwisMode }) => {
  const [email, setEmail] = useState('')
  const [{ requestResetPasswordMessage }, { requestResetPassword }] = useUserDataStore()

  const handleInputChange = (event) => {
    setEmail(event.target.value)
  }

  const handlePasswordReset = () => {
    if (email) {
      requestResetPassword(email)
    }
  }

  return !requestResetPasswordMessage.type ? (
    <div className="col-auto">
      <h4>Passwort vergessen?</h4>
      <div className="py-4">
        Geben sie Ihre E-Mail Adresse an, um Ihr Passwort zurückzusetzen. Wir senden Ihnen in Kürze eine
        E-Mail mit einem Link zum ändern Ihres Passworts.
      </div>
      <div className="form-group py-4">
        <input
          className="form-control"
          type="text"
          onChange={handleInputChange}
          placeholder="E-Mail Adresse"
        ></input>
      </div>
      <Button
        style={{ fontWeight: riwisMode ? '500' : '' }}
        className={(riwisMode ? 'riwis-btn riwis-primary' : 'btn btn-primary') + ' form-group'}
        onClick={handlePasswordReset}
      >
        Passwort zurücksetzen
      </Button>
    </div>
  ) : (
    <Feedback message={requestResetPasswordMessage} handleClose={handleClose}></Feedback>
  )
}
