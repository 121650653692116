import React, { useState, useCallback } from 'react'
import { generateId, findLastNonDeleted } from './utils'
import { ConstraintBox } from './constraintBox'
import { TinyMCE } from './TinyMCE'
import { useSurveyEditorStateStore } from './surveyEditorState'
import { TabContainer } from './TabContainer'
import { QuestionSwitch } from './QuestionSwitch'

export const Question = ({
  questions,
  questionnaireId,
  questionnaireIndex,
  pageId,
  pageIndex,
  assetClass,
}) => {
  const [
    { survey, questionTab },
    { setQuestionInput, addQuestion, deleteQuestion, setQuestionTab, changeQuestionOrder },
  ] = useSurveyEditorStateStore()
  const [newId, setNewId] = useState(0)

  const handleInputChange = useCallback(
    (qid, event) => {
      const index = questions.findIndex((question) => question.qid === qid)
      setQuestionInput(questionnaireIndex, pageIndex, index, event.target.value, event.target.name)
    },
    [pageIndex, questionnaireIndex, questions, setQuestionInput]
  )

  const handleTinyMCEChange = useCallback(
    (qid, event) => {
      const index = questions.findIndex((question) => question.qid === qid)
      setQuestionInput(questionnaireIndex, pageIndex, index, event.value, event.name)
    },
    [pageIndex, questionnaireIndex, questions, setQuestionInput]
  )

  const handleConstraintChange = useCallback(
    (qid, constraint) => {
      const index = questions.findIndex((question) => question.qid === qid)
      setQuestionInput(questionnaireIndex, pageIndex, index, constraint, 'constraints')
    },
    [pageIndex, questionnaireIndex, questions, setQuestionInput]
  )

  const handleNewQuestionClick = useCallback(() => {
    const qid = generateId(6)
    const initialProperties = {
      id: newId,
      qid,
      is_optional: false,
      title: 'Titel 0',
      type: 'text',
      label: '',
      description: '',
      definition: '',
      possible_answers: [],
      questions_field: [],
      min_value: 0,
      max_value: 100,
      default_value: 0,
      order: questions.length + 1,
      unit: '',
      step: 0,
      with_decimal: null,
      constraints: null,
      limit: null,
      riwis_datasources: [],
      opt_out: false,
      data_type: 'text',
      one_answer: false,
      matrix_answer_header: '',
      matrix_question_header: '',
      show_map: false,
    }
    addQuestion(questionnaireIndex, pageIndex, initialProperties)
    setNewId(newId - 1)
    setQuestionTab(questions.length)
  }, [addQuestion, newId, pageIndex, questionnaireIndex, questions, setQuestionTab])

  const handleDeleteQuestionClick = useCallback(
    (index) => {
      if (questionTab === index) {
        setQuestionTab(findLastNonDeleted(index, questions))
      } else if (questionTab - 1 > 0) {
        setQuestionTab(questionTab - 1)
      }
      deleteQuestion(questionnaireIndex, pageIndex, index, true, 'delete')
    },
    [questions, deleteQuestion, pageIndex, questionnaireIndex, questionTab, setQuestionTab]
  )

  const handleCustomCheckboxChange = useCallback(
    (qid, question, name) => {
      const index = questions.findIndex((question) => question.qid === qid)
      setQuestionInput(questionnaireIndex, pageIndex, index, !question[name], name)
    },
    [pageIndex, questionnaireIndex, setQuestionInput, questions]
  )

  const updateOrder = (questions) => {
    changeQuestionOrder(questionnaireIndex, pageIndex, questions)
  }

  const renderSingleQuestion = useCallback(
    (question, index) => {
      return (
        <div className="mt-4 flex-1">
          <div className="form-group row">
            <label className="col-sm-2 form-group-label text-right">&nbsp;</label>
            <div className="col-sm-10">
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  name="is_optional"
                  type="checkbox"
                  readOnly
                  checked={question?.is_optional || false}
                ></input>
                <label
                  className="custom-control-label"
                  onClick={() => handleCustomCheckboxChange(question.qid, question, 'is_optional')}
                >
                  optional
                </label>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-2 form-group-label text-right">Frage</label>
            <div className="col-sm-10">
              <TinyMCE
                id={`questionLabel-${questionnaireId}-${pageId}-${question.id}`}
                name={'label'}
                content={question.label}
                inlineMode={true}
                handleTinyMCEChange={(event) => handleTinyMCEChange(question.qid, event)}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 form-group-label text-right">Beschreibung</label>
            <div className="col-sm-10">
              <TinyMCE
                id={`questionDescription-${questionnaireId}-${pageId}-${question.id}`}
                name={'description'}
                content={question.description}
                inlineMode={true}
                handleTinyMCEChange={(event) => handleTinyMCEChange(question.qid, event)}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 form-group-label text-right">Definition</label>
            <div className="col-sm-10">
              <TinyMCE
                id={`questionDefinition-${questionnaireId}-${pageId}-${question.id}`}
                name={'definition'}
                content={question.definition}
                inlineMode={true}
                handleTinyMCEChange={(event) => handleTinyMCEChange(question.qid, event)}
                toolbar={
                  'undo redo | bold italic |  forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code | image'
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 form-group-label text-right">Bedingung</label>
            <div className="col py-0">
              <ConstraintBox
                constraints={questions
                  .map((q) => {
                    return {
                      id: q.id,
                      qid: q.qid,
                      text: q.label,
                      has: q.possible_answers,
                      pageId: pageIndex,
                      questionnaireId: questionnaireId,
                    }
                  })
                  .filter((item) => item !== undefined && item.has)}
                selected={question.constraints}
                changeHandler={(constraint) => handleConstraintChange(question.qid, constraint)}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 form-group-label text-right">Typ</label>
            <div className="col-sm-10">
              <select
                className="form-control"
                name="type"
                value={question.type || 'radio'}
                onChange={(event) => handleInputChange(question.qid, event)}
              >
                <option value="radio">Radio</option>
                <option value="radioInline">Radio (in Reihe)</option>
                <option value="radioMatrix">Radio (Matrix)</option>
                <option value="checkbox">Checkbox</option>
                <option value="checkboxMatrix">Checkbox (Matrix)</option>
                <option value="text">Text</option>
                <option value="inputColumns">Textspalten</option>
                <option value="textarea">Textfeld</option>
                <option value="slider">Slider</option>
                {survey.type === 'riwis' && <option value="sliderRiwis">RIWIS Slider</option>}
                {survey.type === 'riwis' && <option value="radioMatrixRiwis">RIWIS Radio Matrix</option>}
                {survey.type === 'riwis' && <option value="radioInlineRiwis">RIWIS Radio (in Reihe)</option>}
                <option value="sum">Summen-Abfrage</option>
              </select>
            </div>
          </div>
          <QuestionSwitch
            question={question}
            index={index}
            questionnaireIndex={questionnaireIndex}
            pageIndex={pageIndex}
            assetClass={assetClass}
          />
        </div>
      )
    },
    [
      handleConstraintChange,
      handleCustomCheckboxChange,
      handleInputChange,
      handleTinyMCEChange,
      pageId,
      pageIndex,
      questionnaireId,
      questions,
      survey.type,
      questionnaireIndex,
      assetClass,
    ]
  )

  return (
    <div className="mt-5">
      <div className="d-flex align-items-center">
        <h3>Fragen auf Seite {pageIndex + 1}</h3>
        <button type="button" className="btn-sm btn-outline-primary ml-5" onClick={handleNewQuestionClick}>
          <small>Neue Frage</small>
        </button>
      </div>
      <hr></hr>
      <div className="d-flex flex-row">
        {questions.length > 0 && (
          <div className="d-flex flex-column mr-5">
            <TabContainer
              tabs={questions}
              updateOrder={updateOrder}
              activeTab={questionTab}
              setActiveTab={setQuestionTab}
              handleDelete={handleDeleteQuestionClick}
              tabWidth={'160px'}
              flexDirection={'column'}
            />
          </div>
        )}
        {questions[questionTab] && !questions[questionTab]?.deleted
          ? renderSingleQuestion(questions[questionTab], questionTab)
          : ''}
      </div>
    </div>
  )
}
