import { isArray } from 'highcharts'
import React from 'react'

export const AlertBox = ({ type, message, error, position, right, top }) => {
  const renderList = (errors) => (
    <ul style={{ color: 'black' }} className="mt-3 p-1">
      {errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  )

  return (
    type?.length > 0 && (
      <div
        className={(type === 'success' ? 'success' : 'warning') + ' alertBox'}
        style={{ position: position ? position : 'absolute', top: top || 0, right: right || 0, zIndex: 100 }}
      >
        <div className="d-flex align-items-center">
          {type === 'success' ? (
            <i className="fas fa-check mr-3 icon success"></i>
          ) : (
            <i className="fas fa-exclamation mr-3 icon warning"></i>
          )}
          <div>{message ? message : ''}</div>
        </div>
        <div>{error ? (isArray(error) ? renderList(error) : error) : ''}</div>
      </div>
    )
  )
}
