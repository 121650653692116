import React from 'react'

export class Sum extends React.Component {
  constructor(props) {
    super(props)

    this.state = { givenAnswer: props.value || {} }
  }

  handleChange = (event) => {
    const givenAnswer = { ...this.state.givenAnswer }

    const previousValue = givenAnswer[event.target.name] || ''

    givenAnswer[event.target.name] = event.target.value

    const sum = Object.values(givenAnswer).reduce((accu, value) => accu + Number(value), 0)
    if (sum > this.props.maxValue) {
      givenAnswer[event.target.name] = previousValue
    }

    this.setState({ givenAnswer: givenAnswer })

    this.props.onChange({ question_id: this.props.id, value: givenAnswer, qid: this.props.qid })
  }

  handleOptOut = (event) => {
    const givenAnswer = { ...this.state.givenAnswer }

    givenAnswer[event.target.name] = event.target.checked
    this.setState({ givenAnswer: givenAnswer })

    this.props.onChange({ question_id: this.props.id, value: givenAnswer, qid: this.props.qid })
  }

  render() {
    const sum = this.state.givenAnswer.optOut
      ? '--'
      : Object.values(this.state.givenAnswer).reduce((accu, value) => accu + Number(value), 0)
    const maxValue = Number(this.props.maxValue)
    const sumInputs = this.props.answers.map((answer) => {
      const elementId = this.props.id + '_' + answer.id

      return (
        <tr key={elementId}>
          <th dangerouslySetInnerHTML={{ __html: answer.text }}></th>
          <td>
            <div className="input-group flex-nowrap">
              <input
                className="form-control col-12"
                type="number"
                name={answer.id}
                id={elementId}
                onChange={this.handleChange}
                value={this.state.givenAnswer['' + answer.id] || ''}
                aria-describedby="input-unit"
                min="0"
              ></input>
              <div className="input-group-append">
                <span className="input-group-text" id="input-unit">
                  {this.props.unit}
                </span>
              </div>
            </div>
          </td>
        </tr>
      )
    })

    return (
      <div>
        <table className="sumTable">
          <thead></thead>
          <tbody>
            <tr>
              <th style={{ padding: '14px 0' }}>Summe</th>
              <td>
                <div className="input-group">
                  <input
                    readOnly
                    className={(sum === maxValue ? 'text-success' : 'text-danger') + ' form-control col-12'}
                    type="text"
                    id="sumUp"
                    value={sum + '/' + maxValue || ''}
                    aria-describedby="input-unit"
                  ></input>
                  <div className="input-group-append">
                    <span className="input-group-text" id="input-unit">
                      {this.props.unit}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ borderTop: '1px solid grey', padding: '6px' }}></td>
              <td style={{ borderTop: '1px solid grey', padding: '6px' }}></td>
            </tr>
            {sumInputs}
          </tbody>
        </table>
        {this.props.optOut === true && (
          <div className="custom-control custom-checkbox mb-2 mt-5">
            <input
              className="custom-control-input"
              type="checkbox"
              name="optOut"
              id="optOut"
              onChange={this.handleOptOut}
              checked={this.state.givenAnswer.optOut || false}
            ></input>
            <label className="custom-control-label" htmlFor="optOut">
              Diese Frage kann ich nicht beantworten
            </label>
          </div>
        )}
      </div>
    )
  }
}
