import { useSurveyDataStore } from '../../dataStore'
import { TextEnum } from '../../QuestionTypes/RiwisSlider/mapHelper'

export const Finish = () => {
  return (
    <div
      style={{ flex: 1 }}
      className="d-flex justify-content-center align-items-center h-100 w-100 align-self-center"
    >
      <p className="h3 text-center">Möchten Sie die Umfrage abschliessen?</p>
    </div>
  )
}

export const RiwisFinish = () => {
  const [{ questionnaire }] = useSurveyDataStore()
  return (
    <div
      style={{ flex: 1 }}
      className="d-flex justify-content-center align-items-center h-100 w-100 align-self-center"
    >
      <p className="h3 text-center">
        Möchten Sie den Umfrage-Teil "{TextEnum[questionnaire.asset_class]}" abschließen?
      </p>
    </div>
  )
}

export const Fail = () => {
  return (
    <div
      style={{ flex: 1 }}
      className="d-flex justify-content-center align-items-center align-self-center h-100 w-100"
    >
      <div className="d-flex justify-content-center align-items-center flex-wrap"></div>
      <i
        className="fas fa-exclamation-circle text-warning align-middle mx-5 mb-4"
        style={{ fontSize: '5rem', float: 'left' }}
      ></i>
      <h3 className="text-center">
        Leider konnten Ihre Antworten nicht gespeichert werden.<br></br>
        Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.
      </h3>
    </div>
  )
}

export const Thanks = () => {
  const [{ isSaving, saveMessage }] = useSurveyDataStore()
  return (
    isSaving === false &&
    (saveMessage.type === 'success' ? (
      <div
        style={{ flex: 1 }}
        className="d-flex justify-content-center align-items-center align-self-center h-100 w-100"
      >
        <div className="d-flex justify-content-center align-items-center flex-wrap">
          <i
            className="fas fa-check-circle text-success align-middle mx-5 mb-4"
            style={{ fontSize: '5rem' }}
          ></i>
          <h3 className="text-center">Vielen Dank für Ihre Teilnahme</h3>
        </div>
      </div>
    ) : (
      <Fail />
    ))
  )
}

export const RiwisThanks = () => {
  const [{ nextRiwisQuestionnaire, questionnaire, saveMessage, isSaving }] = useSurveyDataStore()
  return (
    isSaving === false &&
    (saveMessage.type === 'success' ? (
      <div
        style={{ flex: 1 }}
        className="d-flex justify-content-center align-items-center align-self-center h-100 w-100"
      >
        <div className="d-flex justify-content-center align-items-center flex-wrap">
          <i
            className="fas fa-check-circle text-success align-middle mx-5 mb-4"
            style={{ fontSize: '5rem' }}
          ></i>
          <h3 className="text-center">Vielen Dank für Ihre Teilnahme</h3>
          {nextRiwisQuestionnaire.id !== questionnaire.id && (
            <h5 className="mt-5 text-center">
              Sie können nun mit dem Umfrage-Teil "{TextEnum[nextRiwisQuestionnaire.asset_class]}" fortsetzen
              oder die Umfrage beenden.
            </h5>
          )}
        </div>
      </div>
    ) : (
      <Fail />
    ))
  )
}
