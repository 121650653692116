import React, { useState, useEffect } from 'react'
import { AlertBox } from '../../../../components/AlertBox'
import { ModalSelectBox } from './modalSelectBox'
import { UserSelectBox } from '../../../../components/UserSelectBox'
import { Questionnaire } from './questionnaire'
import { TinyMCE } from './TinyMCE'
import { useSurveyEditorStateStore } from './surveyEditorState'
import DatePicker from 'react-date-picker'
import { Dropdown, Button, ButtonGroup, DropdownButton } from 'react-bootstrap'
import { TemplateSave } from './TemplateSave'
import { TemplateLoad } from './TemplateLoad'
import { useParams, useHistory } from 'react-router-dom'

const parseTime = (date) => {
  date = new Date(date) || new Date()
  const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`
  const minutes = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`
  return `${hours}:${minutes}`
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const Survey = () => {
  const [
    { dataIsLoading, survey, userGroups, saveMessage, editTemplate, template },
    { initSurvey, setSurveyInput, saveSurvey, setSaveMessage, saveTemplate, setSurvey },
  ] = useSurveyEditorStateStore()
  const [fileErrors, setFileErrors] = useState({})
  const [showTemplateSave, setShowTemplateSave] = useState(false)
  const [showTemplateLoad, setShowTemplateLoad] = useState(false)
  const [updateContent, setUpdateContent] = useState(false)
  const { surveyId } = useParams()
  const history = useHistory()

  const handleSave = () => {
    saveSurvey(survey)
  }

  const handleTemplateSave = () => {
    saveTemplate(template.title, survey, template.id)
  }
  const handleUserGroupsChange = (event) => {
    const usergroups = event.map((usergroup) => usergroup.id)
    setSurveyInput(usergroups, 'usergroups')
  }
  const handleSupportContactChange = (supportContact) => {
    setSurveyInput(supportContact, 'support_contact')
  }
  const handleInputChange = (event) => {
    if (event?.target?.type === 'checkbox') {
      setSurveyInput(event.target.checked, event.target.name)
    } else {
      setSurveyInput(event.target.value, event.target.name)
    }
  }
  const handleTinyMCEChange = (event) => {
    setSurveyInput(event.value, event.name)
  }

  const updateTinyMCEContent = (bool) => {
    setUpdateContent(bool)
  }

  const handleColorReset = (value, property) => {
    setSurveyInput(value, property)
  }

  const handleDateChange = (event, name) => {
    setSurveyInput(new Date(event).toISOString(), name)
  }

  const handleTimeChange = (event) => {
    const date = event.target.name === 'from' ? survey.from : survey.to
    const [hours, minutes] = event.target.value.split(':')
    let time = new Date(date)
    time.setHours(parseInt(hours))
    time.setMinutes(parseInt(minutes), 0, 0)
    setSurveyInput(new Date(time).toISOString(), event.target.name)
  }

  const handleFileUpload = (event, type) => {
    const newFileErrors = { ...fileErrors }
    newFileErrors[type] = []
    if (event.target.files[0]) {
      if (event.target.files[0].size > 2 * 1024 * 1024) {
        newFileErrors[type].push('Bild muss kleiner als 2mb sein')
      }
      if (event.target.files[0].type.split('/')[0] !== 'image') {
        newFileErrors[type].push('Die Datei muss ein Bild sein')
      }
      if (newFileErrors[type].length) {
        setFileErrors(newFileErrors)
      } else {
        setFileErrors(newFileErrors)
        toBase64(event.target.files[0]).then((base64Image) => {
          setSurveyInput(base64Image, type)
        })
      }
    }
  }

  const handleImageDelete = (type) => {
    const newFileErrors = { ...fileErrors }
    newFileErrors[type] = []
    setFileErrors(newFileErrors)
    setSurveyInput('', type)
  }

  useEffect(() => {
    initSurvey(surveyId)
    return function cleanup() {
      setSurvey(null)
    }
  }, [initSurvey, surveyId, setSurvey])

  useEffect(() => {
    if (saveMessage.msg.length) {
      setTimeout(() => {
        setSaveMessage({ msg: '', error: '', type: '' })
      }, 3000)
    }
  }, [saveMessage, setSaveMessage])

  // redirect to survey with proper id, after creation
  useEffect(() => {
    if (surveyId === '0' && survey && survey.id !== 0) {
      history.push({ pathname: `/admin/survey/${survey.id}` })
    }
  }, [survey, surveyId, history])

  return (
    dataIsLoading === false && (
      <>
        <div className="sticky-top pt-3" style={{ backgroundColor: 'rgba(255, 255, 255, 0.95)' }}>
          <div className="d-flex">
            <h3 className="mr-5 text-nowrap">
              {survey?.id === 0 && !survey.title ? 'Neue Umfrage' : survey.title}
            </h3>
            {editTemplate ? (
              <button className="btn-sm btn-primary text-nowrap" onClick={handleTemplateSave}>
                Vorlage speichern
              </button>
            ) : (
              <button className="btn-sm btn-primary" onClick={handleSave}>
                Speichern
              </button>
            )}
            <div id="#alert" className="d-flex justify-content-end w-100 mr-4">
              <AlertBox type={saveMessage.type} message={saveMessage.msg} error={saveMessage.error} />
            </div>
            <DropdownButton id="dropdown-basic-button" title="Aktionen">
              <Dropdown.Item onClick={() => setShowTemplateSave(true)}>als Vorlage speichern</Dropdown.Item>
              <Dropdown.Item onClick={() => setShowTemplateLoad(true)} disabled={survey.id}>
                Vorlage laden
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <hr className="mb-5"></hr>
        </div>

        <div className="form-group row align-items-center">
          <label className="col-sm-2 h5 m-0 text-primary text-right">Umfragetyp</label>
          <div className="col">
            <Dropdown as={ButtonGroup}>
              <Button style={{ width: '10rem' }} variant="outline-primary">
                {survey.type === 'survey' ? 'SurveyDesk' : 'RIWIS'}
              </Button>

              <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

              <Dropdown.Menu align="right">
                <Dropdown.Item onClick={() => setSurveyInput('survey', 'type')}>SurveyDesk</Dropdown.Item>
                <Dropdown.Item onClick={() => setSurveyInput('riwis', 'type')}>RIWIS</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {survey.type === 'riwis' && (
          <div className="form-group row">
            <label className="col-sm-2 h5 m-0 text-primary text-right">Jahr der Datenquellen</label>
            <div className="col-sm-2">
              <input
                id="datasource_year"
                className="form-control"
                type="text"
                name="datasource_year"
                value={survey.datasource_year || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
        )}

        <div className="form-group row align-items-center">
          <label className="col-sm-2 h5 m-0 text-primary text-right">Status</label>
          <div className="col">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                className="custom-control-input"
                id="radioDraft"
                type="radio"
                name="status"
                value="draft"
                onChange={handleInputChange}
                checked={survey.status === 'draft' ? 'checked' : ''}
              ></input>
              <label className="custom-control-label" htmlFor="radioDraft">
                Entwurf
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                className="custom-control-input"
                id="radioPublished"
                type="radio"
                name="status"
                value="published"
                onChange={handleInputChange}
                checked={survey.status === 'published' ? 'checked' : ''}
              ></input>
              <label className="custom-control-label" htmlFor="radioPublished">
                Veröffentlicht
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                className="custom-control-input"
                id="radioArchived"
                type="radio"
                name="status"
                value="archived"
                onChange={handleInputChange}
                checked={survey.status === 'archived' ? 'checked' : ''}
              ></input>
              <label className="custom-control-label" htmlFor="radioArchived">
                Archiv
              </label>
            </div>
          </div>
        </div>

        <div className="form-group row align-items-center">
          <label className="col-sm-2 h5 text-primary text-right">User-Gruppen</label>
          <div className="col-sm-10">
            <ModalSelectBox
              items={userGroups}
              selectedItemIds={survey.usergroups}
              changeHandler={handleUserGroupsChange}
            />
          </div>
        </div>
        <div className="form-group row align-items-center">
          <label className="col-sm-2 h5 text-primary text-right">Ansprechpartner</label>
          <div className="col-sm-10">
            <UserSelectBox
              support_contact={survey.support_contact || {}}
              changeHandler={handleSupportContactChange}
            ></UserSelectBox>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">Titel</label>
          <div className="col">
            <input
              className="form-control"
              type="text"
              name="title"
              onChange={handleInputChange}
              value={survey.title || ''}
            ></input>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">Untertitel</label>
          <div className="col">
            <input
              className="form-control"
              type="text"
              name="subtitle"
              onChange={handleInputChange}
              value={survey.subtitle || ''}
            ></input>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">Beschreibung</label>
          <div className="col">
            <TinyMCE
              inlineMode={false}
              id={'surveyDescription'}
              content={survey.description}
              updateContent={updateContent}
              onUpdatedContent={updateTinyMCEContent}
              name={'description'}
              handleTinyMCEChange={handleTinyMCEChange}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right" htmlFor="fileUploader">
            Hintergrundbild (Beschreibung)
          </label>
          <div className="col-sm-10">
            {survey.background_image ? (
              <div style={{ width: '200px' }}>
                <img style={{ width: '100%' }} src={survey.background_image} alt="background_image" />
                <button
                  className="btn btn-primary mt-3"
                  onClick={() => handleImageDelete('background_image')}
                >
                  Hintergrundbild löschen
                </button>
              </div>
            ) : (
              <div>
                <input
                  type="file"
                  name="logo"
                  id="fileUploader"
                  onChange={(event) => handleFileUpload(event, 'background_image')}
                />
                {fileErrors['background_image'] &&
                  fileErrors['background_image'].map((error) => <p className="text-danger">{error}</p>)}
              </div>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">Laufzeit</label>
          <div className="col-sm form-inline">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-calendar-alt"></i>
                </span>
              </div>
              <input
                type="time"
                name="from"
                className="timePicker"
                value={parseTime(survey.from)}
                onChange={handleTimeChange}
              />
              <DatePicker
                value={new Date(survey.from)}
                onChange={(event) => handleDateChange(event, 'from')}
              />
            </div>

            <label className="col-form-label mx-sm-4">bis</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-calendar-alt"></i>
                </span>
              </div>
              <input
                type="time"
                name="to"
                className="timePicker"
                value={parseTime(survey.to)}
                onChange={handleTimeChange}
              />
              <DatePicker value={new Date(survey.to)} onChange={(event) => handleDateChange(event, 'to')} />
            </div>
          </div>
        </div>

        <div className="form-group row align-items-center">
          <label className="col-sm-2 col-form-label text-right">Sichtbarkeit für</label>
          <div className="col">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                className="custom-control-input"
                type="radio"
                id="radioTypeOpen"
                name="type_of_survey"
                value="open"
                onChange={handleInputChange}
                checked={survey.type_of_survey === 'open'}
              ></input>
              <label className="custom-control-label" htmlFor="radioTypeOpen">
                Jeden
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                className="custom-control-input"
                type="radio"
                id="radioTypeClosed"
                name="type_of_survey"
                value="closed"
                onChange={handleInputChange}
                checked={survey.type_of_survey === 'closed' || survey.type_of_survey == null}
              ></input>
              <label className="custom-control-label" htmlFor="radioTypeClosed">
                Gruppen
              </label>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right" htmlFor="primaryColor">
            Primärfarbe
          </label>
          <div className="col-sm-10">
            <input
              type="color"
              name="primaryColor"
              id="primaryColor"
              value={survey.primaryColor}
              onChange={handleInputChange}
            />
            {survey.primaryColor !== '#003e71' && (
              <button className="btn ml-2" onClick={() => handleColorReset('#003e71', 'primaryColor')}>
                <i className="fas fa-undo-alt"></i>
              </button>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right" htmlFor="secondaryColor">
            Sekundärfarbe
          </label>
          <div className="col-sm-10">
            <input
              type="color"
              name="secondaryColor"
              id="secondaryColor"
              value={survey.secondaryColor}
              onChange={handleInputChange}
            />
            {survey.secondaryColor !== '#7c7e7d' && (
              <button className="btn ml-2" onClick={() => handleColorReset('#7c7e7d', 'secondaryColor')}>
                <i className="fas fa-undo-alt"></i>
              </button>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right" htmlFor="fileUploader">
            Logo
          </label>
          <div className="col-sm-10">
            {survey.logo ? (
              <div style={{ width: '200px' }}>
                <img style={{ width: '100%' }} src={survey.logo} alt="logo" />
                <button className="btn btn-primary mt-3" onClick={() => handleImageDelete('logo')}>
                  Logo löschen
                </button>
              </div>
            ) : (
              <div>
                <input
                  type="file"
                  name="logo"
                  id="fileUploader"
                  onChange={(event) => handleFileUpload(event, 'logo')}
                />
                {fileErrors['logo'] &&
                  fileErrors['logo'].map((error) => <p className="text-danger">{error}</p>)}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right" htmlFor="showStar">
            Zeige Stern bei nicht-optionalen Fragen
          </label>
          <div className="col-sm-10">
            <div className="col">
              <input
                type="checkbox"
                checked={survey.show_star}
                onChange={handleInputChange}
                className="form-check-input"
                name="show_star"
                id="showStar"
              />
            </div>
          </div>
        </div>
        <TemplateSave showTemplateSave={showTemplateSave} onHide={() => setShowTemplateSave(false)} />
        <TemplateLoad
          showTemplateLoad={showTemplateLoad}
          onHide={() => setShowTemplateLoad(false)}
          onLoaded={updateTinyMCEContent}
        />
        {/* <Questionnaire identifier={this.props.identifier} changeHandler={this.handleChange} questionnaires={this.state.survey.questionnaires}/> */}
        <Questionnaire questionnaires={survey.questionnaires} />
      </>
    )
  )
}

export class Survey_old extends React.Component {
  constructor(props) {
    super(props)
    props.survey.fileError = []

    console.log('!!!!!!!!!construct!!!!!!!!!!')
    console.log(props)

    this.state = {
      survey: { ...props.survey } || {},
      save: false,
      templateMode: false,
      alertType: '',
      alertMessage: '',
      // generateTokenClicked: false
    }
  }

  handleChange = (questionnaires) => {
    let survey = { ...this.state.survey }
    survey.questionnaires = questionnaires
    this.setState({ survey: survey, save: false })
  }

  handleUserGroupsChange = (userGroups) => {
    let survey = { ...this.state.survey }
    survey.usergroups = userGroups.map((usergroup) => usergroup.id)

    this.setState({ survey: survey, save: false })

    this.props.changeHandler(survey)
  }

  handleSupportContactChange = (support_contact) => {
    let survey = { ...this.state.survey }
    survey.support_contact = support_contact
    this.props.changeHandler(survey)
  }

  handleInputChange = (event) => {
    console.log('Survey::handleInputChange: this.state', this.state)

    let survey = { ...this.state.survey }
    survey[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value

    this.setState({
      survey: survey,
      save: false,
    })

    this.props.changeHandler(survey)
  }

  handleDateInputChange = (event) => {
    let survey = { ...this.state.survey }

    //todo moment

    // survey[event.target.name] = moment($('[name=' + event.target.name + ']').datepicker('getUTCDate')).format(
    //   'YYYY-MM-DD'
    // )

    console.log('handleDateInputChange: value', survey[event.target.name])

    this.setState({ survey: survey, save: false })

    this.props.changeHandler(survey)
  }

  handleStatusChange = (event) => {
    let survey = { ...this.state.survey }

    survey[event.target.name] = event.target.value

    this.setState({ survey: survey, save: false })

    this.props.changeHandler(survey)
  }

  handleTinyMCEChange = ({ input, name }) => {
    let survey = { ...this.state.survey }
    survey[name] = input
    this.setState({ survey })
  }

  handleFileUpload = (event) => {
    let survey = { ...this.state.survey }
    survey.fileError = []
    if (event.target.files[0].size > 2 * 1024 * 1024) {
      survey.fileError.push('Bild muss kleiner als 2mb sein')
    }
    if (event.target.files[0].type.split('/')[0] !== 'image') {
      survey.fileError.push('Die Datei muss ein Bild sein')
    }
    if (survey.fileError.length) {
      this.setState({ survey, save: false })
    } else {
      this.toBase64(event.target.files[0]).then((base64Image) => {
        survey['logo'] = base64Image
        this.setState({
          survey: survey,
          save: false,
        })
        this.props.changeHandler(survey)
      })
    }
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  handleLogoDelete = () => {
    let survey = { ...this.state.survey }
    survey.logo = ''
    this.setState({
      survey,
      save: false,
    })
  }

  // handleColorReset = (isPrimary) => {
  //   let survey = { ...this.state.survey }
  //   isPrimary ? (survey.primaryColor = '#003e71') : (survey.secondaryColor = '#7c7e7d')
  //   this.setState({
  //     survey,
  //     save: false,
  //   })
  // }

  handleSave = () => {
    let survey = { ...this.state.survey }
    // todo
    // survey.description = tinymce.get('surveyDescription').getContent()
    this.saveSurvey(survey)
  }

  saveSurvey = (survey) => {
    // todo
    // console.log('save survey')
    // $.ajax({
    //   type: 'POST',
    //   url: '/api/survey/' + survey.id,
    //   data: { surveyData: JSON.stringify(survey).replace(/<\//g, '<\\/') },
    //   success: (res) => {
    //     this.showAlert(res.msg, 'success')
    //   },
    //   error: (res) => {
    //     this.showAlert(`${res.responseJSON.msg}: ${res.responseJSON.err}`, 'error')
    //   },
    // }).done((result) => {
    //   console.log(result)
    //   console.log(surveyApp)
    //   //- surveyApp.forceUpdate()
    //   //- surveyApp.setProps({test: 'test'})
    //   survey = result.survey.survey
    //   console.log(survey)
    //   this.setState({ survey, save: true })
    // })
  }

  showAlert = (text, type) => {
    this.setState({ alertMessage: text, alertType: type })
    // const alertBox = (
    //   <div
    //     className={(type === 'success' ? 'alert-success' : 'alert-danger') + ' alert fadeInAndOut'}
    //     style={{ position: 'absolute', top: 0 }}
    //   >
    //     {{ text }}
    //   </div>
    // )
    // setTimeout(() => {
    //   parentElement.removeChild(alertBox)
    // }, 5000)
  }

  saveTemplate = () => {
    //todo
    // $.ajax({
    //   type: 'POST',
    //   url: '/api/template',
    //   data: { surveyData: JSON.stringify(survey).replace(/<\//g, '<\\/') },
    //   success: (res) => {
    //     // showAlert(res.msg, 'success')
    //     console.log('Save success!!')
    //   },
    //   error: (res) => {
    //     showAlert(`${res.responseJSON.msg}: ${res.responseJSON.err}`, 'error')
    //   },
    // }).done((result) => {
    //   console.log(result)
    //   console.log(surveyApp)
    //   //- surveyApp.forceUpdate()
    //   //- surveyApp.setProps({test: 'test'})
    //   // survey = result.survey.survey
    //   console.log(survey)
    //   this.setState({ survey, save: true })
    // })
  }
  // handleGenerateTokenClick = () => {
  //   this.setState({generateTokenClicked: true})
  //   setTimeout(
  //     () => this.setState({generateTokenClicked: false}),
  //     3000
  //   )
  // }

  getDialogConfig = (editorContent) => {
    console.log(editorContent)
    const index = editorContent.indexOf('id="backgroundImage"')
    let imageURL = ''
    if (index !== -1) {
      imageURL = editorContent.match(/url\('blob:.*\);/)[0]
      imageURL = imageURL.match(/blob:.*\/\/.*\/[a-zA-Z0-9-]*/)[0]
    }
    const initialData = {
      image: {
        value: imageURL,
      },
      editorContent,
    }
    return {
      ...this.dialogConfig,
      initialData,
    }
  }

  dialogConfig = {
    title: 'Hintergrund-Bild festlegen',
    body: {
      type: 'panel',
      items: [
        {
          type: 'urlinput',
          name: 'image',
          filetype: 'image',
          label: 'Bild auswählen',
        },
        { type: 'input', name: 'editorContent', inputMode: 'text', label: '' },
      ],
    },
    buttons: [
      {
        type: 'cancel',
        name: 'closeButton',
        text: 'Cancel',
      },
      {
        type: 'submit',
        name: 'submitButton',
        text: 'Submit',
        primary: true,
      },
    ],
    onSubmit: (api) => {
      //data:image/png;base64,"
      //mceSetContent
      console.log(this.state.survey)
      const survey = { ...this.state.survey }
      var data = api.getData()
      var image = data.image
      // var backgroundImageUrl = image.value
      // const aspectRatio = image.meta.width / image.meta.height
      // const height = 1110 / aspectRatio
      // const style = `
      //   background-image: url(${backgroundImageUrl});
      //   background-size: cover;
      //   max-width: 1110px;
      //   max-height: ${height}px;
      // `
      // todo
      // tinymce.activeEditor.execCommand(
      //   'mceSetContent',
      //   false,
      //   `<div id="backgroundImage" style="${style}">${data.editorContent}</div>`
      // )
      api.close()
      survey.background_image = image.meta.base64
      this.setState({ survey })
      this.props.changeHandler(survey)
    },
  }

  componentDidMount() {
    if (this.props.survey.background_image) {
      console.log('background image found')
      const survey = { ...this.state.survey }
      const blob = this.b64toBlob(survey.background_image)
      const bgURL = URL.createObjectURL(blob)
      console.log('replace url with new generated url')
      survey.description = survey.description.replace(/blob:.*\/\/.*\/[a-zA-Z0-9-]*/, bgURL)
      this.setState({ survey })
    }
    // const getDialogConfig = (editorContent) => {
    //   return this.getDialogConfig(editorContent)
    // }
    // todo
    //   tinymce
    //     .init({
    //       selector: '#surveyDescription',
    //       branding: false,
    //       menubar: false,
    //       language: 'de',
    //       // image_upload_url: '/api/upload/image',
    //       file_picker_types: 'image',
    //       image_advtab: true,
    //       image_class_list: [],
    //       setup: function (editor) {
    //         editor.ui.registry.addButton('dialog-example-btn', {
    //           icon: 'code-sample',
    //           onAction: function () {
    //             editor.windowManager.open(getDialogConfig(editor.getContent()))
    //           },
    //         })
    //       },
    //       file_picker_callback: function (callback, value, meta) {
    //         let input = document.createElement('input')
    //         input.setAttribute('type', 'file')
    //         input.setAttribute('accept', 'image/*')
    //         input.onchange = function () {
    //           let file = this.files[0]
    //           console.log(this.files)

    //           let reader = new FileReader()
    //           reader.onload = function () {
    //             let id = 'blobid' + new Date().getTime()
    //             let blobCache = tinymce.activeEditor.editorUpload.blobCache
    //             let base64 = reader.result.split(',')[1]
    //             let blobInfo = blobCache.create(id, file, base64)
    //             console.log(blobInfo)
    //             let image = new Image()
    //             blobCache.add(blobInfo)
    //             image.src = reader.result
    //             image.onload = function () {
    //               callback(blobInfo.blobUri(), {
    //                 title: file.name,
    //                 width: this.width,
    //                 height: this.height,
    //                 base64: blobInfo.base64(),
    //               })
    //             }

    //             /* call the callback and populate the Title field with the file name */
    //           }
    //           reader.readAsDataURL(file)
    //         }

    //         input.click()
    //       },
    //       plugins: [
    //         'advlist autolink lists link image charmap print preview anchor',
    //         'searchreplace visualblocks code fullscreen',
    //         'insertdatetime media table paste imagetools wordcount textcolor',
    //       ],
    //       toolbar:
    //         'undo redo | forecolor backcolor| styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | dialog-example-btn | code',
    //       content_css: '/css/custom.css',
    //       content_style: 'body { font-family: "Open Sans", Helvetica, sans-serif; }',
    //     })
    //     .then(function () {
    //       $('.tox.tox-tinymce').css('border-radius', '0.25rem')
    //     })

    //   $('.datepicker').datepicker({
    //     autoclose: true,
    //     format: {
    //       toDisplay: function (date, format, language) {
    //         console.log('datepicker: toDisplay called', date)
    //         return moment(date).format('DD.MM.YYYY')
    //       },
    //       toValue: function (date, format, language) {
    //         console.log('datepicker: toValue called', date)
    //         return moment(date).format('YYYY-MM-DD')
    //       },
    //     },
    //   })

    //   $('.datepicker[name=from]').datepicker('setDate', this.state.survey.from)
    //   $('.datepicker[name=to]').datepicker('setDate', this.state.survey.to)

    //   $('.datepicker').on('changeDate', this.handleDateInputChange)

    //   $('.datepicker[name=from]').val(moment(this.state.survey.from).format('DD.MM.YYYY'))
    //   $('.datepicker[name=to]').val(moment(this.state.survey.to).format('DD.MM.YYYY'))

    //   $('.btn').click(function () {
    //     $(this).toggleClass('active')

    //     const outerThis = this

    //     $('.btn').each(function () {
    //       if (outerThis !== this) $(this).removeClass('active')
    //     })
    //   })
  }

  renderSurveyPart() {
    return (
      <React.Fragment>
        <div className="sticky-top pt-3" style={{ backgroundColor: 'rgba(255, 255, 255, 0.95)' }}>
          <div className="d-flex">
            <h3 className="mr-5 text-nowrap">
              {this.props.survey.id === 0 && !this.props.survey.title
                ? 'Neue Umfrage'
                : this.props.survey.title}
            </h3>
            <button className="btn-sm btn-primary" onClick={this.handleSave}>
              Speichern
            </button>
            <div id="#alert" className="d-flex justify-content-end w-100">
              <AlertBox type={this.state.alertType} message={this.state.alertMessage} />
            </div>
          </div>
          <hr className="mb-5"></hr>
        </div>
        <div className="form-group row align-items-center">
          <label className="col-sm-2 h5 m-0 text-primary text-right">Status</label>
          <div className="col">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                className="custom-control-input"
                id="radioDraft"
                type="radio"
                name="status"
                value="draft"
                onChange={this.handleStatusChange}
                checked={this.state.survey.status === 'draft' ? 'checked' : ''}
              ></input>
              <label className="custom-control-label" htmlFor="radioDraft">
                Entwurf
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                className="custom-control-input"
                id="radioPublished"
                type="radio"
                name="status"
                value="published"
                onChange={this.handleStatusChange}
                checked={this.state.survey.status === 'published' ? 'checked' : ''}
              ></input>
              <label className="custom-control-label" htmlFor="radioPublished">
                Veröffentlicht
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                className="custom-control-input"
                id="radioArchived"
                type="radio"
                name="status"
                value="archived"
                onChange={this.handleStatusChange}
                checked={this.state.survey.status === 'archived' ? 'checked' : ''}
              ></input>
              <label className="custom-control-label" htmlFor="radioArchived">
                Archiv
              </label>
            </div>
          </div>
        </div>

        <div className="form-group row align-items-center">
          <label className="col-sm-2 h5 text-primary text-right">User-Gruppen</label>
          <div className="col-sm-10">
            <ModalSelectBox
              items={this.props.userGroups}
              selectedItemIds={this.state.survey.usergroups}
              changeHandler={this.handleUserGroupsChange}
            />
          </div>
          {/* <div className="col">
          <button className="btn btn-primary mb-3" onClick={this.handleGenerateTokenClick}>Token generieren</button>
          <InfoBox header="Generiere Token..." title="TEST" footer="1234 User gesamt" show={this.state.generateTokenClicked}/>
        </div> */}
        </div>
        <div className="form-group row align-items-center">
          <label className="col-sm-2 h5 text-primary text-right">Ansprechpartner</label>
          <div className="col-sm-10">
            <UserSelectBox
              support_contact={this.props.survey.support_contact}
              changeHandler={this.handleSupportContactChange}
            ></UserSelectBox>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">Titel</label>
          <div className="col">
            <input
              className="form-control"
              type="text"
              name="title"
              onChange={this.handleInputChange}
              value={this.state.survey.title || ''}
            ></input>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">Untertitel</label>
          <div className="col">
            <input
              className="form-control"
              type="text"
              name="subtitle"
              onChange={this.handleInputChange}
              value={this.state.survey.subtitle || ''}
            ></input>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">Beschreibung</label>
          <div className="col">
            <TinyMCE
              inlineMode={false}
              id={'surveyDescription'}
              content={this.state.survey.description}
              name={'description'}
              handleTinyMCEChange={this.handleTinyMCEChange}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">Laufzeit</label>
          <div className="col-sm form-inline">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-calendar-alt"></i>
                </span>
              </div>
              <input className="form-control datepicker text-center" type="text" name="from"></input>
            </div>

            <label className="col-form-label mx-sm-4">bis</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-calendar-alt"></i>
                </span>
              </div>
              <input className="form-control datepicker text-center" type="text" name="to"></input>
            </div>
          </div>
        </div>

        <div className="form-group row align-items-center">
          <label className="col-sm-2 col-form-label text-right">Art der Umfrage</label>
          <div className="col">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                className="custom-control-input"
                type="radio"
                id="radioTypeOpen"
                name="type_of_survey"
                value="open"
                onChange={this.handleInputChange}
                checked={this.state.survey.type_of_survey === 'open'}
              ></input>
              <label className="custom-control-label" htmlFor="radioTypeOpen">
                offen
              </label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                className="custom-control-input"
                type="radio"
                id="radioTypeClosed"
                name="type_of_survey"
                value="closed"
                onChange={this.handleInputChange}
                checked={
                  this.state.survey.type_of_survey === 'closed' || this.state.survey.type_of_survey == null
                }
              ></input>
              <label className="custom-control-label" htmlFor="radioTypeClosed">
                geschlossen
              </label>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right" htmlFor="primaryColor">
            Primärfarbe
          </label>
          <div className="col-sm-10">
            <input
              type="color"
              name="primaryColor"
              id="primaryColor"
              value={this.state.survey.primaryColor}
              onChange={this.handleInputChange}
            />
            {this.state.survey.primaryColor !== '#003e71' && (
              <button className="btn ml-2" onClick={() => this.handleColorReset(true)}>
                <i className="fas fa-undo-alt"></i>
              </button>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right" htmlFor="secondaryColor">
            Sekundärfarbe
          </label>
          <div className="col-sm-10">
            <input
              type="color"
              name="secondaryColor"
              id="secondaryColor"
              value={this.state.survey.secondaryColor}
              onChange={this.handleInputChange}
            />
            {this.state.survey.secondaryColor !== '#7c7e7d' && (
              <button className="btn ml-2" onClick={() => this.handleColorReset(false)}>
                <i className="fas fa-undo-alt"></i>
              </button>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right" htmlFor="fileUploader">
            Logo
          </label>
          <div className="col-sm-10">
            {this.state.survey.logo ? (
              <div style={{ width: '200px' }}>
                <img style={{ width: '100%' }} src={this.state.survey.logo} alt="logo" />
                <button className="btn btn-primary mt-3" onClick={this.handleLogoDelete}>
                  Logo löschen
                </button>
              </div>
            ) : (
              <div>
                <input type="file" name="logo" id="fileUploader" onChange={this.handleFileUpload} />
                {this.state.survey.fileError &&
                  this.state.survey.fileError.map((error) => <p className="text-danger">{error}</p>)}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right" htmlFor="fileUploader">
            Zeige Stern bei nicht-optionalen Fragen
          </label>
          <div className="col-sm-10">
            <div className="col">
              <input
                type="checkbox"
                checked={this.state.survey.show_star}
                onChange={this.handleInputChange}
                className="form-check-input"
                name="show_star"
                id="showStar"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    console.log('Survey::render: called', this.state.survey.questionnaires)

    return (
      <React.Fragment>
        {this.renderSurveyPart()}

        {/* <Questionnaire identifier={this.props.identifier} changeHandler={this.handleChange} questionnaires={this.state.survey.questionnaires}/> */}
        <Questionnaire
          changeHandler={this.handleChange}
          questionnaires={this.state.survey.questionnaires || []}
          save={this.state.save}
        />
      </React.Fragment>
    )
  }
}
