import React, { useCallback } from 'react'
import { useSurveyEditorStateStore } from '../../surveyEditorState'

export const DataType = ({ question, questionnaireIndex, pageIndex, index }) => {
  const [, { setQuestionInput }] = useSurveyEditorStateStore()

  const handleInputChange = useCallback(
    (qid, event) => {
      setQuestionInput(questionnaireIndex, pageIndex, index, event.target.value, event.target.name)
    },
    [pageIndex, questionnaireIndex, index, setQuestionInput]
  )

  return (
    <div className={'form-group row'}>
      <label className="col-sm-2 form-group-label text-right">Datentyp</label>
      <div className="col-sm-10">
        <select name="data_type" onChange={(event) => handleInputChange(question.qid, event)}>
          <option value="text">Text</option>
          <option value="number">Zahl</option>
        </select>
      </div>
    </div>
  )
}
