import React from 'react'

export class Checkbox extends React.Component {
  constructor(props) {
    super(props)

    this.state = { givenAnswer: props.value || [] }
  }

  handleChange = (event) => {
    let givenAnswer = this.state.givenAnswer.slice()

    if (event.target.checked && !givenAnswer.some((item) => item === event.target.name)) {
      givenAnswer.push(event.target.name)
    } else if (!event.target.checked) {
      givenAnswer = givenAnswer.filter((item) => item !== event.target.name)
    }

    this.setState({ givenAnswer: givenAnswer })

    this.props.onChange({ question_id: this.props.id, value: givenAnswer, qid: this.props.qid })
  }

  render() {
    const checkboxes = this.props.answers.map((answer) => {
      // const id = 'answer_' + this.props.id + '_' + answer.id
      const name = '' + answer.id
      const value = this.state.givenAnswer.includes(name)

      const elementId = this.props.id + '_' + answer.id

      return (
        <div className="custom-control custom-checkbox mb-2" key={elementId}>
          <input
            className="custom-control-input"
            type="checkbox"
            name={name}
            id={elementId}
            onChange={this.handleChange}
            checked={value}
          ></input>
          <label className="custom-control-label" htmlFor={elementId}>
            {answer.text}
          </label>
        </div>
      )
    })

    return checkboxes
  }
}
