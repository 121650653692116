import { useCallback, useEffect, useMemo, useState } from 'react'
import Slider, { Handle, SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import { useSurveyDataStore } from '../../dataStore'
import { DataCard } from './DataCard'

const disableSliderFor = {
  turnover: true,
  space_new_total: true,
  vacancy: true,
}

const dotStyle = {
  borderColor: '#00697C',
  borderWidth: 4,
  width: 20,
  height: 20,
  zIndex: 100,
  marginLeft: '-9px',
  marginBottom: '-7px',
}

const handleStyle = {
  borderColor: 'rgb(0,41,64)',
  borderWidth: 10,
  width: 20,
  height: 20,
  marginTop: '-7px',
  marginLeft: 1,
}

export const RiwisSliderComponent = ({ datasourceId, data, onChange, question, value }) => {
  const [{ datasourceYear }] = useSurveyDataStore()
  const { id, qid, unit, step, with_decimal } = question
  const [handleHover, setHandleHover] = useState(false)
  const [fileMaker] = useState(() => {
    return question.riwis_datasources.find((datasource) => datasource.id === parseInt(datasourceId)).fileMaker
  })
  const questionDatasource = useMemo(() => {
    return question.riwis_datasources.find((datasource) => datasource.id === parseInt(datasourceId))
  }, [question, datasourceId])
  const [hideSlider] = useState(() => {
    return disableSliderFor[
      question.riwis_datasources.find((datasource) => datasource.id === parseInt(datasourceId)).topic
    ]
  })

  const [riwisValue] = useState(() => data.riwisValue)
  const [averageChange] = useState(() => data.averageChange)
  const [averagePercentage] = useState(() => data.averagePercentage)

  const valueRange = useMemo(() => {
    if (hideSlider) {
      return { min: 0, max: Infinity }
    }
    const valueStep = parseFloat(step) ? parseFloat(step) : 0.1
    const max = Math.ceil(riwisValue * 1.5)
    const diff = max - riwisValue
    const min = riwisValue - diff - valueStep
    return { min, max: max + valueStep }
  }, [riwisValue, step, hideSlider])

  const handleChange = useCallback(
    (userValue) => {
      const answer = { question_id: id, qid, value: { ...value } || {} }
      if (userValue !== null) {
        if (userValue > valueRange.max) {
          userValue = valueRange.max
        } else if (userValue < valueRange.min) {
          userValue = valueRange.min
        }
        answer.value[datasourceId] = { riwisValue, userValue, fileMaker }
        onChange(answer)
      } else {
        delete answer.value[datasourceId]
        onChange(answer)
      }
    },
    [onChange, riwisValue, id, qid, valueRange, datasourceId, value, fileMaker]
  )

  const handleButton = useCallback(
    (operation) => {
      const val = parseFloat(step) ? parseFloat(step) : 0.1
      const userValue = value[datasourceId].userValue ? value[datasourceId].userValue : riwisValue
      if (operation === 'add') {
        const result = parseFloat((userValue + val).toPrecision(12))
        handleChange(result)
      } else {
        const result = parseFloat((userValue - val).toPrecision(12))
        handleChange(result)
      }
    },
    [riwisValue, step, value, datasourceId, handleChange]
  )

  /**  due to createWithTooltip handle dragging not working properly
   * create custom handle with tooltip
   */
  const handle = (props) => {
    const { value, dragging, index, ...restProps } = props
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${with_decimal ? value.toFixed(1) : value} ${unit}`}
        visible={dragging || handleHover}
        placement="top"
        key={index}
      >
        <Handle
          value={value}
          key={index}
          {...restProps}
          onMouseEnter={() => setHandleHover(true)}
          onMouseLeave={() => setHandleHover(false)}
        />
      </SliderTooltip>
    )
  }

  useEffect(() => {
    // add empty answer if there is no data for the current question available
    if (question.riwisData && riwisValue === null && !value) {
      const answer = { question_id: id, qid, value: {} }
      answer.value[datasourceId] = { riwisValue: null, userValue: null, fileMaker }
      onChange(answer)
    }
  }, [riwisValue, question, onChange, id, qid, value, datasourceId, fileMaker])

  return (
    <div className="sliderContainer align-items-center" key={datasourceId}>
      {question.riwisData && (
        <div className="d-flex flex-column justify-content-between align-items-center" style={{ flex: '1' }}>
          <div className="riwisSliderContainer">
            <div className="riwisQuestionHeader">{questionDatasource.question}</div>
            <DataCard
              riwisValue={riwisValue}
              userValue={value[datasourceId]?.userValue || null}
              valueRange={valueRange}
              unit={unit}
              withDecimal={with_decimal}
              averageChange={averageChange}
              averagePercentage={averagePercentage}
              year={datasourceYear}
              handleUserInput={handleChange}
              datasources={question.riwis_datasources}
            />
            {riwisValue !== null && !hideSlider && (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex align-items-center w-100 mt-3">
                  <div className="sliderAdjustButton" onClick={() => handleButton('subtract')}>
                    <i className="fas fa-minus-circle fa-lg"></i>
                  </div>
                  <div className="sliderAdjustButtonAddon">&nbsp;</div>
                  <Slider
                    defaultValue={value[datasourceId]?.userValue || riwisValue}
                    startPoint={riwisValue}
                    step={parseFloat(step) ? step : 0.1}
                    min={valueRange.min}
                    max={valueRange.max}
                    marks={{ [riwisValue]: '' }}
                    value={value[datasourceId]?.userValue || riwisValue}
                    onChange={handleChange}
                    dotStyle={dotStyle}
                    handleStyle={handleStyle}
                    trackStyle={{ backgroundColor: 'rgb(0,41,64)', height: '6px' }}
                    railStyle={{ backgroundColor: 'lightgrey', height: '6px' }}
                    handle={handle}
                  />
                  <div className="sliderAdjustButtonAddon">&nbsp;</div>
                  <div className="sliderAdjustButton" onClick={() => handleButton('add')}>
                    <i className="fas fa-plus-circle fa-lg"></i>
                  </div>
                </div>
                {/* <div className="mt-3 sliderMarker">
                  <div style={{ width: 2, height: 15, marginLeft: 3, backgroundColor: 'lightgrey' }}></div>
                  <div>
                    <span style={{ color: '#00697C', fontWeight: 'bold' }}>RIWIS</span> Wert ({datasourceYear}
                    ):
                  </div>
                  <div>
                    <b>
                      {numberSeparator(riwisValue, with_decimal ? 1 : 0)} {unit}
                    </b>
                  </div>
                </div> */}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
