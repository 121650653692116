import React from 'react'

export const MissingInputWarning = ({ error }) => {
  return (
    <div className="missingInputBox">
      <i className="fas fa-exclamation mr-3 icon warning"></i>
      {error}
    </div>
  )
}
