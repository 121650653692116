import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { stripString, decodeSpecialChars } from './utils'

const style = {
  padding: '0.5rem 1rem',
  // cursor: 'move',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '36px',
}
const labelStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
  width: '100%',
}

const ItemTypes = {
  TAB: 'tab',
}

export const Tab = ({
  index,
  id,
  text,
  moveTab,
  activeTab,
  setActiveTab,
  handleDelete,
  tabWidth,
  tabPills,
}) => {
  // const [isReallyDragging, setIsReallyDragging] = useState(null)
  const ref = useRef(null)
  const [{ hanlderId }, drop] = useDrop({
    accept: ItemTypes.TAB,
    collect(monitor) {
      return {
        hanlderId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveTab(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.TAB,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))
  return (
    <div className={(activeTab === index ? 'active' : '') + (!tabPills ? ' tabWrapper' : 'tabPillWrapper')}>
      <div
        className={(activeTab === index ? 'active' : 'inactive') + (tabPills ? ' tabPills' : '') + ' tab'}
        onClick={() => setActiveTab(index)}
        ref={ref}
        data-hanlder-id={hanlderId}
        style={{ ...style, opacity, width: tabWidth }}
      >
        <div style={{ ...labelStyle }}>
          {index + 1 + '. '}
          {decodeSpecialChars(stripString(text))}
        </div>
        <i
          className="fas fa-trash-alt small ml-3 trashCan"
          onClick={(e) => {
            e.stopPropagation()
            handleDelete(index)
          }}
        ></i>
      </div>
    </div>
  )
}
