import { useReportingDataStore } from './dataStore'
import { useEffect, useMemo } from 'react'
import { StatisticsChart } from './StatisticsChart'

const stripString = (htmlString) => htmlString.replace(/(<([^>]+)>)/gi, '')

const getParsedAnswers = (start, range, answers, question) => {
  let answerIndex = 0
  const result = {
    id: question.question_id,
    question: question.question,
    subQuestions: [],
    answers: [],
  }

  if (question.question_type === 'checkbox' || question.question_type === 'radio') {
    question.possible_answers.forEach((answer) => result.answers.push({ name: answer.text, data: [0] }))
    // radio and checkbox answers
    for (let i = start; i < start + range; i++) {
      // eslint-disable-next-line
      answers.forEach((answer) => {
        if (answer[i] === 'X') result.answers[answerIndex].data[0]++
      })
      answerIndex++
    }
  } else if (question.question_type === 'radioMatrix' || question.question_type === 'checkboxMatrix') {
    // matrix
    question.possible_answers.forEach((answer) =>
      result.answers.push({ name: answer.text, data: question.questions_field.map(() => 0) })
    )
    question.questions_field.forEach((subQuestion, index) => {
      result.subQuestions.push(subQuestion.text)
      for (let i = start; i < start + range; i++) {
        // eslint-disable-next-line
        answers.forEach((answer) => {
          if (answer[i] === 'X') {
            result.answers[answerIndex].data[index] = ++result.answers[answerIndex].data[index] || 1
          }
        })
        answerIndex++
      }
      answerIndex = 0
      start = start + range
    })
    // sum answers
  } else if (question.question_type === 'sum') {
    let optedOutUserCount = 0
    question.possible_answers.forEach((answer) =>
      result.answers.push({ name: '&empty; ' + answer.text, data: [0] })
    )
    for (let i = start; i < start + range; i++) {
      // eslint-disable-next-line
      answers.forEach((answer) => {
        if (i === start && answer[i] === 'Opt-out') {
          optedOutUserCount += 1
        } else if (answer[i] !== 'Opt-out') {
          result.answers[answerIndex].data[0] += Number(answer[i])
        }
      })
      answerIndex++
    }
    result.answers.forEach((answer) => {
      answer.data[0] = answer.data[0] / answers.length
    })
    if (optedOutUserCount > 0) {
      result.answers.push({ name: 'Konnte keine Antwort geben', data: [optedOutUserCount] })
    }
  } else if (question.question_type === 'slider') {
    // get all answers
    const answerValues = answers.map((answer) => answer[start])
    // create unique answer set
    const uniqueValues = answerValues.filter((answer, index, self) => self.indexOf(answer) === index)
    uniqueValues.forEach((value) => result.answers.push({ name: value, data: [0] }))

    answerValues.forEach((value) => {
      result.answers.forEach((resAnswer) => {
        if (resAnswer.name === value) {
          resAnswer.data[0]++
        }
      })
    })
  }
  return result
}

export const Statistics = () => {
  const [{ report, chartType, currentQuestionId }, { setChartType, setCurrentQuestionId }] =
    useReportingDataStore()

  const parsedReport = useMemo(() => {
    if (!report) return []
    const surveyReport = []
    let accAnswers = 1
    const questions = report.questions
    let answers = report.answers

    questions.forEach((question) => {
      if (question.question_type === 'radioMatrix' || question.question_type === 'checkboxMatrix') {
        surveyReport.push(getParsedAnswers(accAnswers, question.possible_answers.length, answers, question))
        question.questions_field.forEach(() => {
          accAnswers += question.possible_answers.length
        })
      } else if (
        question.question_type === 'checkbox' ||
        question.question_type === 'radio' ||
        question.question_type === 'slider' ||
        question.question_type === 'sum'
      ) {
        const length = question.possible_answers.length ? question.possible_answers.length : 1
        surveyReport.push(getParsedAnswers(accAnswers, length, answers, question))
        accAnswers += length
      } else {
        accAnswers += 1
      }
    })
    return surveyReport
  }, [report])

  const handleQuestionChange = (event) => {
    setCurrentQuestionId(parseInt(event.target.value))
  }

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value)
  }

  const filteredQuestions = useMemo(() => {
    if (!report) return []
    return report.questions.reduce((arr, question) => {
      if (
        (question.questions_field && question.questions_field.length) ||
        ((question.possible_answers === null ||
          question.possible_answers.length ||
          question.question_type === 'slider') &&
          question.question_type !== 'inputColumns')
      ) {
        arr.push(question)
      }
      return arr
    }, [])
  }, [report])

  const currentQuestionReport = useMemo(() => {
    return parsedReport.find((question) => question.id === currentQuestionId)
  }, [currentQuestionId, parsedReport])

  useEffect(() => {
    if (
      filteredQuestions[0]?.question_id &&
      !filteredQuestions.some((question) => question.question_id === currentQuestionId)
    ) {
      setCurrentQuestionId(filteredQuestions[0].question_id)
    }
  }, [report, filteredQuestions, currentQuestionId, setCurrentQuestionId])

  return report && currentQuestionReport && currentQuestionId && report.type !== 'riwis' ? (
    <>
      <div className="form-row mb-5">
        <div className="form-group col-md-5">
          <label htmlFor="questionSelector">Frage:</label>
          <select
            name="questionSelector"
            id="questionSelector"
            className="form-control"
            onChange={handleQuestionChange}
            defaultValue={currentQuestionId}
          >
            {filteredQuestions.map((question) => (
              <option
                value={question.question_id}
                key={question.question_id}
                dangerouslySetInnerHTML={{ __html: stripString(question.question) }}
              ></option>
            ))}
          </select>
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="typeSelector">Chart-Typ:</label>
          <select
            name="typeSelector"
            id="typeSelector"
            className="form-control"
            onChange={handleChartTypeChange}
            defaultValue={chartType}
          >
            <option value="column">Column</option>
            <option value="bar">Bar</option>
            <option value="pie">Pie</option>
          </select>
        </div>
      </div>
      {(chartType !== 'pie' || currentQuestionReport.subQuestions.length === 0) && (
        <StatisticsChart
          title={currentQuestionReport.question}
          series={currentQuestionReport.answers}
          categories={currentQuestionReport.subQuestions}
          chartType={chartType}
        />
      )}
      {report &&
        currentQuestionReport &&
        currentQuestionId &&
        currentQuestionReport.subQuestions.length > 0 &&
        currentQuestionReport.subQuestions.map((subQuestion, index) => (
          <div key={`${currentQuestionReport.question_id}-${index}`}>
            <StatisticsChart
              title={subQuestion}
              subQuestionIndex={index}
              series={currentQuestionReport.answers}
              chartType={chartType}
              categories={[]}
            />
          </div>
        ))}
    </>
  ) : (
    <div className="d-flex justify-content-center mt-5">
      <h2>Keine Vorschau möglich</h2>
    </div>
  )
}
