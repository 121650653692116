import React from 'react'

export class RadioInline extends React.Component {
  handleChange = (event) => {
    this.props.onChange({ question_id: event.target.name, value: event.target.value, qid: this.props.qid })
  }

  render() {
    const radios = this.props.answers.map((answer) => {
      const checked = parseInt(this.props.value) === answer.id

      const elementId = this.props.id + '_' + answer.id

      return (
        <div className="custom-control custom-radio custom-control-inline" key={elementId}>
          <input
            className="custom-control-input"
            type="radio"
            name={this.props.id}
            id={elementId}
            value={answer.id}
            onChange={this.handleChange}
            checked={checked}
          ></input>
          <label className="custom-control-label radioLabel" htmlFor={elementId}>
            {answer.text}
          </label>
        </div>
      )
    })

    return radios
  }
}
