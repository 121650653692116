import React from 'react'
import { Logs } from '../helper/api'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const userId = this.props?.user?.id || null
    const report = {
      userId,
      timestamp: new Date(),
      message: error.message,
      stack: error.stack,
      componentStack: errorInfo.componentStack,
      location: window.location.pathname,
      userAgent: window.navigator.userAgent,
      appId: 'survey-desk',
    }
    Logs.saveErrorLog(report)
      .then((res) => {
        if (res.data.msg === 'success') {
          console.log('Error report has been submitted')
        } else {
          console.warn('Error report submit failed', res.data.err)
        }
      })
      .catch((err) => console.warn(err))
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="errorBoundaryContainer">
          <div style={{ position: 'relative' }}>
            <div className="text-center background">
              <i className="fas fa-exclamation-triangle"></i>
            </div>
            <div style={{ position: 'relative' }}>
              <h1 className="mb-5">App Crash</h1>
              <div style={{ fontSize: '1rem' }}>
                <div>Leider ist ein Fehler aufgetreten und die Anwendung ist abgestürzt.</div>
                <div>Bitte laden Sie die Seite neu!</div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
