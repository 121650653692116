import React from 'react'
import { ListBox } from './Components/ListBox'
import { MaxValue } from './Components/MaxValue'
import { OptOut } from './Components/OptOut'
import { Unit } from './Components/Unit'

export const Sum = ({ question, questionnaireIndex, pageIndex, index }) => {
  return (
    <div>
      <OptOut
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
      />

      <ListBox
        label={'Antworten'}
        name="possible_answers"
        items={question.possible_answers}
        index={index}
        pageIndex={pageIndex}
        questionnaireIndex={questionnaireIndex}
      />
      <MaxValue
        question={question}
        questionnaireIndex={questionnaireIndex}
        pageIndex={pageIndex}
        index={index}
      />
      <Unit question={question} questionnaireIndex={questionnaireIndex} pageIndex={pageIndex} index={index} />
    </div>
  )
}
