import React, { useState, useEffect, useCallback } from 'react'
import { useSurveyDataStore } from '../../pages/Survey/dataStore'
import { useUserDataStore } from '../../stores/userStore'

export const LoginBox = ({ handleResendVerificationMail, handleForgotPassword, handleClose, riwisMode }) => {
  const [{ user, loginMessage }, { loginUser }] = useUserDataStore()
  const [{ isAuthorized }, { setDataIsLoading }] = useSurveyDataStore()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = useCallback(() => {
    // set survey data to loading to check authorization in index.js again
    loginUser({ username: email, password })
    if (!isAuthorized) {
      setDataIsLoading(true)
    }
  }, [setDataIsLoading, loginUser, email, password, isAuthorized])

  const onKeyDown = (event) => {
    if (event.code === 'Enter') loginUser({ username: email, password })
  }

  const Error = () => {
    if (loginMessage.err === 'not active') {
      return (
        <>
          <div className="col-auto pb-4 text-danger font-weight-bold">{loginMessage.msg}</div>
          <div className="col-auto pb-2">Keine Bestätigungsmail erhalten?</div>
          <div className="col-auto pb-5">
            <a
              href="_"
              onClick={(e) => {
                e.preventDefault()
                handleResendVerificationMail(email)
              }}
            >
              Bestätigungsmail erneut anfordern.
            </a>
          </div>
        </>
      )
    } else {
      return <div className="text-danger col-auto pb-4 font-weight-bold">{loginMessage.msg}</div>
    }
  }

  useEffect(() => {
    if (user) {
      handleClose()
    }
  }, [user, handleClose])

  return (
    <div className="w-100" onKeyDown={onKeyDown}>
      <h5 className="text-secondary col-auto pb-4">Login</h5>
      {loginMessage.err.length > 0 && <Error />}
      <div className="form-group">
        <div className="col-auto">
          <label className="sr-only" htmlFor="inputEmail">
            EMail-Adresse
          </label>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-user"></i>
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              id="inputEmail"
              name="email"
              placeholder="E-Mail Adresse"
              aria-describedby="emailHelp"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="col-auto">
          <label className="sr-only" htmlFor="inputPassword">
            Passwort
          </label>
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="fas fa-lock"></i>
              </div>
            </div>
            <input
              type="password"
              className="form-control"
              id="inputPassword"
              name="password"
              placeholder="Passwort"
              aria-describedby="passwordHelp"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="col-auto pt-3">
          <a
            href="_"
            onClick={(e) => {
              e.preventDefault()
              handleForgotPassword(true)
            }}
          >
            Passwort vergessen?
          </a>
        </div>
      </div>
      <div className="col-auto">
        <button
          style={{ fontWeight: riwisMode ? '500' : '' }}
          className={(riwisMode ? 'riwis-btn riwis-white' : 'btn btn-outline-secondary') + ' form-group'}
          id="loginBtn"
          onClick={handleLogin}
        >
          Login
        </button>
      </div>
    </div>
  )
}
