import React, { useState, useCallback } from 'react'
import { Question } from './question'
import { findLastNonDeleted } from './utils'
import { TinyMCE } from './TinyMCE'
import { useSurveyEditorStateStore } from './surveyEditorState'
import { TabContainer } from './TabContainer'
import { Modal } from '../../../../components/Modal'
import { Button } from 'react-bootstrap'

export const Page = ({ pages, questionnaireId, title, questionnaireIndex, assetClass }) => {
  const [{ pageTab }, { setPageInput, addPage, setPageTab, deletePage, changePageOrder }] =
    useSurveyEditorStateStore()
  const [newId, setNewId] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [pageToDelete, setPageToDelete] = useState(null)

  const updateOrder = (pages) => {
    changePageOrder(questionnaireIndex, pages)
  }

  const showDeleteModal = (index) => {
    setPageToDelete(index)
    setShowModal(true)
  }

  const hideDeleteModal = () => {
    setPageToDelete(null)
    setShowModal(false)
  }

  const handleNewPageClick = useCallback(() => {
    const initialProperties = {
      id: newId,
      order: pages.length + 1,
      title: '',
      description: '',
      questions: [],
      riwis_icon: '',
    }
    setNewId(newId - 1)
    addPage(questionnaireIndex, initialProperties)
    setPageTab(pages.length)
  }, [addPage, newId, pages, questionnaireIndex, setPageTab])

  const handleInputChange = useCallback(
    (index, event) => {
      setPageInput(questionnaireIndex, index, event.target.value, event.target.name)
    },
    [questionnaireIndex, setPageInput]
  )

  const handleTinyMCEChange = useCallback(
    (index, event) => {
      setPageInput(questionnaireIndex, index, event.value, event.name)
    },
    [questionnaireIndex, setPageInput]
  )

  const handleDeletePageClick = useCallback(() => {
    if (pageTab === pageToDelete) {
      setPageTab(findLastNonDeleted(pageToDelete, pages))
    } else if (pageTab - 1 > 0) {
      setPageTab(pageTab - 1)
    }
    deletePage(questionnaireIndex, pageToDelete)
    hideDeleteModal()
  }, [deletePage, pages, pageTab, pageToDelete, setPageTab, questionnaireIndex])

  const renderSinglePage = useCallback(
    (page, index) => {
      return (
        <React.Fragment>
          <div className="mt-4">
            <div className="form-group row">
              <label className="col-sm-2 col-form-label text-right">Titel</label>
              <div className="col">
                <input
                  className="form-control"
                  type="text"
                  name="title"
                  onChange={(event) => handleInputChange(index, event)}
                  value={page.title || ''}
                ></input>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label text-right">Beschreibung</label>
              <div className="col">
                <TinyMCE
                  id={`pageDescription-${questionnaireId}-${page.id}`}
                  name={'description'}
                  content={page.description}
                  inlineMode={true}
                  handleTinyMCEChange={(event) => handleTinyMCEChange(index, event)}
                />
              </div>
            </div>
          </div>

          <Question
            pageId={page.id}
            questionnaireId={questionnaireId}
            questionnaireIndex={questionnaireIndex}
            pageIndex={index}
            questions={page.questions || []}
            assetClass={assetClass}
          />
        </React.Fragment>
      )
    },
    [assetClass, handleInputChange, handleTinyMCEChange, questionnaireId, questionnaireIndex]
  )

  return (
    <div className="mt-5">
      <div className="d-flex align-items-center">
        <h3>Seiten in Fragebogen {questionnaireIndex + 1}</h3>
        <button type="button" className="btn-sm btn-outline-primary ml-5" onClick={handleNewPageClick}>
          <small>Neue Seite</small>
        </button>
      </div>
      <hr></hr>
      <div className="d-flex flex-wrap">
        {pages.length > 0 && (
          <TabContainer
            tabs={pages}
            activeTab={pageTab}
            setActiveTab={setPageTab}
            handleDelete={showDeleteModal}
            tabWidth={'145px'}
            updateOrder={updateOrder}
          />
        )}
      </div>
      {pages[pageTab] && !pages[pageTab].deleted && renderSinglePage(pages[pageTab], pageTab)}
      {showModal && (
        <Modal onHide={hideDeleteModal} maxWidth={'500px'} minWidth={'450px'}>
          <div className="header">Seite löschen?</div>
          <div className="body">
            <p>
              Seite <b>{pages[pageToDelete].title}</b> wirklich löschen?
            </p>
            <div>Zusätzlich werden folgende Inhalte gelöscht:</div>
            <ul>
              <li>
                <b>Alle Fragen der Seite</b>
              </li>
              <li>
                <b>Antworten zu den Fragen</b>
              </li>
            </ul>
          </div>
          <div className="footer justify-content-between">
            <Button variant="secondary" onClick={hideDeleteModal}>
              Abbrechen
            </Button>
            <Button variant="danger" onClick={handleDeletePageClick}>
              Löschen
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}
