import React from 'react'

export class RadioMatrix extends React.Component {
  constructor(props) {
    super(props)

    this.radioMatrixRef = React.createRef()

    this.state = {
      givenAnswer: props.value || {},
      scrollEndX: false,
      selectedQuestion: null,
    }
  }

  handleChange = (event) => {
    // target name is of form
    // <id of overall question>_<id within questions field>
    //
    const questionName = this.props.id
    const [question, answer] = event.target.value.split('_')
    let givenAnswer = this.state.givenAnswer

    givenAnswer[question] = answer

    if (this.props.oneAnswer) {
      givenAnswer = { [question]: answer }
    }

    this.setState({ givenAnswer: givenAnswer })

    this.props.onChange({ question_id: questionName, value: givenAnswer, qid: this.props.qid })
  }

  onScroll(event) {
    const element = event.target
    const maxX = element.scrollWidth - element.clientWidth
    console.log('scroll: ', element.scrollLeft, 'maxX: ', maxX)
    if (maxX === element.scrollLeft) {
      console.log('is Equal')
    } else {
      console.log('is not Equal')
    }
  }

  renderMobileDropdown = (question) => {
    return (
      <select key={question.id} onChange={this.handleChange}>
        {this.props.answers.map((answer) => {
          const elementValue = question.id + '_' + answer.id
          const checked = parseInt(this.props.value[question.id]) === answer.id
          return (
            <option key={answer.id} value={elementValue} selected={checked}>
              {answer.text}
            </option>
          )
        })}
      </select>
    )
  }

  close = () => {
    this.setSelectedQuestion(null)
  }

  setSelectedQuestion = (questionId) => {
    this.setState({ selectedQuestion: questionId })
  }

  renderMobileDropdownBtn = (question) => {
    const answer = this.props.answers.find((answer) => parseInt(this.props.value[question.id]) === answer.id)
    return (
      <div className="btn-group">
        <button
          type="button"
          className="btn mainBtn"
          key={question.id}
          onClick={() => this.setSelectedQuestion(question.id)}
        >
          {answer ? answer.text : 'Antwort auswählen'}
        </button>
        <button
          type="button"
          className="btn btn-primary dropdown-toggle dropdown-toggle-split"
          onClick={() => this.setSelectedQuestion(question.id)}
        ></button>
      </div>
    )
  }

  render() {
    const tableHeader = (
      <thead>
        <tr>
          <th scope="col">{/* <div className="theadBlur"></div> */}</th>
          {this.props.answers.map((answer) => {
            return <th key={answer.id} dangerouslySetInnerHTML={{ __html: answer.text }}></th>
          })}
        </tr>
      </thead>
    )

    const tableBody = (
      <tbody>
        {this.props.questions.map((question) => {
          return (
            <tr style={{ verticalAlign: 'baseline' }} key={question.id}>
              <th key={question.id} scope="row" dangerouslySetInnerHTML={{ __html: question.text }}></th>

              <td className="matrixDropdown">{this.renderMobileDropdownBtn(question)}</td>

              {this.props.answers.map((answer) => {
                const elementName = this.props.id + '_' + question.id
                const elementId = this.props.id + '_' + question.id + '_' + answer.id
                const elementValue = question.id + '_' + answer.id

                const checked = parseInt(this.props.value[question.id]) === answer.id

                return (
                  <td key={answer.id}>
                    {
                      <div className="custom-control custom-radio" key={elementId}>
                        <input
                          className="custom-control-input"
                          type="radio"
                          name={elementName}
                          id={elementId}
                          value={elementValue}
                          onChange={this.handleChange}
                          checked={checked}
                        ></input>
                        <label className="custom-control-label" htmlFor={elementId}></label>
                      </div>
                    }
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    )

    return (
      <div className="d-flex">
        {this.state.selectedQuestion !== null && (
          <MobileAnswersRadio
            id={this.props.id}
            answers={this.props.answers}
            questions={this.props.questions}
            selectedQuestion={this.state.selectedQuestion}
            givenAnswers={this.props.value}
            close={this.close}
            changeHandler={this.handleChange}
          />
        )}
        <div onScroll={this.onScroll} className="matrixTableContainer">
          <table className="matrixTable">
            {tableHeader}
            {tableBody}
          </table>
        </div>
        {/* <div
          className={this.state.scrollEndX ? '' : 'tableBlur'}
          style={{ width: '1px', backgroundColor: 'white' }}
        ></div> */}
      </div>
    )
  }
}

class MobileAnswersRadio extends React.Component {
  constructor(props) {
    super(props)
    this.question = this.props.questions.find((question) => question.id === this.props.selectedQuestion)
  }

  selectedActive = () => {
    return (
      <div className="selectedRing d-flex justify-content-center align-items-center">
        <div className="selectedDot"></div>
      </div>
    )
  }

  selectedInactive = () => {
    return <div className="selectedRing"></div>
  }

  render() {
    return (
      <div
        className="mobileAnswerWindow"
        onClick={(e) => {
          e.target.className === 'mobileAnswerWindow' && this.props.close()
        }}
      >
        <div className="mobileAnswerContainer">
          <ul className="mobileAnswerList">
            <li className="closeBtn d-flex justify-content-end">
              <div className="crossContainer" onClick={this.props.close}>
                <div className="crossBarOne">
                  <div className="crossBarTwo"></div>
                </div>
              </div>
            </li>
            {this.props.answers.map((answer) => {
              const elementName = this.props.id + '_' + this.question.id + '_m'
              const elementValue = this.question.id + '_' + answer.id
              const elementId = this.props.id + '_' + this.question.id + '_' + answer.id + '_m'
              const checked = parseInt(this.props.givenAnswers[this.question.id]) === answer.id
              return (
                <li key={answer.id} value={elementValue} className="d-flex align-items-center">
                  <div className="custom-control custom-radio">
                    <input
                      onChange={this.props.changeHandler}
                      className="custom-control-input"
                      type="radio"
                      name={elementName}
                      id={elementId}
                      value={elementValue}
                      checked={checked}
                    />
                    <label className="custom-control-label" htmlFor={elementId}>
                      {answer.text}
                    </label>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}
